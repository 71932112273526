<template>
    <v-card style="border-radius: 1em;">
        <v-layout justify-center  v-if="item.imagens.length > 0">
            <v-carousel
            height="20em"
            >
            <v-carousel-item
                v-for="(imagem, i) in item.imagens"
                :key="i"
                :src="imagem"
                style="max-height: 20em"
                reverse-transition="fade-transition"
                transition="fade-transition"
            ></v-carousel-item>
            </v-carousel>
        </v-layout>
        <v-layout
        v-else
        justify-center
        align-center
        fill-height
        style="
            height: 20em;
        "
        >
            <v-icon :color="item.categoria.cor" size="20em">{{item.categoria.icone}}</v-icon>
        </v-layout>
        <v-card-title class="pb-0">
            <h1 style="width: 100%; text-align: center;">{{ item.nome.toUpperCase() }}</h1>
        </v-card-title>
        <v-card-text>
            <v-layout justify-space-between wrap style="font-size: 1.3em;" class="mb-4">
                <v-flex xs5 class="mb-2">
                    Código: {{ item.codigo }}
                </v-flex>
                <v-flex xs5 class="mb-2">
                    Preço: <b>{{ item.preco | currency({fractionCount: 2}) }}</b>
                </v-flex>
                <v-flex xs5 class="mb-2">
                    Categoria: <b>{{ item.categoria.nome }}</b>
                </v-flex>
                <v-flex xs5 class="mb-2">
                    Disponível: <b>{{ item.disponivel ? 'SIM' : 'NÃO' }}</b>
                </v-flex>
                <v-flex xs5 class="mb-2">
                    Tem Controle de Quantidade: <b>{{ item.quantidade_disponivel ? 'SIM' : 'NÃO' }}</b>
                </v-flex>
                <v-flex xs5 class="mb-2">
                    Quantidade Disponível: <b></b>{{ item.quantidade_disponivel ? `${item.quantidade_disponivel} itens` : 'NÃO COTÉM' }}
                </v-flex>
                <v-flex xs5 class="mb-2">
                    Data de Cadastro: <b>{{ item.data_criacao | formatDate('DD/MM/YYYY') }}</b>
                </v-flex>
                <v-flex xs5 class="mb-2">
                    Data de Atualização: <b>{{ item.data_atualizacao | formatDate('DD/MM/YYYY') }}</b>
                </v-flex>
                <v-flex xs11 class="mb-2">
                    Tipo: <b>{{ tipos[item.tipo] }}</b>
                </v-flex>
                <v-flex xs5 class="mb-2">
                    Descrição: <b>{{ item.descricao }}</b>
                </v-flex>
                <v-flex xs11>
                    <v-list two-line>
                        <template v-for="(oferta, index) in item.ofertas">
                            <v-list-tile :key="index">
                                <v-list-tile-avatar>
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-on="on"
                                            :color="`${oferta.para_assinantes ? 'secondary' : ''}`"
                                        >
                                            {{ oferta.rede ? 'ac_unit' : 'local_gas_station' }}
                                        </v-icon>
                                      </template>
                                        <span>
                                            {{  oferta.rede ? 'Válida para a rede' : 'Válida para o posto' }}
                                        </span>
                                    </v-tooltip>
                                </v-list-tile-avatar>
                                <v-list-tile-avatar>
                                    <span
                                        :class="`${ oferta.para_assinantes ? 'secondary' : ''}--text`"
                                    >
                                        {{oferta.percentual_desconto}}%
                                    </span>
                                </v-list-tile-avatar>
                                <v-list-tile-content>
                                    <v-list-tile-sub-title
                                        :class="`${oferta.para_assinantes ? 'secondary' : ''}--text pt-1`"
                                    >
                                        {{ oferta.promocao ? 'Promoção' : 'Oferta' }}:
                                        {{ calcularDesconto(item.preco, oferta.percentual_desconto) }}</v-list-tile-sub-title>
                                    <v-list-tile-sub-title :style="{'color': oferta.quantia_restante && oferta.quantia_restante === 0 ? '#8B0000': ''}"  v-if="oferta.quantia_restante">
                                    {{ oferta.quantia_restante === 0? 'O volume foi atingido': `Resta: ${oferta.quantia_restante}lt` }}
                                    </v-list-tile-sub-title>
                                </v-list-tile-content>
                                <v-list-tile-avatar v-if="oferta && oferta.para_assinantes">
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          class="ml-3"
                                          v-on="on"
                                          color="secondary"
                                        >
                                          star
                                        </v-icon>
                                      </template>
                                        <span>Apenas Para Assinantes</span>
                                    </v-tooltip>
                                </v-list-tile-avatar>
                            </v-list-tile>
                            <v-divider
                                :key="`${index}d`"
                            ></v-divider>
                        </template>
                    </v-list>
                </v-flex>
            </v-layout>
        </v-card-text>
        <v-footer absolute>
            <v-btn
                block
                :color="item.categoria.cor"
                @click="$emit('fechar')"
                class="white--text"
            >
                FECHAR
            </v-btn>
        </v-footer>
    </v-card>
</template>

<script>
export default {
  props: ['item'],
  data: () => ({
    tipos: {
      P: 'Produto Físico',
      D: 'Produto Digital',
      S: 'Serviço'
    }
  }),
  computed: {
    empresa () {
      return this.$store.getters.empresa
    }
  },
  methods: {
    calcularDesconto (preco, percentual) {
      const valorComDesconto = (preco - preco * (percentual / 100)).toFixed(8) // Faz calculo é obriga a função a trabalhar com 4 digitos

      if (this.empresa.truncamento_combustivel) { // Se é truncamento
        return 'R$ ' + valorComDesconto.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] // Remove casas decimais adicionais e conserva o número
      } else { // Se trucamento não está ativo
        const casasDecimais = valorComDesconto.toString().split('.')[1]
        const algarismoConservado = Math.floor(valorComDesconto * 100) % 10
        const algarismoSeguinteAoConservado = Math.floor(valorComDesconto * 1000) % 10
        const algarismoSeguinteSeguidoDeZeros = casasDecimais.substring(3).split('').some(numeroDecial => numeroDecial !== '0')

        if (algarismoSeguinteAoConservado < 5) {
          return 'R$ ' + valorComDesconto.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
        } else if (algarismoSeguinteSeguidoDeZeros) {
          return 'R$ ' + (parseFloat(valorComDesconto) + 0.001).toFixed(2)
        } else if (algarismoSeguinteAoConservado === 5 && !algarismoSeguinteSeguidoDeZeros) {
          if (algarismoConservado % 2 === 0) {
            return 'R$ ' + valorComDesconto.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
          } else {
            return 'R$ ' + (parseFloat(valorComDesconto) + 0.001).toFixed(2)
          }
        }
        return 'R$ ' + (parseFloat(valorComDesconto) + 0.001).toFixed(2)
      }
    }
  }
}
</script>
