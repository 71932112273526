import settings from '@/settings'

const adicionarCredito = (data, headers) => {
  return fetch(`${settings.apetrusApi}/creditos/criarcredito/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const meusCreditos = (headers, payload) => {
  let url = `${settings.apetrusApi}/creditos/getcreditos/`

  if (payload) {
    const keys = Object.keys(payload)
    for (let i = 0; i < keys.length; i++) {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${keys[i]}=${payload[keys[i]]}`
    }
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: headers
  })
}

const removerCredito = (data, headers) => {
  return fetch(`${settings.apetrusApi}/creditos/removercredito/${data.id}/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const aumentarCredito = (data, headers) => {
  return fetch(`${settings.apetrusApi}/creditos/adicionarcredito/${data.credito.id}/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const reduzirCredito = (data, headers) => {
  return fetch(`${settings.apetrusApi}/creditos/reduzircredito/${data.credito.id}/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

export default {
  adicionarCredito,
  meusCreditos,
  removerCredito,
  aumentarCredito,
  reduzirCredito
}
