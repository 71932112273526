<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || empresa.tipo_empresa !== 1)"/>
  <v-container grid-list-md fluid class="pt-5" v-else>
    <v-tabs background-color="transparent">
      <v-tab @click="filtroVisibilidade = true">Postos</v-tab>
      <v-tab @click="filtroVisibilidade = false; carregarRedes($event)">Redes de Postos</v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <template>
    <!-- Filtro de rede -->
    <v-toolbar color="transparent" class="elevation-0 secondary--text" v-if="!filtroVisibilidade">
      <v-toolbar-title>Faça uma proposta</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="mr-4" small rounded  @click="showFilter = !showFilter">
        <v-icon>{{ !showFilter ? 'filter_list' : 'filter_list_off' }}</v-icon>
      </v-btn>
    </v-toolbar>
    <v-slide-x-transition v-if="!filtroVisibilidade">
      <v-card v-show="showFilter" class="ml-4 mr-4">
        <v-card-text>
          <v-layout wrap justify-space-between>
            <v-flex xs10 md11>
              <v-layout wrap justify-space-around>
                <v-flex xs11 sm5 md3 lg4 xl2>
                  <v-text-field label="Nome" v-model="filtrosRede.nome"></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2 md1 align-self-center>
              <v-btn id="button" small fab color="info" @click="carregarRedes($event)">
                <v-icon>search</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-slide-x-transition>
    <!-- fim -->
    <!-- Filtro de postos -->
    <v-toolbar color="transparent" class="elevation-0 secondary--text" v-if="filtroVisibilidade">
      <v-toolbar-title>Faça uma proposta</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="mr-4" small rounded  @click="showFilter = !showFilter">
        <v-icon>{{ !showFilter ? 'filter_list' : 'filter_list_off' }}</v-icon>
      </v-btn>
      <v-btn small rounded @click="abrirMapa">
        <v-icon class="material-icons mr-2">{{mostrarMapa ? 'view_module' : 'explore'}}</v-icon>
        {{mostrarMapa ? 'Postos em cards' : 'Postos no mapa'}}
      </v-btn>
    </v-toolbar>
    <v-slide-x-transition v-if="filtroVisibilidade">
      <v-card v-show="showFilter" class="ml-4 mr-4">
        <v-card-text>
          <v-layout wrap justify-space-between>
            <v-flex xs10 md11>
              <v-layout wrap justify-space-around>
                <v-flex xs11 sm5 md3 lg2 xl2>
                  <v-text-field label="CNPJ" v-model="filtrosPostos.cnpj" :mask="'##.###.###/####-##'"></v-text-field>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-text-field label="Nome fantasia" v-model="filtrosPostos.nome_fantasia__icontains"></v-text-field>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-text-field label="Cidade" v-model="filtrosPostos.endereco__cidade__icontains"></v-text-field>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-select label="Estado" v-model="filtrosPostos.endereco__estado__iexact" :items="estados" item-text="text" item-value="value"></v-select>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2 md1 align-self-center>
              <v-btn id="button" small fab color="info" @click="carregarPostos($event)">
                <v-icon>search</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-slide-x-transition>
    <!-- fim -->
    <v-layout row wrap justify-center class="mt-4">
      <v-flex sm12 v-if="mostrarMapa">
        <v-card dark color="primary lighten-2">
          <l-map
            ref="myMap"
            v-if="mapa.center"
            style="height: 75vh; width: 100%; z-index: 0;"
            :options="{ zoomControl: zoomControl }"
            :zoom="mapa.zoom"
            :center="mapa.center"
            @update:zoom="zoomUpdated"
            @update:center="centerUpdated"
            @update:bounds="boundsUpdated"
          >
            <l-tile-layer
              :name="mapa.name"
              :url="mapa.url"
              :attribution="mapa.attribution"></l-tile-layer>
              <l-layer-group>
                <app-marker v-for="(posto, index) in postosMapa" @postoSelecionado="selecionarPosto"  :key="index" :posto="posto"></app-marker>
              </l-layer-group>
          </l-map>
        </v-card>
      </v-flex>
      <template v-else>
        <v-layout column class="ma-4">
          <template v-if="filtroVisibilidade">
          <h3 class="ml-4 mb-4">Procurar por postos</h3>
          <v-layout v-if="postos" align-start justify-start row wrap fill-height style="min-height: 18em;">
            <v-flex xs12 sm6 md4 lg4 xl3 v-for="(item, index) in postos.results" :key="index" class="mb-3">
              <postos-ativos :posto="item" @postoSelecionado="selecionarPosto"/>
            </v-flex>
          </v-layout>
          <v-pagination v-model="pagination.page" :length="pages" circle class="mb-4"></v-pagination>
          </template>
          <template v-if="!filtroVisibilidade">
          <h3 class="ml-4 mb-3" v-if="redes && redes.results.length > 0">Procurar por redes</h3>
          <v-layout v-if="redes && redes.results.length > 0" align-start justify-start row wrap fill-height class="mb-4" style="min-height: 18em;">
            <v-flex xs12 sm6 md4 lg4 xl3 v-for="(item, index) in redes.results" :key="index" class="mb-3">
              <redes-ativas :rede="item" @redeSelecionada="selecionarRede"/>
            </v-flex>
          </v-layout>
          <v-pagination v-model="paginationRede.page" :length="pagesRede" circle class="mb-4" v-if="redes && redes.results.length > 0"></v-pagination>
          </template>
        </v-layout>
      </template>
    </v-layout>
    <v-dialog v-model="dialogPropostas" v-if="dialogPropostas" max-width="500" persistent>
      <dialog-enviar-proposta  @fechar="fecharDialog" :posto="postoSelecionado" :rede="redeSelecionada"/>
    </v-dialog>
  </template>
  </v-container>
</template>

<script>
import AppMarker from '@/components/util/Marker'
import { LMap, LTileLayer, LLayerGroup } from 'vue2-leaflet'
import DialogEnviarProposta from '@/components/dialogs/DialogEnviarProposta'
import PostosAtivos from '@/components/dialogs/postos'
import RedesAtivas from '@/components/dialogs/Redes'
import AvisoBloqueio from '@/components/AvisoBloqueio'

export default {
  components: {
    LMap,
    LTileLayer,
    LLayerGroup,
    AppMarker,
    DialogEnviarProposta,
    PostosAtivos,
    RedesAtivas,
    AvisoBloqueio
  },
  data () {
    return {
      estados: [
        { value: null, text: 'Selecione um estado' },
        { value: 'AC', text: 'Acre' },
        { value: 'AL', text: 'Alagoas' },
        { value: 'AP', text: 'Amapá' },
        { value: 'AM', text: 'Amazonas' },
        { value: 'BA', text: 'Bahia' },
        { value: 'CE', text: 'Ceará' },
        { value: 'DF', text: 'Distrito Federal' },
        { value: 'ES', text: 'Espírito Santo' },
        { value: 'GO', text: 'Goiás' },
        { value: 'MA', text: 'Maranhão' },
        { value: 'MT', text: 'Mato Grosso' },
        { value: 'MS', text: 'Mato Grosso do Sul' },
        { value: 'MG', text: 'Minas Gerais' },
        { value: 'PA', text: 'Pará' },
        { value: 'PB', text: 'Paraíba' },
        { value: 'PR', text: 'Paraná' },
        { value: 'PE', text: 'Pernambuco' },
        { value: 'PI', text: 'Piauí' },
        { value: 'RJ', text: 'Rio de Janeiro' },
        { value: 'RN', text: 'Rio Grande do Norte' },
        { value: 'RS', text: 'Rio Grande do Sul' },
        { value: 'RO', text: 'Rondônia' },
        { value: 'RR', text: 'Roraima' },
        { value: 'SC', text: 'Santa Catarina' },
        { value: 'SP', text: 'São Paulo' },
        { value: 'SE', text: 'Sergipe' },
        { value: 'TO', text: 'Tocantins' }
      ],
      filtroVisibilidade: true,
      showFilter: false,
      filtrosRede: {
        nome: ''
      },
      filtrosPostos: {
        cnpj: '',
        nome_fantasia__icontains: '',
        endereco__cidade__icontains: '',
        endereco__estado__iexact: ''
      },
      step: 1,
      snackbarerror: {
        active: false,
        color: 'error',
        multiLine: true,
        timeout: 4000,
        vertical: true,
        errorText: ''
      },
      drawer: false,
      avatar: false,
      item: 2,
      items: [
        {
          avatar: '',
          title: 'Negociação com Posto1',
          status: 1
        }
      ],
      promocao: null,
      // oferta: null,
      mapa: {
        name: 'Mapa',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: 'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
        maxZoom: 18,
        zoom: 14,
        center: null,
        bounds: null,
        zoomControl: false
      },
      newCenter: {
        lat: null,
        lng: null
      },
      mostrarMapa: false,
      dialogPropostas: false,
      postoSelecionado: null,
      redeSelecionada: null,
      postosMapa: [],
      lagura_tela: 0,
      pagination: {
        rowsPerPage: 4,
        page: 1
      },
      paginationRede: {
        rowsPerPage: 4,
        page: 1
      }
    }
  },
  watch: {
    empresa (val, oldval) {
      this.carregarPostos()
      this.carregarRedes()
      this.$store.dispatch('carregarTiposPagamento')
    },
    pagination: {
      handler (val, oldval) {
        this.carregarPostos()
      },
      deep: true
    },
    paginationRede: {
      handler (val, oldval) {
        this.carregarRedes()
      },
      deep: true
    }
  },
  computed: {
    zoomControl () {
      return !this.$vuetify.breakpoint.xs
    },
    headers () {
      return this.$store.getters.headers
    },
    redes () {
      return this.$store.getters.redes
    },
    postos () {
      return this.$store.getters.postos
    },
    ofertas () {
      return this.$store.getters.ofertas
    },
    oferta () {
      return this.$store.getters.oferta
    },
    ofertasPorPosto () {
      return this.$store.getters.ofertasPorPosto
    },
    visible () {
      return this.oferta !== null
    },
    empresa () {
      return this.$store.getters.empresa
    },
    ready () {
      return !!this.headers && !!this.empresa
    },
    pages () {
      const count = this.postos.count
      if (this.pagination.rowsPerPage == null || count == null) return 0

      return Math.ceil(count / this.pagination.rowsPerPage)
    },
    pagesRede () {
      const count = this.redes.count
      if (this.paginationRede.rowsPerPage == null || count == null) return 0

      return Math.ceil(count / this.paginationRede.rowsPerPage)
    },
    tela () {
      return Number(window.innerWidth)
    }
  },
  mounted () {
    if (this.ready) {
      this.$store.dispatch('carregarTiposPagamento')
      this.carregarRedes()
      this.carregarPostos()
    }
  },
  methods: {
    carregarRedes (event) {
      if (event) {
        this.paginationRede.page = 1
      }
      const data = {
        page: this.paginationRede.page,
        page_size: this.paginationRede.rowsPerPage
      }

      if (this.filtrosRede.nome) data.nome__icontains = this.filtrosRede.nome

      this.$store.dispatch('pegarRedes', data)
    },
    carregarPostos (event) {
      if (event) {
        this.pagination.page = 1
      }
      const data = {
        page: this.pagination.page,
        page_size: this.pagination.rowsPerPage
      }

      if (this.filtrosPostos.cnpj) data.cnpj = this.filtrosPostos.cnpj
      if (this.filtrosPostos.nome_fantasia__icontains) data.nome_fantasia__icontains = this.filtrosPostos.nome_fantasia__icontains
      if (this.filtrosPostos.endereco__cidade__icontains) data.endereco__cidade__icontains = this.filtrosPostos.endereco__cidade__icontains
      if (this.filtrosPostos.endereco__estado__iexact) data.endereco__estado__iexact = this.filtrosPostos.endereco__estado__iexact

      this.$store.dispatch('pegarPostos', data)
    },
    zoomUpdated (zoom) {
      this.mapa.zoom = zoom
    },
    centerUpdated (center) {
      this.mapa.center = center
    },
    boundsUpdated (bounds) {
      this.mapa.bounds = bounds
    },
    carregarMapa (latitude, longitude) {
      this.mapa.center = [latitude, longitude]
    },
    showError (errorText) {
      this.snackbarerror.errorText = errorText
      this.snackbarerror.active = true
    },
    async abrirMapa () {
      this.mostrarMapa = !this.mostrarMapa
      if (this.postosMapa.length < 1) {
        await this.$store.dispatch('pegarPostosMapa').then(postos => {
          this.postosMapa = postos
        })
      }
    },
    fecharDialog () {
      this.postoSelecionado = null
      this.dialogPropostas = false
    },
    selecionarPosto (posto) {
      this.postoSelecionado = posto
      this.dialogPropostas = true
    },
    selecionarRede (rede) {
      this.redeSelecionada = rede
      this.dialogPropostas = true
    }
  },
  created () {
    this.lagura_tela = this.tela
    if (this.lagura_tela <= 1904 && this.lagura_tela > 957) {
      this.pagination.rowsPerPage = 3
      this.paginationRede.rowsPerPage = 3
    } else if (this.lagura_tela <= 957 && this.lagura_tela > 595) {
      this.pagination.rowsPerPage = 2
      this.paginationRede.rowsPerPage = 2
    } else if (this.lagura_tela <= 595) {
      this.pagination.rowsPerPage = 1
      this.paginationRede.rowsPerPage = 1
    }
  },
  beforeMount () {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.carregarMapa(position.coords.latitude, position.coords.longitude)
      })
      /* geolocation is available */
    } else {
      this.$root.router.push(-1)
      /* geolocation IS NOT available */
    }
    if (this.ready) {
      this.carregarPostos()
      this.carregarRedes()
    }
  },
  clear_fields () {
    this.qtdLitros = null
  }
}
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}

.leaflet-left .leaflet-control {
  margin-top: 70px
}

.leaflet-container .leaflet-control-attribution {
  font-size: 8px
}

.v-btn--bottom.v-btn--absolute.apetrus-abastecer {
  bottom: 10px;
  left:0;
  right:0;
  margin: auto
}
</style>
