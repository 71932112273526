<template>
  <v-card>
    <v-card-title class="justify-center" v-if="titulo">
        <slot name="titulo"></slot>
    </v-card-title>
    <v-card-text>
        <slot name="principal"></slot>
    </v-card-text>
    <v-card-actions class="justify-spacer-between">
        <v-flex xs6>
            <v-btn
                flat
                @click="$emit('fechar')"
                block
            >
                Não
            </v-btn>
        </v-flex>
        <v-divider vertical></v-divider>
        <v-flex xs6>
            <v-btn
                flat
                @click="$emit('confirmado')"
                color="error"
                block
            >
                SIM
            </v-btn>
        </v-flex>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['titulo']
}
</script>
