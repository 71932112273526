<template>
  <v-card>
    <v-card-title> Editar Funcionário </v-card-title>
    <v-container flex grid-list-xs>
      <v-form lazy-validation v-model="valid" ref="formConvite">
        <v-layout row wrap fill-height align-content-center justify-start>
          <v-flex sm12>
            <v-text-field
              v-model="funcionario_model.cpf"
              label="CPF"
              :rules="[(v) => !!v || 'Esse campo é obrigatório']"
              chips
              v-mask="'###.###.###-##'"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm12>
            <v-text-field
              v-model="funcionario_model.email"
              label="Email"
          ></v-text-field>
          </v-flex>
          <v-flex sm12>
            <v-text-field
              v-model="funcionario_model.nome_email"
              label="Nome"
              :rules="[(v) => !!v || 'Esse campo é obrigatório']"
              chips
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-row wrap justify="center">
              <v-btn flat v-if="!funcionario_model.bloqueado" color="error" @click="desativarFuncionario">
              <v-icon>cancel</v-icon>
                  Bloquear
              </v-btn>
              <v-btn flat v-else color="green lighten-2" @click="ativarFuncionario">
              <v-icon>check_circle</v-icon>
                  Desbloquear
              </v-btn>
          </v-row>
            </v-flex>
        </v-layout>
      </v-form>
    </v-container>
    <v-card-actions>
      <v-btn text color="info" @click="fechar()">Voltar</v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="info" @click="editarFuncionario()">Confirmar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { pessoasDao } from '@/api'

export default {
  props: ['visible', 'funcionario'],
  data () {
    return {
      valid: false,
      combobox: null,
      funcionario_model: {
        id: null,
        cpf: null,
        email: null,
        nome_email: null,
        bloqueado: null
      },
      cpf: '',
      pessoas: []
    }
  },
  mounted () {
    if (this.funcionario) {
      this.funcionario_model.id = this.funcionario.id_funcionario
      this.funcionario_model.cpf = this.funcionario.cpf
      this.funcionario_model.email = this.funcionario.email
      this.funcionario_model.nome_email = this.funcionario.nome_email
      this.funcionario_model.bloqueado = this.funcionario.bloqueado
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    ready () {
      return !!this.combobox && this.pessoas.length === 0
    },
    empresa () {
      return this.$store.getters.empresa
    }
  },
  watch: {
    '$route' () {
      this.this.carregarPessoas()
    }
  },
  methods: {
    fechar () {
      this.$refs.formConvite.reset()
      this.$emit('fechar')
    },
    desativarFuncionario () {
      this.$store.dispatch('ativarOuDesativarFuncionario', { id_funcionario: this.funcionario_model.id, bloquear: true })
      this.funcionario_model.bloqueado = true
      this.$emit('carregar')
      this.$emit('fechar')
    },
    ativarFuncionario () {
      this.$store.dispatch('ativarOuDesativarFuncionario', { id_funcionario: this.funcionario_model.id, bloquear: false })
      this.funcionario_model.bloqueado = false
      this.$emit('carregar')
      this.$emit('fechar')
    },
    editarFuncionario () {
      this.funcionario_model.cpf = this.funcionario_model.cpf.replace('.', '').replace('.', '').replace('-', '')
      if (this.$refs.formConvite.validate()) {
        this.$store.dispatch('atualizarFuncionario', this.funcionario_model)
        this.$emit('info', 'Funcionário foi editado com sucesso')
        this.$emit('carregar')
        this.$emit('fechar')
      }
    },
    carregarPessoas () {
      pessoasDao.porEmail(this.email, this.headers).then(response => {
        return response.json()
      }).then(json => {
        this.$emit('carregar')
        this.pessoas = json
      })
    }
  }
}
</script>
