<template>
  <v-card class="pa-2">
    <v-stepper v-model="step" style="box-shadow: none;">
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-layout align-center justify-center fill-height column>
            <v-flex align-self-center>
              <img :src="require(`@/assets/logomarca.png`)" alt="Logo Apetrus" width="250" class="pa-0 ma-0">
            </v-flex>
            <h1 class="mb-2" style="color: #074D6E;">Apetrus Fidelidade</h1>
            <h3 class="mb-4">
              Com o <b>Apetrus Fidelidade</b> será possível conceder prêmios aos seus clientes por abastecerem em sua empresa.
              Ative o programa, cadastre os prêmios e sai na frente da concorrência.
            </h3>
            <v-btn block large style="color: white;" color="#074D6E" @click="ativadorFidelidade">Ativar</v-btn>
          </v-layout>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-card-title class="justify-center">
            <h1 class="text-center" style="color: #074D6E;">
              PROGRAMA DE FIDELIDADE
            </h1>
          </v-card-title>
          <v-card-text>
            <v-flex sm12>
                <v-toolbar
                color="#eee"
                class="elevation-0 secondary--text"
                style="background: transparent" >
                <v-spacer></v-spacer>
                    <v-toolbar-title>{{ title }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="!hide" bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" x-small class="mr-2" rounded fab color='primary' @click="step = 5">
                          <v-icon size="18">exposure_plus_2</v-icon>
                        </v-btn>
                      </template>
                      <span>Definir pontuação</span>
                    </v-tooltip>
                    <v-tooltip v-if="!hide" bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" x-small rounded fab color='primary' @click="step++">
                          <v-icon>add</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ text_cadastro }}</span>
                    </v-tooltip>
                </v-toolbar>
            </v-flex>
          </v-card-text>
          <v-layout align-space-around justify-start fill-height column>
            <v-data-table
                  hide-default-footer
                  :hide-default-header="!premios.length"
                  :items="premios"
                  :headers="premiosHeader"
                  class="elevation-1"
                  :loading="loading"
              >
              <v-alert v-slot:no-results :value="true" color="error" icon="warning">
                  Desculpe, não encontramos nenhum dado!
              </v-alert>

              <template v-slot:item="props">
                  <tr>
                    <td>{{props.item.nome}}</td>
                    <td>{{props.item.descricao}}</td>
                    <td>{{props.item.topo_pontos}} {{props.item.topo_pontos > 1? 'pontos': 'ponto'}}</td>
                    <td>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon @click="deletarPremio(props.item)">
                            <v-icon color="primary">delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Remover prêmio</span>
                      </v-tooltip>
                    </td>
                  </tr>
              </template>
              </v-data-table>
              <h4 class="text-center text--secondary mt-4">
                {{
                  empresa.pontos_fidelidade > 0 && empresa.topo_pontuacao > 0  ? `Seus clientes ganharão ${ empresa.pontos_fidelidade == 1 ? `${empresa.pontos_fidelidade} ponto` : `${empresa.pontos_fidelidade} pontos` } a cada R$ ${empresa.topo_pontuacao} em compras` :
                  'Para seu clintes começarem a pontuar, defina uma pontuação.'
                }}
              </h4>
          </v-layout>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-card-text>
            <v-flex sm12>
              <v-toolbar
                color="#eee"
                class="elevation-0 secondary--text"
                style="background: transparent"
              >
                <v-spacer></v-spacer>
                <v-toolbar-title>{{ title }}</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
            </v-flex>
            <v-layout row wrap>
              <v-flex xs8>
                <v-text-field
                  label="Nome"
                  outline
                  v-model="nome"
                  type="text"
                ></v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  class="ml-4"
                  label="Pontos para prêmio"
                  outline
                  type="number"
                  v-model="topo_pontos"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  outline
                  label="Descrição do prêmio"
                  v-model="descricao"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-stepper-content>
        <v-stepper-content step="4">
          <v-card-text>
            <v-flex sm12>
              <v-toolbar
                color="#eee"
                class="elevation-0 secondary--text"
                style="background: transparent"
              >
                <v-spacer></v-spacer>
                <v-toolbar-title>{{ title }}</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
            </v-flex>
            <v-layout column>
              <v-flex align-self-center>
                <img
                  max-height="250"
                  max-width="250"
                  :src="imagem_premio ? imagem_premio : ''"
                  @click="imagem_premio ? carregarImagem = true : $refs.imageInput.click()"
                  style="cursor: pointer"
                />
                <v-card v-if="!imagem_premio" color="grey" height="250" width="250">
                  <v-layout justify-center align-center fill-height>
                    <div class="title white--text pa-4 text-xs-center">Inserir imagem do prêmio</div>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex class="text-xs-center">
                <v-btn
                  text
                  block
                  color="grey darken-2"
                  @click="$refs.imageInput.click()"
                >
                  <v-spacer></v-spacer>
                  <template v-if="nome_imagem">{{nome_imagem}}</template>
                  <template v-else>CARREGAR IMAGEM</template>
                  <v-spacer></v-spacer>
                  <v-icon>cloud_upload</v-icon>
                  <input
                    type="file"
                    style="display:none"
                    accept="image/*"
                    ref="imageInput"
                    @input="carregarImagem"
                  />
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-stepper-content>
        <v-stepper-content step="5">
          <v-toolbar
            color="#eee"
            class="elevation-0 secondary--text"
            style="background: transparent"
          >
            <v-spacer></v-spacer>
            <v-toolbar-title>Cadastrar pontuação por compra</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-flex xs12 align-self-center class="text--primary">
            <h5>Definir valor de compra para pontuar</h5>
            <v-text-field required v-money="money" v-model.lazy="topo_pontuacao" outline label="valor para pontual"/>
            <h5>Definir quantidade pontos ganhos a cada {{topo_pontuacao}} em compras</h5>
            <v-text-field required v-model="pontos_fidelidade" type="number" outline label="Quantidade de pontos"/>
            <h5 class="text-xs-center text--secondary">Seus clientes ganharão {{ pontos_fidelidade == 1 ? `${pontos_fidelidade} ponto` : `${pontos_fidelidade} pontos` }} a cada {{topo_pontuacao}} em compras</h5>
          </v-flex>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-card-actions v-if="step != 1">
      <v-btn rounded @click="voltar">Voltar</v-btn>
      <v-spacer></v-spacer>
      <v-btn
          v-if="step == 3"
          rounded
          color='primary'
          @click="cadastrarPremio"
      >
          Cadastrar
      </v-btn>
      <v-btn
          v-if="step == 5"
          rounded
          color='primary'
          @click="programaDeFidelidade"
      >
          CONFIRMAR
      </v-btn>
      <v-btn
          v-if="step == 4"
          rounded
          color='primary'
          @click="updatePremio"
      >
          Salvar
      </v-btn>
      <v-btn
          v-if="step == 2"
          rounded
          color='error'
          @click="ativadorFidelidade"
      >
          Desativar Fidelidade
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

import { VMoney } from 'v-money'
export default {
  directives: { money: VMoney },
  data () {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      hide: false,
      title: 'Prêmios cadastrados na fidelidade',
      step: 1,
      text_cadastro: 'Cadastrar prêmio',
      text_button: 'Visualizar combustíveis pontuados',
      icon: 'local_gas_station',
      possuiNiveis: false,
      nome: '',
      topo_pontos: 0,
      pontos_fidelidade: 0,
      topo_pontuacao: 0.00,
      combustivel: null,
      pontuacao: 0,
      descricao: '',
      nome_imagem: '',
      imagem_premio: null,
      id_premio: null,
      premios: [],
      premiosHeader: [
        {
          text: 'Nome',
          align: 'left',
          sortable: false,
          value: 'Nome'
        },
        {
          text: 'Descrição',
          align: 'left',
          sortable: false,
          value: 'Descrição'
        },
        {
          text: 'Pontos para prêmio',
          align: 'left',
          sortable: false,
          value: 'Topo de Pontos'
        },
        {
          align: 'left',
          sortable: false
        }
      ]
    }
  },
  methods: {
    ativadorFidelidade () {
      this.$store.dispatch('editarEmpresa',
        {
          temFidelidade: !this.empresa.temFidelidade,
          cnpj: this.empresa.cnpj
        }
      ).then(() => {
        this.carregarEmpresa()
      })
    },
    voltar () {
      switch (this.step) {
        case 1:
          this.fechar()
          break
        case 2:
          this.step = 1
          this.fechar()
          break
        case 3:
          this.step = 2
          this.limparCampos()
          break
        case 4:
          this.step = 2
          this.limparCampos()
          break
        case 5:
          this.limparCampos()
          this.step = 2
      }
    },
    visualizar () {
      if (this.step === 1) {
        this.step = 2
        this.icon = 'card_giftcard'
        this.text_button = 'Visualizar Prêmios'
      } else {
        this.step = 1
      }
    },
    fechar () {
      this.limparCampos()
      this.$emit('fechar')
    },
    limparCampos () {
      this.topo_pontos = 0
      this.pontuacao = 0
      this.descricao = ''
      this.nome = ''
      this.topo_pontuacao = 0
      this.pontos_fidelidade = 0
      this.nome_imagem = ''
      this.imagem_premio = null
    },
    programaDeFidelidade () {
      this.topo_pontuacao = this.topo_pontuacao.split(' ')[1]
      this.topo_pontuacao = this.topo_pontuacao.split('.').join('')
      this.topo_pontuacao = this.topo_pontuacao.split(',').join('.')
      if (this.topo_pontuaca <= 0 || this.pontos_fidelidade <= 0) {
        return
      }
      this.$store.dispatch('editarEmpresa', {
        topo_pontuacao: this.topo_pontuacao,
        pontos_fidelidade: this.pontos_fidelidade,
        cnpj: this.empresa.cnpj
      }).then(() => {
        this.carregarEmpresa()
        this.topo_pontuacao = 0
        this.pontos_fidelidade = 0
        this.step = 2
      })
    },
    updatePremio () {
      this.$store.dispatch('inserirArquivoFirebase', {
        prefixo: 'premio',
        id: this.id_premio,
        arquivo: this.imagem_premio,
        local: 'premios',
        tipo: 'png'
      }).then(downloadUrl => {
        this.$store.dispatch('premios', {
          id: this.id_premio,
          method: 'PATCH',
          body: {
            url_imagem: downloadUrl,
            empresa: this.empresa.cnpj
          }
        })
      }).then(() => {
        this.carregarPremios()
        this.step = 2
        this.id_premio = null
        this.limparCampos()
      })
    },
    carregarPremios () {
      this.$store.dispatch('premios', {
        method: 'GET',
        parameters: {
          empresa: this.empresa.cnpj
        }
      }).then(json => {
        this.premios = json
      })
    },
    carregarEmpresa () {
      const empresa = this.empresa
      this.$store.dispatch('escolherEmpresa', empresa)
    },
    cadastrarPremio () {
      this.$store.dispatch('premios', {
        method: 'POST',
        body: {
          nome: this.nome,
          descricao: this.descricao,
          topo_pontos: this.topo_pontos,
          empresa: this.empresa.cnpj
        }
      }).then(value => {
        this.id_premio = value.id
      }).then(() => {
        this.carregarPremios()
        this.limparCampos()
        this.step = 4
      })
    },
    deletarPremio (premio) {
      const resposta = confirm('Tem certeza que deseja excluir')
      if (resposta === true) {
        this.$store.dispatch('premios', {
          id: premio.id,
          method: 'DELETE',
          body: {
            empresa: this.empresa.cnpj
          }
        }).then(() => {
          this.carregarPremios()
        })
      }
    },
    carregarImagem (event) {
      this.imagem_premio = null
      const imagem = event.target.files[0]
      const reader = new FileReader()
      reader.onload = event => {
        var image = new Image()
        image.src = event.target.result
        var self = this
        image.onload = function (e) {
          var height = this.height
          var width = this.width
          if (height !== 400 || width !== 400) {
            const canvas = document.createElement('canvas')
            const maxWidth = 400
            const scaleSize = maxWidth / width
            canvas.width = maxWidth
            canvas.height = height * scaleSize
            if (canvas.height > 400) {
              const scaleSize = maxWidth / canvas.height
              canvas.height = maxWidth
              canvas.width = canvas.height * scaleSize
            }
            const ctx = canvas.getContext('2d')
            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height)
            const srcEncoded = ctx.canvas.toDataURL(e.target, 'image/jpeg')
            self.imagem_premio = srcEncoded
            self.nome_imagem = imagem.name
            return true
          }
          self.imagem_premio = event.target.result
          self.nome_imagem = imagem.name
          return true
        }
      }
      if (imagem) {
        reader.readAsDataURL(imagem)
      }
    }
  },
  computed: {
    empresa () {
      return this.$store.getters.empresa
    },
    loading () {
      return this.$store.getters.loading
    }
  },
  beforeMount () {
    this.carregarPremios()
    if (this.empresa.temFidelidade) {
      this.step = 2
      this.pontos_fidelidade = this.empresa.pontos_fidelidade
      this.topo_pontuacao = this.empresa.topo_pontuacao
    }
  },
  watch: {
    'empresa.temFidelidade' (val) {
      if (val) {
        this.step = 2
      } else {
        this.step = 1
      }
    },
    empresa (val) {
      if (val !== undefined && val !== null) {
        this.pontos_fidelidade = this.empresa.pontos_fidelidade
        this.topo_pontuacao = this.empresa.topo_pontuacao
      }
    },
    step (val, old) {
      switch (val) {
        case 1:
          this.title = 'Prêmios cadastrados na Fidelidade'
          this.text_cadastro = 'Castradar prêmio'
          this.icon = 'local_gas_station'
          this.hide = false
          this.text_button = 'Visualizar combustíveis pontuados'
          break
        case 2:
          this.title = 'Prêmios'
          this.text_cadastro = 'Cadastrar Prêmio'
          this.icon = 'card_giftcard'
          this.text_button = 'Visualizar Prêmios'
          this.hide = false
          break
        case 3:
          this.title = 'Cadastrar Prêmio'
          break
        case 4:
          this.title = 'Insira a imagem do prêmio'
          break
        case 5:
          this.title = 'Insira a imagem do prêmio'
      }
    }
  }
}
</script>
