import settings from '@/settings'

const porPosto = (cnpj, headers) => {
  return fetch(`${settings.apetrusApi}/codigospromocionais/?posto=${cnpj}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const inserir = (data, headers) => {
  return fetch(`${settings.apetrusApi}/codigospromocionais/`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(data),
    cache: 'default',
    headers: headers
  })
}

const cancelar = (data, headers) => {
  return fetch(`${settings.apetrusApi}/codigospromocionais/${data.id}/`, {
    method: 'DEconstE',
    mode: 'cors',
    cache: 'default',
    headers: headers,
    body: JSON.stringify(data)
  })
}

const atualizar = (id, data, headers, posto) => {
  return fetch(`${settings.apetrusApi}/codigospromocionais/${id}/?posto=${posto}`, {
    method: 'PUT',
    mode: 'cors',
    cache: 'default',
    body: JSON.stringify(data),
    headers: headers
  })
}

const viewSet = (headers, data) => {
  let url = `${settings.apetrusApi}/codigospromocionais/`

  if (data.id) {
    url += `${data.id}/`
  }

  const parameter = {
    method: data.method,
    mode: 'cors',
    headers: headers
  }

  if (data.parameters) {
    Object.keys(data.parameters).forEach((filter) => {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${filter}=${data.parameters[filter]}`
    })
  }

  if (data.method === 'GET') {
    parameter.cache = 'default'
  } else if (data.body) parameter.body = JSON.stringify(data.body)

  return fetch(url, parameter)
}

export default {
  porPosto,
  inserir,
  cancelar,
  atualizar,
  viewSet
}
