import settings from '@/settings'

const post = (headers, data) => {
  // Cadastrar e edita assinatura
  return fetch(`${settings.apetrusApi}/assinatura/`, {
    method: 'post',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const get = (headers, filtros) => {
  // Pegar planos do posto ou rede
  let url = `${settings.apetrusApi}/assinatura/planos/`
  if (filtros) {
    Object.keys(filtros).forEach((filter) => {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${filter}=${filtros[filter]}`
    })
  }
  return fetch(url, {
    method: 'GET',
    cache: 'default',
    mode: 'cors',
    headers: headers
  })
}

const deletar = (headers, data) => {
  return fetch(`${settings.apetrusApi}/assinatura/`, {
    method: 'DELETE',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

export default {
  get,
  post,
  deletar
}
