<template>
  <v-card class="elevation-1" color="transparent">
    <v-list v-if="funcionarios">
      <v-list-item-group v-model="funcionarios" color="primary">
        <v-list-item v-for="(funcionario, index) in funcionarios" :key="index">
          <v-list-item-icon>
            <v-tooltip bottom v-if="funcionario.confirmado == true">
              <template v-slot:activator="{ on }">
                <v-icon color="teal" v-on="on" size="30">done</v-icon>
              </template>
              Confirmado
            </v-tooltip>
                        <v-tooltip bottom v-if="funcionario.confirmado == null">
              <template v-slot:activator="{ on }">
                <v-icon color="grey lighten-1" v-on="on" size="30"
                  >watch_later</v-icon
                >
              </template>
              Pendente
            </v-tooltip>
            <v-tooltip bottom v-if="funcionario.confirmado == false">
              <template v-slot:activator="{ on }">
                <v-icon color="grey lighten-1" v-on="on" size="30"
                  >close</v-icon
                >
              </template>
              Colaborador não aceitou!
            </v-tooltip>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="funcionario.nome_email"
              class="title font-weight-regular grey--text text--darken-2"
            ></v-list-item-title>
            <v-list-item-subtitle v-if="funcionario.cpf"
              v-text="funcionario.cpf"
              class="caption"
            ></v-list-item-subtitle>
            <v-list-item-subtitle v-else
              v-text="text"
              class="caption red--text"
            ></v-list-item-subtitle>
            <v-list-item-subtitle
              v-text="funcionario.email"
              class="caption"
            ></v-list-item-subtitle>
          </v-list-item-content>
          <v-icon v-if="funcionario.bloqueado" color="error">block</v-icon>
          <v-list-item-action>
            <v-tooltip bottom v-if="funcionario.ativo">
              <template v-slot:activator="{ on }">
                <v-btn text icon small @click="dialogRemover(funcionario)">
                  <v-icon color="red" v-on="on" size="30">close</v-icon>
                </v-btn>
              </template>
              Excluir
            </v-tooltip>
            <v-tooltip bottom v-if="funcionario.ativo">
              <template v-slot:activator="{ on }">
                <v-btn text icon small @click="editarFuncionario(funcionario)">
                  <v-icon color="green" v-on="on" size="30">edit</v-icon>
                </v-btn>
              </template>
              Editar
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
      <v-list-item v-if="!funcionarios.length">
        <v-list-item-content>
          <v-list-item-title
            class="text-xs-center title font-weight-light grey--text text--darken-2"
          >
            Sem funcionarios cadastrados
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div class="text-xs-center">
      <v-pagination
        v-model="pagination.page"
        :length="pages"
        circle
      ></v-pagination>
    </div>
    <v-dialog v-if="dialogEditarFuncionario" v-model="dialogEditarFuncionario" width="400">
      <dialog-editar-funcionario @fechar="dialogEditarFuncionario = false" @carregar="carregarFuncionarios" :funcionario="funcionarioSelecionado"/>
    </v-dialog>
    <v-dialog v-model="dialogExcluir" v-if="dialogExcluir" width="450">
      <excluir-funcionario @excluir="removerFuncionario" :funcionario="funcionarioSelecionado" @fechar="dialogExcluir = false"/>
    </v-dialog>
  </v-card>
</template>

<script>

import DialogEditarFuncionario from '@/components/dialogs/DialogEditarFuncionario.vue'
import ExcluirFuncionario from '@/components/dialogs/ExcluirFuncionario.vue'

export default {
  props: ['filtros'],
  components: { DialogEditarFuncionario, ExcluirFuncionario },
  data () {
    return {
      dialogExcluir: false,
      dialogCadastrarFuncionario: false,
      dialogEditarFuncionario: false,
      funcionarioSelecionado: '',
      text: 'Atenção! é necessário adicionar um cpf ao funcionario',
      // totalItems: 0,
      pagination: {
        page: 1,
        rowsPerPage: 5
      },
      funcionario: null,
      payloadFuncionario: {
        funcionario: null,
        pagination: null,
        empresa: null
      }
    }
  },
  watch: {
    pagination: {
      handler (val, oldval) {
        this.carregarFuncionarios()
      },
      deep: true
    },
    empresa (val, oldval) {
      if (val !== null && val !== undefined && val !== oldval) {
        this.carregarFuncionarios()
      }
    }
  },
  computed: {
    pages () {
      if (this.pagination.rowsPerPage == null || this.totalItems == null) {
        return 0
      }
      return Math.ceil(this.totalItems / this.pagination.rowsPerPage)
    },
    funcionarios () {
      return this.$store.getters.funcionarios
    },
    empresa () {
      return this.$store.getters.empresa
    },
    headers () {
      return this.$store.getters.headers
    },
    totalItems () {
      return this.$store.getters.totalItemsPage
    }
  },
  mounted () {
    if (this.headers) {
      this.carregarFuncionarios()
    }
  },
  methods: {
    editarFuncionario (funcioario) {
      this.funcionarioSelecionado = funcioario
      this.dialogEditarFuncionario = true
    },
    carregarFuncionarios (event) {
      if (event) {
        this.pagination.page = 1
      }

      var data = {
        page: this.pagination.page,
        empresa: this.empresa.cnpj
      }

      if (this.filtros.nome_email) data.nome_email__icontains = this.filtros.nome_email
      if (this.filtros.cpf) data.cpf__icontains = this.filtros.cpf
      if (this.filtros.tem_veiculo) data.tem_veiculo = this.filtros.tem_veiculo
      if (this.filtros.endereco__cidade) data.endereco__cidade = this.filtros.endereco__cidade
      if (this.filtros.endereco__estado) data.endereco__estado = this.filtros.endereco__estado

      this.$store.dispatch('carregarFuncionarios', data)
    },
    dialogRemover (funcionario) {
      this.funcionarioSelecionado = funcionario
      this.dialogExcluir = true
    },
    removerFuncionario (funcionario) {
      this.payloadFuncionario.funcionario = funcionario
      this.payloadFuncionario.pagination = this.pagination
      this.payloadFuncionario.empresa = this.empresa.cnpj
      this.$store.dispatch('removerFuncionario', this.payloadFuncionario).then(() => {
        this.carregarFuncionarios()
      })
      this.dialogExcluir = false
    },
    castError (error) {
      return this.$emit('error', error)
    },
    castInfo (info) {
      return this.$emit('info', info)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
