<script src="https://cdn.jsdelivr.net/npm/vue/dist/vue.js"></script>
<template>
  <v-card>
    <v-img
      :src="require('@/assets/background.svg')"
      :min-height="$vuetify.breakpoint.xs ? '100vh' : '0'"
    >
      <v-card-title
        class="headline title-xs-center secondary--text font-weight-medium"
      >Termos de uso da empresa Apetrus</v-card-title>
      <template v-if="!loading">
        <v-card-text
          v-html="termosDeUso.texto"
          v-if="termosDeUso"
          v-on="confirmarDisabledMethods()"
        ></v-card-text>
        <br />
        <br />
        <v-footer absolute color="transparent" class="pb-3">
          <!-- <v-btn text color="grey darken-2" @click="fechar()">VOLTAR</v-btn> -->
          <v-spacer></v-spacer>
          <v-btn
            text
            color="secondary"
            :disabled="confirmarDisabled"
            @click="confirmarLeitura()"
          >CONFIRMAR LEITURA</v-btn>
        </v-footer>
      </template>
      <template v-else>
        <v-layout fill-height justify-center align-center>
          <v-progress-circular indeterminate size="250" color="primary"></v-progress-circular>
        </v-layout>
      </template>
    </v-img>
  </v-card>
</template>

<script>
export default {
  data () {
    return {
      confirmarDisabled: true
    }
  },
  computed: {
    loading () {
      return this.$store.getters.loading
    },
    termosDeUso () {
      return this.$store.getters.termosDeUso
    }
  },
  mounted () {
    this.carregarTermos()
  },
  methods: {
    confirmarDisabledMethods() {
      this.confirmarDisabled = false
    },
    handleScroll: function (evt, el) {
      if (window.scrollY > 50) {
        el.setAttribute(
          'style',
          'opacity: 1; transform: translate3d(0, -10px, 0)'
        )
      this.confirmarDisabled = false
      }
      return window.scrollY > 100
    },
    fechar () {
      this.confirmarDisabled = true
      this.$emit('fechar')
    },
    confirmarLeitura () {
      this.$emit('confirmarLeitura')
      this.fechar()
    },
    carregarTermos () {
      this.$store.dispatch('carregarTermosDeUso')
    },
    onScroll (e) {
      if (
        e.target.scrollTop ===
        e.target.scrollHeight - e.target.offsetHeight
      ) {
        this.confirmarDisabled = false
      }
    }
  }
}
</script>
