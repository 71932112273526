<template>
  <v-layout
    justify-center
    align-content-center
    style="
      height: 50em;
    "
  >
    <v-layout wrap justify-space-around class="mt-4">
      <v-flex
        xs12
        sm12
        md2
        class="icone"
      >
        <v-icon size="200" color="error">block</v-icon>
      </v-flex>
      <v-flex
        xs12
        sm12
        md10
        class="texto"
        >
        <h2 class="error--text">
          {{ empresa.ativo ? 'Essa tela está bloqueada para acesso, pois a empresa logada não tem autorização para acessa-lá.' : 'Essa tela está bloqueada para acesso enquando a empresa estiver desativa.' }}
        </h2>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  computed: {
    empresa () {
      return this.$store.getters.empresa
    }
  }
}
</script>

<style scoped>
  .texto {
    font-size: 1.5em;
    text-align: center;
    justify-content: center;
    display: flex;
  }

  .icone {
    display: flex;
    justify-content: center;
  }

  @media screen and (min-width: 960px) {
    .texto {
      align-items: center;
    }
    .icone {
      align-items: center;
    }
  }
  @media screen and (max-width: 959px) {
    .texto {
      align-items: start;
    }
    .icone {
      align-items: end;
    }
  }
</style>
