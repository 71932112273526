<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || empresa.tipo_empresa !== 1)"/>
  <v-layout align-space-around justify-start fill-height column class="pt-5" v-else>
    <v-toolbar
        color="transparent"
        class="elevation-0 secondary--text"
      >
        <v-toolbar-title>Gerenciar Frota</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="mr-4" small fab color="info"  @click="showFilter = !showFilter">
          <v-icon>{{ !showFilter ? 'filter_list' : 'filter_list_off' }}</v-icon>
        </v-btn>
        <v-btn @click="dialogPreCadastro = true" rounded small color="primary"><v-icon class="mr-2">list_alt</v-icon> Pré-Cadastro</v-btn>
        <v-dialog v-model="dialogAdicionarVeiculo" width="800" scrollable :fullscreen="$vuetify.breakpoint.xs" persistent>
          <template v-slot:activator="{ on }">
            <v-chip class="ma-2" color='info' v-on="on">
              <v-icon left>directions_car</v-icon>
              Cadastrar Veiculo
            </v-chip>
          </template>
          <adicionar-veiculo
            v-on:fechar="dialogAdicionarVeiculo = false"
            v-on:info="showInfo"
            v-on:error="showError"
            :isVenda="false"
          ></adicionar-veiculo>
        </v-dialog>

        <v-dialog v-model="dialogAdicionarGrupo" width="500" persistent>
          <template v-slot:activator="{ on }">
            <v-chip class="ma-2" color='purple' text-color="white" v-on="on" >
              <v-icon left>group</v-icon>
              Criar Grupos
            </v-chip>
          </template>
          <v-card>
            <v-card-title
              class="headline grey lighten-2"
              primary-title
            >
              Grupos de Veículos
            </v-card-title>
              <v-card-text>
              <v-text-field
                v-model="grupoVeiculos.descricaoGrupo"
                label="Descrição"
                required
              ></v-text-field>
            </v-card-text>
              <v-card-actions>
                <v-btn text color="info" @click="dialogAdicionarGrupo=false">
                  Voltar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color='info' @click="insertClasseDeVeiculo()">
                  Confirmar
                </v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-slide-x-transition>
          <v-card v-show="showFilter" class="ml-4 mr-4">
            <v-card-text>
              <v-layout wrap justify-space-between>
                <v-flex xs10 md11>
                  <v-layout wrap justify-space-around>
                    <v-flex xs12 sm6 md6 lg2 xl2>
                      <v-text-field label="Modelo" v-model="filtros.modelo"></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6 lg2 xl2>
                      <v-text-field label="Marca" v-model="filtros.marca"></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6 lg2 xl2>
                      <v-text-field label="Ano" v-model="filtros.ano"></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6 lg2 xl2>
                      <v-text-field label="CPF" v-model="filtros.motorista__cpf" v-mask="'###.###.###-##'"></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6 lg3 xl3>
                      <v-select
                        v-model="filtros.combustivel_nome"
                        :items="combustiveis"
                        label="Combustível"
                        item-text="nome"
                        item-value="nome"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs2 md1 align-self-center>
                  <v-btn small fab color="info" @click="buscarVeiculos($event)">
                    <v-icon>search</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-slide-x-transition>
    <v-flex>
      <v-select
        clearable
        label="Grupo dos veiculos"
        :items="grupoVeiculos"
         item-text="descricao"
         item-value="descricao"
        v-model="selectGroup"
        @change='buscarVeiculosporClasse()'
      ></v-select>
      <v-data-table
        hide-default-footer
        :items="veiculos"
        :headers="veiculosHeaders"
        hide-footer
        no-data-text="Você ainda não possui veículos"
        class="elevation-1"
      >
        <template v-slot:item="props">
          <tr>
            <td>
              <template v-if="props.item.cor">
                <v-icon :color="formatarCor(props.item.cor)">airport_shuttle</v-icon>
              </template>
                <v-icon v-else :color="formatarCor(props.item.cor)">airport_shuttle</v-icon>
            </td>
            <td class="text-xs-left">{{ props.item.tipo_veiculo ? props.item.tipo_veiculo.denominacao : '' }}</td>
            <td class="text-xs-right">{{ props.item.identificacao }}</td>
            <td class="text-xs-right">{{ props.item.modelo_marca }}</td>
            <td class="text-xs-right pl-0">
              <template v-for="item in props.item.combustiveis">
                <v-tooltip :key="item.combustivel.id_combustivel" :color="item.combustivel.color" top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <svg-filler :path="require(`@/assets/combs/${item.combustivel.icone}.svg`)" width="40px" height="40px" :fill="item.combustivel.color"/>
                    </span>
                  </template>
                  <span v-if="item.combustivel.nome">{{item.combustivel.nome}}</span>
                </v-tooltip>
              </template>
            </td>
            <td class="text-xs-right" v-if="props.item.motorista">
              <v-chip
                v-if="props.item.motorista.pessoa"
                class="ma-2"
                close
                color="black"
                outlined
                @click:close="removerMotorista(props.item)"
              >
                {{props.item.motorista.nome_email}}
              </v-chip>
              </td>
            <v-menu :close-on-content-click="false" v-else>
                <template v-slot:activator="{ on }">
                  <v-btn text icon color="green" v-on="on">
                    <v-icon>person_add</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in funcionarios"
                      :key="index"
                      @click="inserirMotoristaNoVeiculo(props.item, item)"
                    >
                      <v-list-item-title v-if="item.pessoa.nome">{{ item.pessoa.nome }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                      <v-pagination v-model="pagination.page" :length="pages" circle></v-pagination>
                  </v-card-actions>
                </v-card>
            </v-menu>
            <td class="text-xs-rigth">
              <v-chip
                  v-if="props.item.classe"
                  class="ma-2"
                  close
                  color="black"
                  outlined
                  @click:close="removerVeiculoDoGrupo(props.item)"
                >
                  {{props.item.classe.descricao}}
                </v-chip>
              <v-menu v-else>
                <template v-slot:activator="{ on }">
                  <v-btn text icon color="purple" v-on="on">
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in grupoVeiculos"
                    :key="index"
                    @click="inserirVeiculoNoGrupo(props.item, item)"
                  >
                    <v-list-item-title>{{ item.descricao }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td class="text-xs-right">
              <v-btn text icon color="info" @click="editarVeiculo(props.item)">
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn text icon color="red" @click="excluirVeiculo(props.item)">
                <v-icon>close</v-icon>
              </v-btn>

            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="text-xs-center">
        <v-pagination v-model="paginationVeiculo.page" :length="pagesVeiculo" circle></v-pagination>
      </div>
    </v-flex>
    <v-dialog
      scrollable
      v-model="dialogEditarVeiculo"
      v-if="dialogEditarVeiculo"
      :fullscreen="$vuetify.breakpoint.xs"
      width="600"
      persistent
    >
      <editar-veiculo
        :veiculo="veiculo"
        v-on:info="showInfo"
        @fechar="dialogEditarVeiculo = false"
        v-on:error="showError"
        v-on:fechar="veiculo = null"
      />
    </v-dialog>
    <v-dialog
      scrollable
      v-model="dialogExcluirVeiculo"
      v-if="dialogExcluirVeiculo"
      :fullscreen="$vuetify.breakpoint.xs"
      width="620"
      persistent
    >
      <excluir-veiculo
        :veiculo="veiculo"
        @carregar="buscarVeiculos()"
        @fechar="dialogExcluirVeiculo = false"
        v-on:info="showInfo"
        v-on:error="showError"
        v-on:fechar="veiculo = null"
      />
    </v-dialog>
    <v-snackbar
      v-model="snackbar.active"
      :color="snackbar.color"
      :multi-line="snackbar.multiLine"
      :timeout="snackbar.timeout"
      :vertical="snackbar.vertical"
    >
      {{ snackbar.text }}
      <v-btn
        text
        @click="snackbar.active = false"
      >
        Fechar
      </v-btn>
    </v-snackbar>
    <v-dialog width="900" v-model="dialogPreCadastro" v-if="dialogPreCadastro" scrollable>
      <pre-cadastro @fechar="dialogPreCadastro = false"></pre-cadastro>
    </v-dialog>
  </v-layout>
</template>

<script>

import { veiculoDao, funcionariosDao } from '@/api'
import EditarVeiculo from '@/components/dialogs/DialogEditarVeiculo'
import AdicionarVeiculo from '@/components/dialogs/DialogAdicionarVeiculo'
import ExcluirVeiculo from '@/components/dialogs/DialogExcluirVeiculo.vue'
import PreCadastro from '@/components/dialogs/PreCadastro'
import AvisoBloqueio from '@/components/AvisoBloqueio'

export default {
  components: { EditarVeiculo, AdicionarVeiculo, ExcluirVeiculo, PreCadastro, AvisoBloqueio },
  data () {
    return {
      showFilter: false,
      dialogPreCadastro: false,
      filtros: {
        modelo: '',
        marca: '',
        ano: '',
        motorista__cpf: '',
        combustivel_nome: ''
      },
      snackbar: {
        active: false,
        color: '',
        multiLine: true,
        timeout: 4000,
        vertical: true,
        text: ''
      },
      menuMotoristas: false,
      grupo: {
        descricaoGrupo: null
      },
      totalItems: 0,
      pagination: {
        page: 1,
        rowsPerPage: 5
      },
      paginationVeiculo: {
        page: 1,
        rowsPerPage: 5
      },
      addDriver: false,
      data: {
        id_funcionario: null,
        veiculo: null,
        pagination: null
      },
      classes: [],
      dialogAdicionarVeiculo: false,
      dialogAdicionarGrupo: false,
      dialogEditarVeiculo: false,
      dialogExcluirVeiculo: false,
      dialogMostrarGrupos: false,
      veiculo: null,
      funcionario: null,
      funcionarios: [],
      selectGroup: null,
      veiculosHeaders: [
        {
          text: 'Cor',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tipo do Veículo',
          align: 'left',
          sortable: false,
          value: 'tipo_veiculo'
        },
        {
          text: 'Placa',
          align: 'left',
          sortable: false,
          value: 'identificacao'
        },
        {
          text: 'Modelo',
          align: 'left',
          sortable: false,
          value: 'modelo_marca'
        },
        {
          text: 'Combustível',
          align: 'left',
          sortable: false,
          value: 'combustivel.nome'
        },
        {
          text: 'Motorista',
          align: 'left',
          sortable: false,
          value: 'motorista.pessoa.nome'
        },
        {
          text: 'Grupo',
          align: 'left',
          sortable: false,
          value: 'grupo.descricao'
        },
        {
          align: 'right',
          sortable: false
        }
      ]
    }
  },
  watch: {
    selectGroup (val) {
      if (val) {
        this.buscarVeiculosporClasse()
      } else {
        this.buscarVeiculos()
      }
    },
    empresa (val) {
      this.buscarVeiculos()
      this.getClassesporEmpresa()
    },
    pagination: {
      handler (val, oldval) {
        this.carregarMotoristas()
      },
      deep: true
    },
    paginationVeiculo: {
      handler (val, oldval) {
        this.buscarVeiculos()
      },
      deep: true
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    combustiveis () {
      return this.$store.getters.combustiveis
    },
    veiculos () {
      return this.$store.getters.veiculos
    },
    pages () {
      if (this.pagination.rowsPerPage == null || this.totalItems == null) {
        return 0
      }
      return Math.ceil(this.totalItems / this.pagination.rowsPerPage)
    },
    pagesVeiculo () {
      if (this.paginationVeiculo.rowsPerPage == null || this.totalItemsVeiculo == null) {
        return 0
      }
      return Math.ceil(this.totalItemsVeiculo / this.paginationVeiculo.rowsPerPage)
    },
    empresa () {
      return this.$store.getters.empresa
    },
    grupoVeiculos () {
      return this.$store.getters.grupoVeiculos
    },
    totalItemsVeiculo () {
      return this.$store.getters.totalItemsPage
    },
    filteredItems () {
      return this.veiculos.filter((i) => {
        return !this.selectGroup || (i.classe.descricao === this.selectGroup)
      })
    }
  },
  mounted () {
    if (this.empresa && this.headers) {
      this.buscarVeiculos()
      this.$store.dispatch('carregarCombustiveis')
      this.carregarMotoristas()
      this.getClassesporEmpresa()
    }
  },
  methods: {
    inserirVeiculoNoGrupo (veiculo, classe) {
      var pagination = {
        paginationVeiculo: this.paginationVeiculo,
        cnpj: this.empresa.cnpj
      }
      var data = {
        veiculo: veiculo.id_veiculo,
        classe: classe.id,
        pagination: pagination
      }
      this.$store.dispatch('inserirVeiculoNoGrupo', data)
    },
    removerVeiculoDoGrupo (veiculo) {
      var pagination = {
        paginationVeiculo: this.paginationVeiculo,
        cnpj: this.empresa.cnpj
      }
      var data = {
        veiculo: veiculo.id_veiculo,
        pagination: pagination
      }
      this.$store.dispatch('removerVeiculoDoGrupo', data)
    },
    carregarMotoristas () {
      if (this.headers) {
        var data = {
          pagination: this.pagination,
          empresa: this.empresa.cnpj
        }
        funcionariosDao
          .getFuncionariosEmpresaConfirmados(data, this.headers)
          .then(res => res.json())
          .then(json => {
            this.totalItems = json.count
            this.funcionarios = json.results
          })
      }
    },
    inserirMotoristaNoVeiculo (veiculo, funcionario) {
      var pagination = {
        paginationVeiculo: this.paginationVeiculo,
        cnpj: this.empresa.cnpj
      }
      this.data.id_funcionario = funcionario.id_funcionario
      this.data.veiculo = veiculo
      this.data.pagination = pagination
      this.$store.dispatch('inserirMotorista', this.data)
      this.buscarVeiculos()
      this.addDriver = false
    },
    removerMotorista (veiculo) {
      var pagination = {
        paginationVeiculo: this.paginationVeiculo,
        cnpj: this.empresa.cnpj
      }
      this.data.id_veiculo = veiculo.id_veiculo
      this.data.pagination = pagination
      this.$store.dispatch('removerMotorista', this.data)
    },
    editarVeiculo (veiculo) {
      this.veiculo = veiculo
      this.dialogEditarVeiculo = true
    },
    excluirVeiculo (veiculo) {
      this.veiculo = veiculo
      this.dialogExcluirVeiculo = true
    },
    getClassesporEmpresa () {
      this.$store.dispatch('getClassesporEmpresa')
    },
    insertClasseDeVeiculo () {
      if (this.grupoVeiculos.descricaoGrupo === undefined || this.grupoVeiculos.descricaoGrupo === null) {
        this.showError('Insira uma descrição ao grupo')
        return
      }
      this.$store.dispatch('insertClasseDeVeiculo', this.grupoVeiculos.descricaoGrupo)
      this.dialogAdicionarGrupo = false
    },
    apagarVeiculo (veiculo) {
      veiculoDao.deleteVeiculoByEmpresa(veiculo.id_veiculo, this.headers)
        .then(response => {
          if (response.ok) {
            // this.$store.dispatch('carregarVeiculos', this.empresa.cnpj)
            this.buscarVeiculos()
            this.snackbar.color = 'info'
            this.snackbar.text = 'Veículo apagado com sucesso'
            this.snackbar.active = true
          }
        })
    },
    buscarVeiculos () {
      var data = {
        page: this.paginationVeiculo.page,
        cnpj: this.empresa.cnpj
      }

      if (this.filtros.motorista__cpf) data.motorista__cpf = this.filtros.motorista__cpf
      if (this.filtros.modelo) data.modelo__icontains = this.filtros.modelo
      if (this.filtros.marca) data.marca__icontains = this.filtros.marca
      if (this.filtros.ano) data.ano_fabricacao = this.filtros.ano
      if (this.filtros.combustivel_nome) data.combustivel_nome = this.filtros.combustivel_nome

      this.$store.dispatch('carregarVeiculos', data)
    },
    buscarVeiculosporClasse () {
      var data = {
        paginationVeiculo: this.paginationVeiculo,
        classe: this.selectGroup,
        cnpj: this.empresa.cnpj
      }
      this.$store.dispatch('carregarVeiculosporClasse', data)
    },
    showError (error) {
      this.snackbar.color = 'error'
      this.snackbar.text = error
      this.snackbar.active = true
    },
    showInfo (info) {
      this.snackbar.color = 'info'
      this.snackbar.text = info
      this.snackbar.active = true
      this.buscarVeiculos()
    },
    formatarCor (cor) {
      if (cor === null) return ''
      if (cor === undefined) return ''
      if (cor.length === 6) return `#${cor}`
      if (cor.length === 7) return cor
      if (cor.length === 9) return cor.slice(0, 7)
      else {
        let teste = parseInt(cor)
        teste = teste.toString(16)
        return `#${teste.slice(2, 8)}`
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
