import settings from '@/settings'

// Pega preço dos combustiveis de um posto
const pegarPorPosto = (headers, cnpj) => {
  return fetch(`${settings.apetrusApi}/precoscombustivel/porposto/?cnpj=${cnpj}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

export default {
  pegarPorPosto
}
