<template>
  <v-card>
    <v-toolbar color="primary">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="fechar">
            <v-icon color="white">close</v-icon>
          </v-btn>
        </template>
        <span>Fechar</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-toolbar-title style="color: white;">
        Excluir proposta
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-alert v-if="error" type="error" class="mt-4 ml-1 mr-1 mb-1 pa-1">
      <h4>{{error}}</h4>
    </v-alert>
    <v-card-text>
      <v-row v-if="this.proposta">
        <v-col cols="12" sm="6" md="12">
          <h3 class="text-xs-center red--text my-3">ATENÇÃO! Ao confirmar esta operação não poderá mas realizar ações com essa proposta</h3>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions align-content-space-between>
      <v-btn text color="red" dark @click="desativarProposta()">Confirmar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['proposta'],
  data () {
    return {
      mostra: true,
      error: null,
      cor: ''
    }
  },
  methods: {
    fechar () {
      this.error = null
      this.$emit('carregar')
      this.$emit('fechar')
    },
    desativarProposta () {
      this.$store.dispatch('desativarProposta', this.proposta.id).then(() => {
      }).catch(() => {
        this.error = 'Não foi possível excluir a proposta'
      })
      this.fechar()
    }
  }
}
</script>
