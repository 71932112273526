<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || empresa.tipo_empresa !== 1)"/>
  <v-layout align-space-around justify-start fill-height column class="pt-5" v-else>
    <v-toolbar
      color="transparent"
      class="elevation-0 secondary--text"
      style="background: transparent">
      <v-toolbar-title>Propostas</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar color="transparent" class="elevation-0 secondary--text">
        <v-spacer></v-spacer>
        <v-btn class="mr-4" small rounded  @click="showFilter = !showFilter">
          <v-icon>{{ !showFilter ? 'filter_list' : 'filter_list_off' }}</v-icon>
        </v-btn>
      </v-toolbar>
        <v-btn small rounded @click="dialogPropApetrus = true">
            <img
              style="width: 30px; margin-left: -10px"
              :src="require(`@/assets/apetrus.png`)"
              class="mr-2"
            />
            Apetrus Propostas
        </v-btn>
    </v-toolbar>
    <v-slide-x-transition>
      <v-card v-show="showFilter" class="ml-4 mr-4 mb-4">
        <v-card-text>
          <v-layout wrap justify-space-between>
            <v-flex xs10 md11>
              <v-layout wrap justify-space-around>
                <v-flex xs11 sm5 md3 lg2 xl2>
                  <v-text-field label="Identificação" v-model="filtros.id" type="number"></v-text-field>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-text-field label="Data de abertura" v-model="filtros.data_abertura" type="date"></v-text-field>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-select
                    v-model="filtros.status"
                    :items="[{value:'true', text:'Aceita'},{value:'false', text:'Negada'}]"
                    label="Estado da proposta"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-select
                    v-model="filtros.modelo_faturamento"
                    :items="[{value:'prepago', text:'Pré-pago'}, {value:'pospago', text:'Pós-pago'}, {value:'presencial', text:'Presencial'}]"
                    item-text="text"
                    item-value="value"
                    label="Modelo de faturamento"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2 md1 align-self-center>
              <v-btn small fab color="info" @click="carregarPropostas($event)">
                <v-icon>search</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-slide-x-transition>
      <v-data-table
        hide-default-footer
        :headers="propostasHeaders"
        :items="propostas.results"
        :options.sync="pagination"
        no-data-text="Você ainda não possui propostas"
        class="elevation-1"
      >
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-left">
                <v-tooltip bottom v-if="props.item.status==true">
                  <template v-slot:activator="{ on }">
                    <v-icon color="teal" dark v-on="on">check_circle_outline</v-icon>
                  </template>
                  <span>Proposta Aceita.</span>
                </v-tooltip>
                <v-tooltip bottom v-if="props.item.status==null && props.item.data_resposta == null">
                  <template v-slot:activator="{ on }">
                    <v-icon color="primary" dark v-on="on">query_builder</v-icon>
                  </template>
                  <span>Aguardando Resposta.</span>
                </v-tooltip>
                <v-tooltip bottom v-if="props.item.status==false">
                  <template v-slot:activator="{ on }">
                    <v-icon color="red" dark v-on="on">highlight_off</v-icon>
                  </template>
                  <span>Recusado.</span>
                </v-tooltip>
                <v-tooltip bottom v-if="props.item.status==null && props.item.data_resposta">
                  <template v-slot:activator="{ on }">
                    <v-icon color="teal" dark v-on="on">feedback</v-icon>
                  </template>
                  <span>Responda o Posto</span>
                </v-tooltip>
              </td>
              <td class="text-xs-left">
                {{props.item.identificacao}}
              </td>
            <td>{{ tipos_proposta[props.item.modelo_faturamento]}}</td>
            <td>{{ getDestinatario(props.item.posto || props.item.rede) }}</td>
            <td>{{ valorProposta(props.item) | currency({ fractionCount: 2 }) }}</td>
            <td>{{ props.item.data_abertura | formatDate('DD/MM/YYYY')}}</td>
            <td class="text-xs-right">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn class="mr-1" v-on="on" icon color="primary" @click="detalharProposta(props.item)">
                    <v-icon>remove_red_eye</v-icon>
                  </v-btn>
                </template>
                <span>Detalhes da proposta</span>
              </v-tooltip>
              <v-tooltip left v-if="props.item.modelo_faturamento !== 'presencial' && props.item.modelo_faturamento !== 'credito'">
                <template v-slot:activator="{ on }">
                  <v-btn class="mr-1" v-on="on" icon color="green" @click="aumetarSaldoProposta(props.item)">
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <span>Adicionar saldo</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn class="mr-1" v-on="on" icon color="red" @click="desativarProposta(props.item)">
                    <v-icon>cancel</v-icon>
                  </v-btn>
                </template>
                <span>Excluir Proposta</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    <div class="text-xs-center">
      <v-pagination v-model="pagination.page" :length="pages" circle></v-pagination>
    </div>
    <v-snackbar
      v-model="snackbar.active"
      :color="snackbar.color"
      :multi-line="snackbar.multiLine"
      :timeout="snackbar.timeout"
      :vertical="snackbar.vertical"
    >
      {{ snackbar.text }}
      <v-btn
        text
        @click="snackbar.active = false"
      >
        Fechar
      </v-btn>
    </v-snackbar>
    <v-dialog v-if="dialogDetalhes" v-model="dialogDetalhes" width="400">
      <dialog-detalhes-proposta @fechar="dialogDetalhes = false" @carregar="carregarPropostas()" :proposta="propostaSelecionada"/>
    </v-dialog>
    <v-dialog v-if="dialogAumentarSaldo" v-model="dialogAumentarSaldo" width="700">
      <dialog-aumentar-saldo @fechar="dialogAumentarSaldo = false" v-on:error="showError" @carregar="carregarPropostas()" :proposta="propostaSelecionada"/>
    </v-dialog>
    <v-dialog v-if="dialogDesativarProposta" v-model="dialogDesativarProposta" width="550">
      <dialog-excluir-proposta @fechar="dialogDesativarProposta = false" @carregar="carregarPropostas()" :proposta="propostaSelecionada"/>
    </v-dialog>
    <v-dialog v-if="dialogPropApetrus" scrollable v-model="dialogPropApetrus" width="550">
      <dialog-proposta-apetrus @fechar="dialogPropApetrus = false" @carregar="carregarPropostas()" :proposta="propostaSelecionada"/>
    </v-dialog>
  </v-layout>
</template>
<script>
import DialogDetalhesProposta from '@/components/dialogs/DialogDetalhesProposta'
import DialogAumentarSaldo from '@/components/dialogs/DialogAumentarSaldo'
import DialogExcluirProposta from '@/components/dialogs/DialogExcluirProposta'
import DialogPropostaApetrus from '@/components/dialogs/PropostaApetrus'
import AvisoBloqueio from '@/components/AvisoBloqueio'
export default {
  components: { DialogDetalhesProposta, DialogAumentarSaldo, DialogExcluirProposta, DialogPropostaApetrus, AvisoBloqueio },
  data () {
    return {
      filtros: {
        id: '',
        is_apetrus: '',
        data_abertura: '',
        status: '',
        modelo_faturamento: ''
      },
      showFilter: false,
      dialogDetalhes: false,
      dialogPropApetrus: false,
      dialogAumentarSaldo: false,
      dialogDesativarProposta: false,
      propostaSelecionada: null,
      tipos_proposta: {
        credito: 'Crédito Apetrus',
        // pospago: 'Pós-Pago',
        prepago: 'Pré-pago',
        presencial: 'Presencial'
      },
      snackbar: {
        active: false,
        color: '',
        multiLine: true,
        timeout: 4000,
        vertical: true,
        text: ''
      },
      pagination: {
        page: 1,
        rowsPerPage: 5
      },
      propostasHeaders: [
        {
          value: 'proposta.status',
          sortable: false
        },
        {
          text: 'Identificação',
          value: 'identificacao',
          sortable: false
        },
        {
          text: 'Tipo',
          align: 'left',
          sortable: false,
          value: 'Tipo'
        },
        {
          text: 'Destinatário',
          align: 'left',
          sortable: false,
          value: 'nome'
        },
        {
          text: 'Total',
          value: 'valor',
          sortable: false,
          align: 'left'
        },
        {
          text: 'Data Abertura',
          value: 'data_abertura',
          sortable: false,
          align: 'left'
        },
        {
          align: 'right',
          sortable: false
        }
      ]
    }
  },
  watch: {
    empresa (val, oldval) {
      if (val !== null && val !== undefined && val !== oldval) {
        this.carregarPropostas()
      }
    },
    pagination: {
      handler (val, oldval) {
        this.carregarPropostas()
      },
      deep: true
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    empresa () {
      return this.$store.getters.empresa
    },
    propostas () {
      return this.$store.getters.propostas
    },
    pages () {
      const count = this.propostas.count
      if (this.pagination.rowsPerPage == null || count == null) return 0

      return Math.ceil(count / this.pagination.rowsPerPage)
    }
  },
  mounted () {
    if (this.empresa) {
      this.carregarPropostas()
    }
  },
  methods: {
    valorProposta (proposta) {
      if (proposta.modelo_faturamento === 'credito') return proposta.credito_apetrus
      else if (proposta.modelo_faturamento === 'presencial') return undefined
      return proposta.valor
    },
    getDestinatario  (destinatario) {
      if (!destinatario) return 'Apetrus'
      else if (destinatario.nome) return `Rede ${destinatario.nome}`
      else if (destinatario.nome_fantasia) return `Posto ${destinatario.nome_fantasia}`
      return undefined
    },
    detalharProposta (proposta) {
      this.propostaSelecionada = proposta
      this.dialogDetalhes = true
    },
    aumetarSaldoProposta (proposta) {
      this.propostaSelecionada = proposta
      this.dialogAumentarSaldo = true
    },
    desativarProposta (proposta) {
      this.propostaSelecionada = proposta
      this.dialogDesativarProposta = true
    },
    carregarPropostas (event) {
      if (event) {
        this.pagination.page = 1
      }

      var data = {
        pagination: this.pagination,
        filtros: {}
      }

      if (this.filtros.id) data.filtros.id = this.filtros.id
      if (this.filtros.is_apetrus) data.filtros.is_apetrus = this.filtros.is_apetrus
      if (this.filtros.data_abertura) data.filtros.data_abertura = this.filtros.data_abertura
      if (this.filtros.status) data.filtros.status = this.filtros.status
      if (this.filtros.modelo_faturamento) data.filtros.modelo_faturamento = this.filtros.modelo_faturamento

      this.$store.dispatch('buscarPropostas', data)
    },
    showError (error) {
      this.snackbar.color = 'error'
      this.snackbar.text = error
      this.snackbar.active = true
    },
    showInfo (info) {
      this.snackbar.color = 'info'
      this.snackbar.text = info
      this.snackbar.active = true
    },
    recarregarPagina () {
      document.location.reload(true)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
