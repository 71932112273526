<template>
  <l-marker
    :visible="marker.visible"
    :draggable="marker.draggable"
    :lat-lng="marker.position"
    :icon="marker.icon"
    v-if="marker.icon"
    @click="pegarItens"
  >
    <template v-if="posto">
      <l-popup :options="{ className: 'apetrus-popup' }">
        <v-card light class="elevation-0" width="250">
          <v-img
            :src="!!posto.url_imagem_posto ? posto.url_imagem_posto : require('@/assets/posto-default.png')"
            aspect-ratio="2.75"
            gradient="to top right, rgba(50,115,201,.33), rgba(25,32,72,.7)"
          >
            <v-container fill-height fluid>
              <v-layout fill-height>
                <v-flex xs12 align-end flexbox>
                  <span class="subheading font-weight-bold white--text">{{posto.nome_fantasia}}</span>
                </v-flex>
              </v-layout>
            </v-container>
          </v-img>
          <v-list class="transparent pb-0" v-if="combustiveis">
            <template v-for="(combustivel, index) in combustiveis">
              <v-divider v-if="index > 0" :key="index"></v-divider>
              <v-list-item ripple :key="`${combustivel.id}list`" @click="$emit('postoSelecionado', posto)">
                <v-list-item-avatar>
                  <svg-filler
                    :path="require(`@/assets/combs/${combustivel.combustivel.icone}.svg`)"
                    width="50px"
                    height="50px"
                    :fill="combustivel.combustivel.color"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 0.9em;" v-html="combustivel.combustivel.nome"></v-list-item-title>
                  <!-- <v-list-item-subtitle style="font-size: 0.9em;">{{calcularOferta(combustivel) | currency({ fractionCount: 2 })}}</v-list-item-subtitle> -->
                </v-list-item-content>
                <v-list-item-action>
                  <!-- <v-btn icon ripple>
                    <v-icon color="success">shopping_cart</v-icon>
                  </v-btn> -->
                </v-list-item-action>
              </v-list-item>
            </template>
            <v-list-item v-if="combustiveis.length == 0">
              <v-list-item-content>
                  <v-list-item-title style="font-size: 1em;">Não há combustíveis cadastrados</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </l-popup>
    </template>
  </l-marker>
</template>

<script>
import L from 'leaflet'
import { LMarker, LPopup } from 'vue2-leaflet'
import Gota from '@/components/util/Gota'

export default {
  components: {
    LMarker,
    LPopup,
    // eslint-disable-next-line vue/no-unused-components
    Gota
  },
  props: ['posto'],
  data () {
    return {
      dialogPagCodigo: false,
      postoTemPromocoes: false,
      promocoes: [],
      combustiveis: [],
      marker: {
        position: { lat: null, lng: null },
        draggable: false,
        visible: true,
        res: null,
        icon: null
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.marker.position.lat = this.posto.latitude
      this.marker.position.lng = this.posto.longitude
    }, 500)

    this.marker.icon = L.divIcon({
      className: 'markerdiv',
      html: `<svg
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        id="svg8"
        version="1.1"
        viewBox="0 0 40 37"
        height="100%"
        width="100%">
        <defs
            id="defs2" />
  <g
     id="layer1"
     transform="translate(-31.24555,-220.8983)">
    <g
       id="g825"
       transform="matrix(0.32828337,0,0,0.32828337,46.408033,172.58935)">
      <path
         style="fill:#fdb54b;stroke-width:0.11066704"
         d="m 7.9583906,259.5939 c -0.038288,-0.0441 -0.020082,-0.49289 0.039283,-0.99617 C 8.305059,256.0135 7.34497,252.02392 5.3785335,247.7157 4.0538065,244.81331 1.2260434,239.97878 -0.64163772,237.42328 -7.3700708,228.21703 -13.155029,225.5013 -30.448874,214.76395 c -8.47923,-7.70361 -14.601453,-13.05101 -15.364811,-21.9492 -1.326649,-15.46428 1.452115,-30.94623 19.815444,-42.13648 8.005189,-3.06573 13.317905,-3.40359 22.4133462,-3.81598 11.6511505,1.88809 18.8719048,5.74386 25.5257448,14.65651 5.039351,6.83573 5.259186,11.57989 6.79931,20.99713 0.821166,5.0208 0.932545,12.81085 0.270093,18.88365 -0.918217,8.41759 -2.532168,15.50528 -5.820123,25.55908 -0.09846,0.30118 -0.552278,1.57749 -1.007947,2.83485 -2.802152,7.73176 -6.254536,15.50934 -10.063841,22.67197 -1.79423,3.37363 -1.924144,3.61169 -2.8340259,5.19291 -0.9776602,1.69906 -1.2224978,2.05672 -1.3253942,1.93674 z M 5.008665,217.89727 c 0.3415767,-0.92617 1.6478277,-5.08296 1.9661514,-6.257 1.1882829,-4.38255 1.9504189,-8.29072 2.6215005,-13.44252 0.4503321,-3.45861 0.6587321,-10.03945 0.4197171,-13.25974 -3.4600192,-42.65593 -35.84298,-37.64311 -47.829045,-21.76897 -6.327052,8.37945 -9.74756,21.01953 5.824419,28.58118 3.310222,1.27781 7.738324,3.13281 10.915134,4.57279 9.211354,4.17531 14.5397666,7.43034 18.0404442,11.02053 4.287554,4.39719 6.7261938,7.95328 7.291123,10.63204 0.09953,0.47215 0.2266695,0.78872 0.3092527,0.77026 0.076722,-0.0197 0.2757234,-0.39821 0.4413177,-0.84737 z"
         id="path829" />
      <circle
         r="37.105614"
         cy="184.68994"
         cx="-8.43997"
         id="path34"
         style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:2.41614413;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
      <image
         width="73.477852"
         height="73.477852"
         preserveAspectRatio="none"
         xlink:href="${
  this.posto && this.posto.url_logo
    ? this.posto.url_logo
    : require('@/assets/logo.png')
}"
         id="image193"
         x="-44.557869"
         y="147.41447" />
    </g>
  </g>
    </svg>`,
      iconSize: [50, 45],
      iconAnchor: [30, 50]
    })
  },
  computed: {
    empresa () {
      return this.$store.getters.empresa
    }
  },
  methods: {
    emitError (error) {
      this.$emit('error', error)
      this.dialogPagCodigo = false
    },
    calcularOferta (combustivel) {
      return combustivel.preco
      // if (oferta.valor) {
      //  return (oferta.preco_combustivel.preco - oferta.preco_combustivel.preco * oferta.valor / 100).toFixed(2)
      // } else {
      //  return oferta.preco_combustivel.preco - oferta.desconto
      // }
    },
    pegarItens () {
      if (!this.empresa) return
      if (!this.posto) return
      const data = {
        empresa: this.empresa.cnpj,
        categoria__referencia: 'combustivel',
        posto__cnpj: this.posto.cnpj
      }

      this.$store.dispatch('pegarItens', data).then(json => {
        this.combustiveis = json
      })
    }
  }
}
</script>

<style>
.apetrus-popup .leaflet-popup-content-wrapper {
  padding: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.apetrus-popup .leaflet-popup-content {
  margin: 0 !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.apetrus-popup .v-card {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.apetrus-popup .leaflet-popup-close-button {
  top: 10px !important;
  right: 10px !important;
  color: white !important;
  font-size: 20px !important;
}

</style>
