import settings from '@/settings'

const inserir = (data, headers) => {
  return fetch(`${settings.apetrusApi}/meios-de-pagamento/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const listar = (headers, cnpj) => {
  return fetch(`${settings.apetrusApi}/meios-de-pagamento/?empresa=${cnpj}`, {
    method: 'GET',
    mode: 'cors',
    headers: headers
  })
}

const deletar = (id, headers) => {
  return fetch(`${settings.apetrusApi}/meios-de-pagamento/${id}/`, {
    method: 'DELETE',
    mode: 'cors',
    headers: headers
  })
}

const listarSecundarios = (headers, cnpj) => {
  return fetch(
    `${settings.apetrusApi}/meios-de-pagamento/meios_recebimentos_secundarios/?empresa=${cnpj}`,
    {
      method: 'GET',
      mode: 'cors',
      headers: headers
    }
  )
}

const vincularPagamento = (headers, data) => {
  return fetch(
    `${settings.apetrusApi}/meios-de-pagamento/${data.pagamento}/vincular-posto/`,
    {
      method: 'POST',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(data.body)
    }
  )
}

const desvincularPagamento = (headers, data) => {
  return fetch(
    `${settings.apetrusApi}/meios-de-pagamento/${data.id_pagamento}/desvincular-posto/`,
    {
      method: 'PUT',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(data)
    }
  )
}

const ativarPagamento = (headers, data) => {
  return fetch(
    `${settings.apetrusApi}/meios-de-pagamento/${data.id_meio_pagamento}/tornar-principal-tipo-pagamento/?deletar=${data.deletar}`,
    {
      method: 'PUT',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(data)
    }
  )
}

const habilitarPagamento = (headers, data) => {
  return fetch(
    `${settings.apetrusApi}/formaspagamento/habilitarformapagamento/?id_forma_pagamento=${data.id_forma_pagamento}`,
    {
      method: 'PATCH',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(data)
    }
  )
}

const atualizar = (headers, data) => {
  return fetch(
    `${settings.apetrusApi}/meios-de-pagamento/${data.id}/atualizar/`,
    {
      method: 'PUT',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(data)
    }
  )
}

export default {
  inserir,
  listar,
  deletar,
  listarSecundarios,
  vincularPagamento,
  desvincularPagamento,
  ativarPagamento,
  habilitarPagamento,
  atualizar
}
