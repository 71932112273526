<template>
  <v-card>
    <v-card-title class="justify-center">
      Editar Proposta
    </v-card-title>
    <v-alert v-if="proposta.veiculo.valor" text type="info" class="mt-4 mb-1 pa-2">
        <h4>
          Valor estimado que o usuário espera receber pelo veículo: {{proposta.veiculo.valor | currency({ fractionCount: 2 })}}
        </h4>
      </v-alert>
    <v-card-text>
      <v-layout wrap justify-space-between>
        <v-flex xs5>
          <v-switch
              v-model="tem_valor_oferecido"
              label="Deseja estimar um valor para esse veículo ?"
              color="primary"
          ></v-switch>
        </v-flex>
        <v-flex xs5>
          <v-btn class="mt-4" small rounded color="primary" @click="troca = !troca">
            <v-icon class="mr-1">
              {{ troca ? 'close' :'airport_shuttle'}}
            </v-icon>
            {{ troca ? 'Não Oferecer troca' : 'Oferecer troca' }}
          </v-btn>
        </v-flex>
        <v-slide-x-transition>
          <v-flex xs12 v-if="tem_valor_oferecido">
            <v-text-field
              filled
              label="Valor estimado oferecido"
              v-model.lazy="valor_oferecido"
              v-money="money"
              prefix="R$"
              class="mb-1"
              hide-details
            ></v-text-field>
            <h6 class="text-center error--text mb-4" style="font-size: 0.8em;">
              * Esse valor estará sujeito a alteração após o veículo ser avaliado presencialmente
            </h6>
          </v-flex>
        </v-slide-x-transition>
        <v-layout justify-center v-if="troca" style="width:100;">
          <v-card class="mb-4" elevation="4" style="width: 90%;">
            <v-card-title class="justify-center">
              Opções de troca
            </v-card-title>
            <v-card-text>
              <h3>Selecione um veículo para trocar</h3>
              <v-list style="width: 100%" class="mb-0">
                <v-list-item v-for="(v, index) in veiculos" :key="index">
                  <v-list-item-icon>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" :color="formatarCor(v.cor)">{{getIcon(v.tipo_veiculo)}}</v-icon>
                      </template>
                      Cor {{v.cor_texto}}
                    </v-tooltip>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{v.modelo_marca}}</v-list-item-title>
                    <v-list-item-subtitle>Ano: {{v.ano_fabricacao}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-tooltip bottom v-if="veiculoSelecionado && veiculoSelecionado.id_veiculo === v.id_veiculo">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="veiculoSelecionado = undefined, carregarVeiculos(false)"
                        >
                          <v-icon>close</v-icon>
                        </v-btn>
                      </template>
                      <span>Remover Veículo</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          color="success"
                          @click="veiculoSelecionado = v, veiculos=[v]"
                        >
                          <v-icon>check</v-icon>
                        </v-btn>
                      </template>
                      <span>Adicionar veículo para troca</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <div class="text-xs-center mb-4 mt-0" style="width: 100%" v-if="!veiculoSelecionado && pages && pages > 1">
                <v-pagination class="mt-0" v-model="pagination.page" :length="pages" circle></v-pagination>
              </div>
              <v-layout wrap justify-space-between class="pa-4" v-show="veiculoSelecionado">
                <v-flex xs12>
                  <v-switch slot="activator" class="ml-4" v-model="oferecer_retorno" color="info">
                    <template v-slot:label>
                      Oferecer um valor estimado de retorno na troca
                    </template>
                  </v-switch>
                  <template v-if="oferecer_retorno">
                    <v-text-field
                      outlined
                      label="Valor estimado oferecido para o cliente"
                      v-model.lazy="valor_oferecido_troca"
                      v-money="money"
                      prefix="R$"
                      class="mb-1"
                      hide-details
                    ></v-text-field>
                    <h6 class="text-center error--text mb-4" style="font-size: 0.8em;">
                      * Esse valor estará sujeito a alteração após o veículo ser avaliado presencialmente
                    </h6>
                  </template>
                </v-flex>
                <v-flex xs12>
                  <v-switch slot="activator" class="ml-4" v-model="pedir_retorno" color="info">
                    <template v-slot:label>
                      Solicitar um valor estimado de retorno na troca
                    </template>
                  </v-switch>
                  <template v-if="pedir_retorno">
                    <v-text-field
                      outlined
                      label="Valor estimado solicitado por você ao cliente"
                      v-model.lazy="valor_retorno"
                      v-money="money"
                      prefix="R$"
                      class="mb-1"
                      hide-details
                    ></v-text-field>
                    <h6 class="text-center error--text mb-4" style="font-size: 0.8em;">
                      * Esse valor estará sujeito a alteração após o veículo ser avaliado presencialmente
                    </h6>
                  </template>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-layout>
        <v-flex xs12>
          <v-textarea label="Descrição" v-model="descricao" outlined></v-textarea>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-layout column>
        <v-btn class="mb-2" rounded block  @click="confirmar" color="success">
          <v-icon class="mr-1">edit</v-icon>
          Editar Proposta
        </v-btn>
        <v-btn class="mb-2" rounded block  @click="$emit('fechar')">Fechar</v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import { VMoney } from 'v-money'
export default {
  directives: { money: VMoney },
  props: ['proposta'],
  data: () => ({
    pedir_retorno: false,
    oferecer_retorno: false,
    veiculos: [],
    veiculoSelecionado: undefined,
    tem_valor_oferecido: false,
    valor_oferecido: undefined,
    valor_oferecido_troca: undefined,
    valor_retorno: undefined,
    descricao: undefined,
    troca: false,
    proposta_data: {
      solicitacao: { veiculo: {} },
      veiculo: {}
    },
    money: {
      decimal: ',',
      thousands: '.',
      precision: 2,
      masked: false
    },
    pagination: {
      page: 1,
      rowsPerPage: 5
    },
    totalItens: 0
  }),
  computed: {
    empresa () {
      return this.$store.getters.empresa
    },
    pages () {
      if (this.pagination.rowsPerPage == null || this.totalItens == null) {
        return 0
      }
      return Math.ceil(this.totalItens / this.pagination.rowsPerPage)
    }
  },
  watch: {
    pagination: {
      handler (val, oldval) {
        this.carregarVeiculos(false)
      },
      deep: true
    },
    oferecer_retorno (val) {
      if (val) {
        this.pedir_retorno = false
        this.valor_retorno = undefined
      }
    },
    pedir_retorno (val) {
      if (val) {
        this.oferecer_retorno = false
        this.valor_oferecido_troca = undefined
      }
    },
    troca (val) {
      if (!val) {
        this.oferecer_retorno = false
        this.pedir_retorno = false
        this.valor_retorno = undefined
        this.valor_oferecido_troca = undefined
        this.veiculoSelecionado = undefined
        this.carregarVeiculos(false)
      }
    },
    tem_valor_oferecido (val) {
      if (!val) {
        this.valor_oferecido = undefined
      }
    }
  },
  beforeMount () {
    this.proposta_data = { ...this.proposta }
    this.tem_valor_oferecido = !!this.proposta_data.solicitacao.valor_oferecido
    this.troca = !!this.proposta.solicitacao.veiculo
    this.oferecer_retorno = !!this.proposta_data.solicitacao.valor_oferecido_troca
    this.pedir_retorno = !!this.proposta_data.solicitacao.valor_retorno
    this.valor_oferecido = this.proposta_data.solicitacao.valor_oferecido
    this.valor_oferecido_troca = this.proposta_data.solicitacao.valor_oferecido_troca
    this.valor_retorno = this.proposta_data.solicitacao.valor_retorno
    this.descricao = this.proposta_data.solicitacao.descricao
    this.veiculoSelecionado = this.troca ? this.proposta.solicitacao.veiculo : undefined
    this.veiculos = this.troca ? [this.proposta.solicitacao.veiculo] : undefined
    if (!this.veiculoSelecionado) this.carregarVeiculos(false)
  },
  methods: {
    confirmar () {
      this.$store.dispatch('editarPropostaVeiculo', {
        data: {
          veiculo_id: this.veiculoSelecionado ? this.veiculoSelecionado.id_veiculo : null,
          valor_oferecido: !this.tem_valor_oferecido || !this.valor_oferecido ? null : Number(this.valor_oferecido.replaceAll('.', '').replace(',', '.')),
          valor_retorno: !this.pedir_retorno || !this.valor_retorno ? null : Number(this.valor_retorno.replaceAll('.', '').replace(',', '.')),
          valor_oferecido_troca: !this.oferecer_retorno || !this.valor_oferecido_troca ? null : Number(this.valor_oferecido_troca.replaceAll('.', '').replace(',', '.')),
          descricao: this.descricao ? this.descricao.toString().trim() : null
        },
        id: this.proposta.solicitacao.id
      }).then(() => {
        this.$emit('carregar')
        this.$emit('fechar')
      })
    },
    formatarCor (cor) {
      if (cor === null) return ''
      if (cor === undefined) return ''
      if (cor.length === 6) return `#${cor}`
      if (cor.length === 7) return cor
      if (cor.length === 9) return cor.slice(0, 7)
      else {
        let teste = parseInt(cor)
        teste = teste.toString(16)
        return `#${teste.slice(2, 8)}`
      }
    },
    carregarVeiculos (isBtn) {
      const data = {
        empresa: this.empresa.cnpj
      }
      data.page = isBtn ? 1 : this.pagination.page
      data.page_size = this.pagination.rowsPerPage
      if (this.tipo_veiculo) data.tipo_veiculo = this.tipo_veiculo
      if (this.modelo) data.modelo__icontains = this.modelo
      if (this.marca) data.marca__icontains = this.marca
      if (this.cor_texto) data.cor_texto__icontains = this.cor_texto
      this.$store.dispatch('listarVeiculosAVenda', data).then(json => {
        this.veiculos = json.results
        this.totalItens = json.count
      })
    },
    getIcon (tipo) {
      if (tipo && typeof tipo === 'object') tipo = tipo.denominacao
      if (tipo === 'Passageiro' || tipo === 'Automóvel' || tipo === 'Outros') return 'airport_shuttle'
      else if (tipo === 'Caminhão') return 'local_shipping'
      else if (tipo === 'Caminhão trator') return 'agriculture'
      else if (tipo === 'Caminhonete') return 'agriculture'
      else if (tipo === 'Ciclomotor' || tipo === 'Motoneta' || tipo === 'Motocicleta' || tipo === 'Triciclo') return 'two_wheeler'
      else if (tipo === 'Micro-ônibus' || tipo === 'Ônibus') return 'directions_bus'
      return 'airport_shuttle'
    }
  }
}
</script>
