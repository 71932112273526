import settings from '@/settings'

const getPropostaByCliente = (headers, payload) => {
  let url = `${settings.apetrusApi}/proposta/getpropostabyclient/?`
  if (payload) {
    const keys = Object.keys(payload)
    for (let i = 0; i < keys.length; i++) {
      url = `${url}${payload[keys[i]] ? `&${keys[i]}=${payload[keys[i]]}` : ''}`
    }
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const detalharCompra = (id, headers) => {
  return fetch(`${settings.apetrusApi}/compras/?id=${id}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const recomprar = (data, headers) => {
  return fetch(`${settings.apetrusApi}/compras/recomprar/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const realizarCompra = (data, headers) => {
  return fetch(`${settings.apetrusApi}/compras/compraproposta/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const finalizarCompra = (data, headers) => {
  return fetch(data.url, {
    method: 'POST',
    body: JSON.stringify({
      id_compra: data.id_compra,
      id_cartao: data.id_cartao,
      cartao: data.cartao,
      user_agent: data.user_agent
    }),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const pagar = (data, headers) => {
  return fetch(data.url, {
    method: 'POST',
    body: JSON.stringify({
      id_compra: data.id_compra,
      id_cartao: data.id_cartao,
      cartao: data.cartao,
      cvv: data.cvv,
      user_agent: data.user_agent
    }),
    mode: 'no-cors',
    cache: 'default',
    headers: headers
  })
}

const iniciarVerificacao = (id, hash, cartao, headers) => {
  return fetch(`${settings.apetrusApi}/cartoes/?id=${id}`, {
    method: 'PUT',
    body: JSON.stringify({
      cartao: hash,
      option: 'start',
      cvv: cartao.cvv
    }),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const pegarComprasPostos = (headers, filtros) => {
  let url = `${settings.apetrusApi}/compras/compras-automacao/`
  if (filtros) {
    Object.keys(filtros).forEach((filter) => {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${filter}=${filtros[filter]}`
    })
  }
  return fetch(url, {
    method: 'GET',
    cache: 'default',
    mode: 'cors',
    headers: headers
  })
}

const pegarComprasEmpresa = (headers, filtros) => {
  let url = `${settings.apetrusApi}/compras/por-empresa/`
  if (filtros) {
    Object.keys(filtros).forEach((filter) => {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${filter}=${filtros[filter]}`
    })
  }
  return fetch(url, {
    method: 'GET',
    cache: 'default',
    mode: 'cors',
    headers: headers
  })
}

export default {
  getPropostaByCliente,
  recomprar,
  detalharCompra,
  realizarCompra,
  finalizarCompra,
  pagar,
  iniciarVerificacao,
  pegarComprasPostos,
  pegarComprasEmpresa
}
