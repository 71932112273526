<template>
  <v-card>
    <v-card-title class="justify-center">
        <h3>EXCLUIR VEÍCULO</h3>
    </v-card-title>
    <v-alert v-if="error" type="error" class="mt-4 ml-1 mr-1 mb-1 pa-1">
      <h4>{{error}}</h4>
    </v-alert>
    <v-card-text>
      <v-row v-if="veiculo">
        <v-col cols="12" sm="6" md="12">
          <h3 class="text-xs-center red--text my-3">ATENÇÃO! Ao confirmar esta operação o veículo não estará mais disponível na sua frota</h3>
          <p v-if="veiculo.usuario" class="text-xs-center red--text my-3">Este veículo pertence ao funcionário: {{veiculo.usuario.pessoa.nome}} {{veiculo.usuario.pessoa.sobrenome}}</p>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-space-around">
      <v-flex xs6>
        <v-btn text block @click="fechar">fechar</v-btn>
      </v-flex>
      <v-flex xs6>
        <v-btn text block color="red" dark @click="apagarVeiculo()">Confirmar</v-btn>
      </v-flex>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  props: ['veiculo'],
  data () {
    return {
      mostra: true,
      error: null,
      cor: ''
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    }
  },
  methods: {
    fechar () {
      this.error = null
      this.$emit('carregar')
      this.$emit('fechar')
    },
    apagarVeiculo () {
      this.$store.dispatch('apagarVeiculo', this.veiculo.id_veiculo)
      this.$emit('carregar')
      this.fechar()
    }
  }
}
</script>
