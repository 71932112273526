
const pay = (headers, data) => {
  return fetch(data.url, {
    method: data.method,
    body: JSON.stringify(data.body),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const verifyPayment = (headers, data) => {
  let url = data.url
  if (data.body) {
    const keys = Object.keys(data.body)
    for (let i = 0; i < keys.length; i++) {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${keys[i]}=${data.body[keys[i]]}`
    }
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

export default {
  pay,
  verifyPayment
}
