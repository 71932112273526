import settings from '@/settings'

const get = (cnpj, headers) => {
  return fetch(`${settings.apetrusApi}/notificacoes/empresa/?cnpj_empresa=${cnpj}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const put = (data, headers) => {
  return fetch(`${settings.apetrusApi}/notificacoes/empresa/`, {
    method: 'PUT',
    mode: 'cors',
    cache: 'default',
    body: JSON.stringify(data),
    headers: headers
  })
}

const push = (headers, data) => {
  return fetch(`${settings.apetrusApi}/notificacoes/push/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

export default {
  get,
  put,
  push
}
