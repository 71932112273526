import settings from '@/settings'

const pegarMeiosDeRecebimento = (headers) => {
  /*
    * method GET pegar os meios de recebimentos da apetrus
  */
  const request = {
    mode: 'cors',
    headers: headers,
    method: 'GET',
    cache: 'default'
  }
  return fetch(`${settings.apetrusApi}/administradores/meioderecebimento/`, request)
}

export default {
  pegarMeiosDeRecebimento
}
