<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || empresa.tipo_empresa !== 1)"/>
  <v-layout align-space-around justify-start fill-height column class="pt-5" v-else>
    <v-toolbar
      color="transparent"
      class="elevation-0 secondary--text"
      style="background: transparent"
    >
      <v-toolbar-title>Negociações aceitas</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar color="transparent" class="elevation-0 secondary--text">
        <v-spacer></v-spacer>
        <v-btn class="mr-4" small fab color="info"  @click="showFilter = !showFilter">
          <v-icon>{{ !showFilter ? 'filter_list' : 'filter_list_off' }}</v-icon>
        </v-btn>
      </v-toolbar>
    </v-toolbar>
    <v-slide-x-transition>
      <v-card v-show="showFilter" class="ml-4 mr-4 mb-4">
        <v-card-text>
          <v-layout wrap justify-space-between>
            <v-flex xs10 md11>
              <v-layout wrap justify-space-around>
                <v-flex xs11 sm5 md3 lg2 xl2>
                  <v-text-field label="Identificação" v-model="filtros.id" type="number"></v-text-field>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-text-field label="Data de abertura" v-model="filtros.data_abertura" type="date"></v-text-field>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-select
                    v-model="filtros.status"
                    :items="[{value:'true', text:'Aceita'},{value:'false', text:'Negada'}]"
                    label="Estado da proposta"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-select
                    v-model="filtros.modelo_faturamento"
                    :items="[{value:'prepago', text:'Pré-pago'}, {value:'pospago', text:'Pós-pago'}, {value:'presencial', text:'Presencial'}]"
                    item-text="text"
                    item-value="value"
                    label="Modelo de faturamento"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2 md1 align-self-center>
              <v-btn small fab color="info" @click="carregarCompras($event)">
                <v-icon>search</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-slide-x-transition>
    <v-data-table
      hide-default-footer
      :headers="comprasHeaders"
      :items="propostas"
      hide-footer
      no-data-text="Você ainda não possui compras"
      class="elevation-1"
    >
      <template v-slot:item="props">
        <tr>
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" v-if="props.item.posto || props.item.rede">{{ props.item.posto ? 'local_gas_station' : 'ac_unit' }}</v-icon>
                <img
                  v-else
                  style="width: 30px; margin-left: -10px"
                  :src="require(`@/assets/apetrus.png`)"
                  class="mr-2"
                />
              </template>
              <span>{{ props.item.posto ? 'Posto' : 'Rede' }}</span>
            </v-tooltip>
          </td>
          <td class="text-xs-left">{{props.item.identificacao}}</td>
          <td>{{ getDestinatario(props.item.posto || props.item.rede) }}</td>
          <td>{{ valorProposta(props.item) | currency({fractionCount: 2}) }}</td>
          <td class="green--text" v-if="props.item.modelo_faturamento === 'prepago'"> + {{ props.item.cashback | currency({fractionCount: 2}) }}</td>
          <td v-else>Não contém</td>
          <td>{{ (props.item.valor  || props.item.credito_apetrus) | currency({fractionCount: 2}) }}</td>
          <td>{{ props.item.valor_usado | currency({fractionCount: 2})   }}</td>
          <td class="text-left">
            <v-btn
              :disabled="props.item.modelo_faturamento === 'presencial'"
              text
              icon
              color="primary"
              @click="getPrecoCombustivel(props.item)"
            >
              <v-icon>receipt</v-icon>
            </v-btn>
          </td>
          <td class="text-xs-left">
            <v-tooltip bottom v-if="props.item.status == null">
              <template v-slot:activator="{ on }">
                <v-icon color="primary" dark v-on="on">query_builder</v-icon>
              </template>
              <span>Aguardando Resposta.</span>
            </v-tooltip>
            <v-tooltip bottom v-else-if="props.item.status == true">
              <template v-slot:activator="{ on }">
                <v-icon color="teal" dark v-on="on"
                  >check_circle_outline</v-icon
                >
              </template>
              <span>Pagamento Aceito.</span>
            </v-tooltip>
            <v-tooltip bottom v-else-if="props.item.status == false">
              <template v-slot:activator="{ on }">
                <v-icon color="primary" dark v-on="on">query_builder</v-icon>
              </template>
              <span>Aguardando Resposta.</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-xs-center">
      <v-pagination
        v-model="pagination.page"
        :length="pages"
        circle
      ></v-pagination>
    </div>
    <dialog-pagamento
      :negociacao="negociacao_pagamento"
      :visible="visible2"
      v-if="negociacao_pagamento"
      v-on:fechar="negociacao_pagamento = null"
      v-on:error="showError"
    ></dialog-pagamento>
    <dialog-voucher
      v-if="visible"
      :negociacao="negociacao"
      :visible="visible"
      @carregar="carregarCompras"
      v-on:fechar="negociacao = null"
      v-on:error="showError"
    ></dialog-voucher>
    <v-snackbar
      v-model="snackbar.active"
      :color="snackbar.color"
      :multi-line="snackbar.multiLine"
      :timeout="snackbar.timeout"
      :vertical="snackbar.vertical"
    >
      {{ snackbar.text }}
      <v-btn text @click="snackbar.active = false"> Fechar </v-btn>
    </v-snackbar>
  </v-layout>
</template>

<script>

// import detalhesCompra from '@/components/dialogs/DialogDetalhesCompra'
import DialogPagamento from '@/components/dialogs/DialogPagamento'
import DialogVoucher from '@/components/dialogs/DialogVoucher'
import { comprasDao } from '@/api'
import AvisoBloqueio from '@/components/AvisoBloqueio'

export default {
  components: { DialogPagamento, DialogVoucher, AvisoBloqueio },
  data () {
    return {
      showFilter: false,
      filtros: {},
      snackbar: {
        active: false,
        color: '',
        multiLine: true,
        timeout: 4000,
        vertical: true,
        text: ''
      },
      pagination: {
        page: 1,
        rowsPerPage: 5
      },
      paginationPropostas: {
        page: 1,
        rowsPerPage: 5
      },
      totalItems: 0,
      compra: null,
      negociacao: null,
      negociacao_pagamento: null,
      today: null,
      precoCombustivel: null,
      comprasHeaders: [
        {
          text: '',
          align: 'left',
          sortable: false
        },
        {
          text: 'Identificação',
          value: 'identificacao'
        },
        {
          text: 'Nome',
          align: 'left',
          sortable: false,
          value: 'Nome'
        },
        {
          text: 'Valor da proposta',
          value: 'valor',
          sortable: false,
          align: 'left'
        },
        {
          text: 'Cashback',
          value: 'valor',
          sortable: false,
          align: 'left'
        },
        {
          text: 'Valor total',
          value: 'valor',
          sortable: false,
          align: 'left'
        },
        {
          text: 'Valor usado',
          value: 'combustivel',
          sortable: false,
          align: 'left'
        },
        {
          text: 'Consumir saldo',
          value: 'gerarvoucher',
          sortable: false,
          align: 'left'
        },
        {
          text: 'Status',
          value: 'statuspagemento',
          sortable: false,
          align: 'left'
        },
        {
          align: 'right',
          sortable: false
        }
      ],
      propostas: []
    }
  },
  watch: {
    pagination: {
      handler (val, oldval) {
        this.carregarCompras()
      },
      deep: true
    },
    paginationPropostas: {
      handler (val, oldval) {
        this.carregarCompras()
        this.$store.dispatch('carregarTiposPagamento')
      },
      deep: true
    },
    headers (val, oldval) {
      if (val !== null && val !== undefined && val !== oldval) {
        this.carregarCompras()
      }
    },
    empresa (val, oldval) {
      if (val !== null && val !== undefined && val !== oldval) {
        this.carregarPropostas()
        this.carregarCompras()
      }
    }
  },
  computed: {
    pages () {
      const count = this.totalItems
      if (this.pagination.rowsPerPage == null || count == null) return 0

      return Math.ceil(count / this.pagination.rowsPerPage)
    },
    headers () {
      return this.$store.getters.headers
    },
    empresa () {
      return this.$store.getters.empresa
    },
    visible () {
      return this.negociacao !== null
    },
    visible2 () {
      return this.negociacao_pagamento !== null
    },
    ready () {
      return !!this.headers && !!this.empresa
    }
  },
  beforeMount () {
    if (this.ready) {
      this.carregarCompras()
      this.carregarPropostas()
      this.$store.dispatch('tornarCliente')
    }
  },
  mounted () {
    if (this.empresa) {
      this.carregarCompras()
      this.$store.dispatch('carregarTiposPagamento')
    }
    this.today = new Date().setHours(0, 0, 0, 0)
  },
  methods: {
    carregarPropostas (event) {
      if (event) {
        this.pagination.page = 1
      }

      var data = {
        pagination: this.pagination,
        filtros: {}
      }

      if (this.filtros.id) data.filtros.id = this.filtros.id
      if (this.filtros.is_apetrus) data.filtros.is_apetrus = this.filtros.is_apetrus
      if (this.filtros.data_abertura) data.filtros.data_abertura = this.filtros.data_abertura
      if (this.filtros.status) data.filtros.status = this.filtros.status
      if (this.filtros.modelo_faturamento) data.filtros.modelo_faturamento = this.filtros.modelo_faturamento

      this.$store.dispatch('buscarPropostas', data)
    },
    valorProposta (proposta) {
      if (proposta.modelo_faturamento === 'credito') return proposta.credito_apetrus
      else if (proposta.modelo_faturamento === 'presencial') return undefined
      return proposta.valor_inicial
    },
    carregarCompras (event) {
      if (this.headers && this.empresa) {
        if (event) {
          this.pagination.page = 1
        }

        var data = {
          page: this.pagination.page,
          cnpj: this.empresa.cnpj
        }

        if (this.filtros.id) data.id = this.filtros.id
        if (this.filtros.is_apetrus) data.is_apetrus = this.filtros.is_apetrus
        if (this.filtros.data_abertura) data.data_abertura = this.filtros.data_abertura
        if (this.filtros.status) data.status = this.filtros.status
        if (this.filtros.modelo_faturamento) data.modelo_faturamento = this.filtros.modelo_faturamento

        comprasDao.getPropostaByCliente(this.headers, data).then((response) => {
          return response.json()
        }).then((result) => {
          this.totalItems = result.count
          this.propostas = result.results
        })
      }
    },
    getPrecoCombustivel (proposta) {
      var combustivelNegociado = {
        id: proposta.id,
        qtd_litros: proposta.qtd_litros,
        posto: proposta.posto,
        rede: proposta.rede,
        desconto: proposta.desconto,
        data_confirmacao: proposta.data_confirmacao,
        data_expiracao: proposta.data_expiracao,
        status_pagamento: proposta.status_pagamento,
        valor: proposta.valor,
        valor_usado: proposta.valor_usado,
        is_apetrus: proposta.is_apetrus,
        credito_apetrus: proposta.credito_apetrus,
        modelo_faturamento: proposta.modelo_faturamento,
        combustivel: proposta.combustivel,
        item: proposta.item
      }
      this.negociacao = combustivelNegociado
    },
    getCompra (proposta) {
      var combustivelNegociado = {
        id: proposta.id,
        qtd_litros: proposta.qtd_litros,
        posto: proposta.posto,
        rede: proposta.rede,
        desconto: proposta.desconto,
        chave_pix: proposta.chave_pix,
        num_boleto: proposta.num_boleto,
        dados_bancarios: proposta.dados_bancarios,
        data_confirmacao: proposta.data_confirmacao,
        data_expiracao: proposta.data_expiracao,
        status_pagamento: proposta.status_pagamento,
        valor: proposta.valor,
        valor_usado: proposta.valor_usado,
        is_apetrus: proposta.is_apetrus,
        credito_apetrus: proposta.credito_apetrus,
        modelo_faturamento: proposta.modelo_faturamento,
        combustivel: proposta.combustivel,
        item: proposta.combustivel
      }
      this.negociacao_pagamento = combustivelNegociado
      // })
    },
    showError (error) {
      this.snackbar.color = 'error'
      this.snackbar.text = error
      this.snackbar.active = true
      this.carregarCompras()
    },
    showInfo (info) {
      this.snackbar.color = 'info'
      this.snackbar.text = info
      this.snackbar.active = true
      this.carregarCompras()
    },
    getDestinatario  (destinatario) {
      if (!destinatario) return 'Apetrus'
      else if (destinatario.nome) return `Rede ${destinatario.nome}`
      else if (destinatario.nome_fantasia) return `Posto ${destinatario.nome_fantasia}`
      return undefined
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
