<template>
  <v-card>
    <v-card-title v-if="step != 1">
      {{step == 2 ? 'Envie uma proposta para Apetrus' : 'Insira o valor e o comprovante de pagamento'}}
    </v-card-title>
    <v-card-text>
      <v-stepper v-model="step" style="height: 100%; box-shadow: none;">
        <v-stepper-items style="height: 100%;">
          <v-stepper-content step="1" style="height: 100%;">
            <v-layout justify-center class="mt-0 pa-0">
              <img
                style="width: 70%;"
                :src="require(`@/assets/logomarca2.png`)"
                class="mr-2"
              />
            </v-layout>
            <h1 style="text-align: center; color: rgb(8, 77, 110);">Propostas</h1>
            <v-card-text style="font-size: 1.2em; text-align: justify;">
              Com o <b>Apetrus Proposta</b> você pode fazer uma proposta diretamente
              para Apetrus e assim desfrutar de descontos em vários postos,
              além disso, você contará com uma maior simplicidade e rapidez
              em suas negociações, pois a Apetrus negociará com os postos por você.
            </v-card-text>
          </v-stepper-content>
          <v-stepper-content step="2" style="height: 100%;">
            <v-card-text>
              <v-select
                label="Selecione a forma de pagamento"
                outlined
                v-model="tipo"
                item-value="id"
                item-text="text"
                :items="[
                  { id: 1, text: 'Crédito Apetrus (Carteira)' },
                  { id: 2, text: 'Pagamento no posto (Presencial)' },
                ]"/>
              <ul style="font-size: 1.2em; text-align: justify;">
                <template>
                  <li class="mb-4" v-if="tipo == 1">Você realizará o pagamento através da sua Carteira Apetrus no termino de um abastecimento.</li>
                  <li class="mb-4" v-else-if="tipo == 2">Você pagará no estabelecimento (Posto) ao realizar abastecimeto.</li>
                  <li class="mb-4" v-else>Você comprará um crédito junto a Apetrus.</li>
                  <li class="mb-4">Você poderá abastecer a vontade sem nenhum limite.</li>
                  <li class="mb-4">Você poderá abastecer em qualquer posto ou rede que a Apetrus negociou.</li>
                  <li class="mb-4">Elimina a necessidade de negociar com o posto ou rede de postos.</li>
                </template>
              </ul>
            </v-card-text>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn @click="next" color="rgb(8, 77, 110)" class="white--text mb-4">{{step == 1 ? 'Participar' :  'Enviar'}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { VMoney } from 'v-money'
export default {
  directives: { money: VMoney },
  data: () => ({
    step: 1,
    tipo: 1,
    valor: '0,00',
    meiosDeRecebimento: [],
    arquivoAnexado: undefined,
    imagemAnexada: undefined,
    url_arquivo: null,
    money: {
      decimal: ',',
      thousands: '.',
      precision: 2,
      masked: false
    }
  }),
  computed: {
    empresa () {
      return this.$store.getters.empresa
    }
  },
  methods: {
    next () {
      switch (this.step) {
        case 1:
          this.step++
          break
        case 2:
          if (!this.tipo) {
            this.$store.dispatch('setError', 'Tipo de proposta não especificado.')
            return
          }
          this.cadastrarPropostaApetrus()
      }
    },
    cadastrarPropostaApetrus () {
      this.$store.dispatch('cadastrarPropostaApetrus', {
        cnpj: this.empresa.cnpj,
        tipo: this.tipo,
        valor: Number(this.valor.replaceAll('.', '').replace(',', '.'))
      }).then(() => {
        this.$emit('carregar')
        this.$emit('fechar')
      })
    }
  }
}
</script>
