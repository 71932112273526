import settings from '@/settings'

// Pega postos ativos
const pegarPostos = (headers, data) => {
  return fetch(`${settings.apetrusApi}/postos/postosativos/${data && data.pagination ? `?page=${data.pagination.page}&page_size=${data.pagination.rowsPerPage}` : `?notPaged=${true}`}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const filtroDePostos = (headers, filtros) => {
  let url = `${settings.apetrusApi}/postos/postosativos/?`
  if (filtros) {
    const keys = Object.keys(filtros)
    for (let i = 0; i < keys.length; i++) {
      url = `${url}${url[url.length - 1] === '?' ? '' : '&'}${keys[i]}=${filtros[keys[i]]}`
    }
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

// Pega um posto
const pegarPosto = (headers, cnpj) => {
  return fetch(`${settings.apetrusApi}/postos/${cnpj}/`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const pegarPostosMapa = (headers) => {
  return fetch(`${settings.apetrusApi}/postos/comofertas/`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

// [Atenção] Trocar endpoint para o endpoint que retorna os voucher da empresa que você fez no backend
// se você ainda não o fez recomendo que esse endpoint  esteja como empresas/relatorio ou algo semelhante
const relatorioCompras = (filtros, headers) => {
  const keys = Object.keys(filtros)
  let url = `${settings.apetrusApi}/postos/compras/`
  for (let i = 0; i < keys.length; i++) {
    url = `${url}&${keys[i]}=${filtros[keys[i]]}`
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const relatorioComprasPosto = (filtros, cnpj, headers) => {
  const keys = Object.keys(filtros)
  let url = `${settings.apetrusApi}/postos/comprasnoposto/?posto=${cnpj}`
  for (let i = 0; i < keys.length; i++) {
    url = `${url}&${keys[i]}=${filtros[keys[i]]}`
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const relatorioVouchers = (filtros, cnpj, headers) => {
  const keys = Object.keys(filtros)
  let url = `${settings.apetrusApi}/postos/vouchers/?posto=${cnpj}`
  for (let i = 0; i < keys.length; i++) {
    url = `${url}&${keys[i]}=${filtros[keys[i]]}`
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const pegarMeioRecebimento = (headers, cnpj) => {
  return fetch(`${settings.apetrusApi}/postos/meiorecebimento/${cnpj}/`, {
    method: 'GET',
    mode: 'cors',
    headers: headers
  })
}

const pegarEndereco = (headers, cnpj) => {
  return fetch(`${settings.apetrusApi}/enderecos/posto/?cnpj=${cnpj}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const comProposta = (headers) => {
  return fetch(`${settings.apetrusApi}/proposta/postoscomproposta/`, {
    method: 'GET',
    mode: 'cors',
    headers: headers
  })
}

const numeroIntegrados = (headers, cnpj) => {
  return fetch(`${settings.apetrusApi}/postos/numeros-integrados/?empresa=${cnpj}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

export default {
  pegarPostos,
  filtroDePostos,
  pegarEndereco,
  pegarPosto,
  pegarPostosMapa,
  relatorioComprasPosto,
  relatorioVouchers,
  relatorioCompras,
  pegarMeioRecebimento,
  comProposta,
  numeroIntegrados
}
