import settings from '@/settings'

const cadastrarUsuarioFrentista = (headers, data) => {
  return fetch(`${settings.apetrusApi}/frentistas/cadastrarusuario/${data.id_frentista}/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const inserirFrentista = (headers, data) => {
  return fetch(`${settings.apetrusApi}/frentistas/cadastrar/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const inativarFrentista = (headers, data) => {
  return fetch(`${settings.apetrusApi}/frentistas/inativarfrentista/?id=${data.id}`, {
    method: 'PUT',
    mode: 'cors',
    body: JSON.stringify(data),
    headers: headers
  })
}

const get = (headers, filtros) => {
  let url = `${settings.apetrusApi}/frentistas/`
  if (filtros) {
    Object.keys(filtros).forEach((filter) => {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${filter}=${filtros[filter]}`
    })
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const put = (headers, data) => {
  return fetch(`${settings.apetrusApi}/frentistas/editar/`, {
    method: 'PUT',
    mode: 'cors',
    body: JSON.stringify(data),
    headers: headers
  })
}

export default {
  cadastrarUsuarioFrentista,
  inserirFrentista,
  inativarFrentista,
  get,
  put
}
