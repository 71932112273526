<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card>
      <v-card-text
        class="pt-4"
        style="position: relative;"
      >
    <v-stepper v-model="step" style="box-shadow: none;">
      <v-stepper-items>
        <v-stepper-content step="1">
        <div class="pl-3">
          <h3 class="display-1 font-weight-light orange--text mb-2">Editar Empresa</h3>
          <div class="font-weight-light title mb-2">
          </div>
        </div>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex
              xs12
              sm6
              md4
            >
              <v-text-field
                label="Nome fantásia"
                v-model="empresa.nome_fantasia"
                required
              ></v-text-field>
            </v-flex>
            <v-flex
              xs12
              sm8
            >
              <v-text-field
                :rules="razaoRules"
                label="Razão Social"
                v-model="empresa.razao_social"
                hint="Insira o nome oficial do CNPJ"
              ></v-text-field>
            </v-flex>
            <v-flex
              xs12
              sm6
            >
              <v-text-field
                v-model="empresa.cnpj"
                label="Cnpj"
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                @keyup="searchCep()"
                v-model="empresa.endereco.cep"
                maxlength="8"
                label="Cep"
                required
                hint="Digite apenas os número"
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="empresa.endereco.logradouro"
                label="Logradouro"
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-text-field
                v-model="empresa.endereco.numero"
                label="Nº"
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-text-field
                v-model="empresa.endereco.complemento"
                label="Complemento"
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="empresa.endereco.bairro"
                label="Bairro"
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-text-field
                v-model="empresa.endereco.cidade"
                label="Cidade"
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-text-field
                v-model="empresa.endereco.estado"
                label="UF"
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                  label="Número de WhatsApp"
                  v-model="empresa.contato1"
                  placeholder="(12) 9 3456-7891"
                  v-mask="'(##) # ####-####'"
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                  label="Número de telefone (Fixo)"
                  v-model="empresa.contato2"
                  placeholder="(12) 3456-7891"
                  v-mask="'(##) ####-####'"
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                  label="Número de celular"
                  v-model="empresa.contato3"
                  placeholder="(12) 9 3456-7891"
                  v-mask="'(##) # ####-####'"
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                  label="Email da empresa"
                  v-model="empresa.email"
                  placeholder="empresa@apetrus.com.br"
              ></v-text-field>
            </v-flex>
            <template v-if="empresa.tipo_empresa === 4 || empresa.tipo_empresa === 5 || empresa.tipo_empresa === 6">
              <v-flex xs12 align-self-center>
                <v-select
                  v-model="empresa.categoria"
                  :items="categorias"
                  label="Categoria de Atuação"
                />
              </v-flex>
              <v-flex xs12 align-self-center>
                <v-select
                  v-model="empresa.subcategorias"
                  :items="subcategorias.filter(sc => {
                    const categoriaCorrespondente = sc.categoria === empresa.categoria || empresa.categoria === 9
                    const servicoCorrespondente = (empresa.tipo_empresa === 5 && sc.servico || empresa.tipo_empresa === 4 && !sc.servico) || (empresa.tipo_empresa === 6)
                    return categoriaCorrespondente && servicoCorrespondente
                  }
                  )"
                  label="Subcategoria de Atuação"
                  chips
                  multiple
                />
              </v-flex>
            </template>
          </v-layout>
        </v-container>
        </v-stepper-content>
        <v-stepper-content step="2">
          <div class="pl-3">
            <h3 class="font-weight-light orange--text mb-2" style="font-size: 2em; width: 100%; text-align: center;">Insira agora o logo da sua empresa.</h3>
          </div>
          <v-layout justify-center column full-width  style="height: 100%;">
            <v-flex align-self-center>
              <img v-if="imagem && mostrar" :src="imagem" alt="Logo da empresa" width="100%">
              <v-card color="grey" height="250" width="250" v-if="!imagem && !croppingLogo">
                  <v-layout justify-center align-center fill-height>
                      <div class="title white--text pa-4 text-xs-center">Sem imagem</div>
                  </v-layout>
              </v-card>
              <v-btn class="mt-3 mb-3" block color="primary" @click="$refs.logoInput.click()" v-if="mostrar">
                Inserir imagem
                <input
                    type="file"
                    style="display:none"
                    accept="image/*"
                    ref="logoInput"
                    @input="carregarImagem"
                />
              </v-btn>
            </v-flex>
            <v-flex xs12 v-show="croppingLogo">
              <vue-croppie
                ref="croppieRef"
                :enableExif="true"
                :enforceBoundary="true"
                :enableResize="false"
                :enableOrientation="false"
                :mouseWheelZoom="true"
                :showZoomer="false"
                :boundary="{ width: 250, height: 250 }"
                :viewport="{ width: 200, height: 200, type : 'circle' }"
              ></vue-croppie>
                <v-btn
                  text
                  @click="recortarImagem"
                  color="grey darken-2"
                  block
                  class="mt-3"
                >
                  Recortar imagem
                </v-btn>
            </v-flex>
          </v-layout>
        </v-stepper-content>
        <v-stepper-content step="3">
          <div class="pl-3">
            <h3 class="font-weight-light orange--text mb-2" style="font-size: 2em; width: 100%; text-align: center;">Insira agora a capa da sua empresa.</h3>
          </div>
          <v-layout justify-center align-center fill-height  style="height: 100%;">
            <v-flex align-self-center>
              <img v-if="capa && mostrar2" :src="capa" alt="Capa da empresa" width="100%">
              <v-card color="grey" height="250" width="450" v-if="!capa && !croppingCapa">
                <v-layout justify-center align-center fill-height>
                  <div class="title white--text pa-4 text-xs-center">Sem imagem</div>
                </v-layout>
              </v-card>
              <v-btn class="mt-3" block color="primary" @click="$refs.capaInput.click()" v-if="mostrar2">
                Inserir imagem
                <input
                    type="file"
                    style="display:none"
                    accept="image/*"
                    ref="capaInput"
                    @input="carregarImagem"
                />
              </v-btn>
            </v-flex>
            <v-flex xs12 v-show="croppingCapa">
                <vue-croppie
                ref="croppieCapaRef"
                :enableExif="true"
                :enforceBoundary="true"
                :enableResize="false"
                :enableOrientation="false"
                :mouseWheelZoom="true"
                :showZoomer="false"
                :boundary="{ width: 450, height: 250 }"
                :viewport="{ width: 350, height: 200}"
                ></vue-croppie>
                <v-btn
                text
                @click="recortarImagem"
                color="grey darken-2"
                block
                class="mt-3"
                >Recortar imagem</v-btn>
            </v-flex>
          </v-layout>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card-text>
  <v-card-actions>
    <v-btn
      text
      color="primary"
      @click="fechar()"
    >
      Cancelar
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn
      v-if="btnPular"
      text
      color="primary"
      @click="botaoPular()"
    >
      {{step == 2 ? 'Pular' : 'Fechar'}}
    </v-btn>
    <v-btn
      v-else
      text
      color="primary"
      @click="btnConfirmar()"
    >
      Salvar
    </v-btn>
  </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { empresasDao } from '@/api'

export default {
  props: ['empresa'],
  data () {
    return {
      step: 1,
      valid: true,
      mostrar: true,
      mostrar2: true,
      croppingLogo: false,
      croppingCapa: false,
      imagem: null,
      capa: null,
      razaoRules: [
        v => !!v || 'Insira Razão Social!'
      ],
      cnpjRules: [
        v => !!v || 'Insira Cnpj!'
      ],
      cropped: null,
      nome_imagem: null,
      categorias: [
        { text: 'Alimentação e Bebidas', value: 1 },
        { text: 'Automóveis e Peças Automotivas', value: 2 },
        { text: 'Construção e Imóveis', value: 3 },
        { text: 'Design e Criatividade', value: 4 },
        { text: 'Moda e Estilo', value: 5 },
        { text: 'Saúde e Bem-Estar', value: 6 },
        { text: 'Beleza e Estética', value: 7 },
        { text: 'Educação e Treinamento', value: 8 },
        { text: 'Outra', value: 9 }
      ],
      subcategorias: [
        // Alimentação e Bebidas
        { text: 'Restaurante', value: 1, categoria: 1, servico: false },
        { text: 'Lanchonete', value: 2, categoria: 1, servico: false },
        { text: 'Pizzaria', value: 3, categoria: 1, servico: false },
        { text: 'Bar', value: 4, categoria: 1, servico: false },
        { text: 'Buffet', value: 5, categoria: 1, servico: true },
        { text: 'Supermercado', value: 6, categoria: 1, servico: false },
        { text: 'Venda de Água Mineral', value: 7, categoria: 1, servico: false },
        { text: 'Padaria', value: 8, categoria: 1, servico: false },

        // Automóveis e Peças Automotivas
        { text: 'Auto Peças', value: 9, categoria: 2, servico: false },
        { text: 'Oficina', value: 10, categoria: 2, servico: true },
        { text: 'Concessionária', value: 11, categoria: 2, servico: false },
        { text: 'Transportadora', value: 12, categoria: 2, servico: true },
        { text: 'Lava Jato', value: 13, categoria: 2, servico: true },
        { text: 'Locação', value: 14, categoria: 2, servico: true },
        { text: 'Auto Escola', value: 15, categoria: 2, servico: true },
        { text: 'Equipadora', value: 16, categoria: 2, servico: true },

        // Construção e Imóveis
        { text: 'Material de Construção', value: 17, categoria: 3, servico: false },
        { text: 'Construtora', value: 18, categoria: 3, servico: true },
        { text: 'Metalurgica', value: 19, categoria: 3, servico: true },
        { text: 'Madeireira', value: 20, categoria: 3, servico: false },
        { text: 'Portão Automático', value: 21, categoria: 3, servico: false },
        { text: 'Vidraçarias', value: 22, categoria: 3, servico: true },
        { text: 'Engenharia', value: 23, categoria: 3, servico: true },
        { text: 'Serralharia', value: 24, categoria: 3, servico: true },
        { text: 'Corretagem e Consultoria Imobiliária', value: 25, categoria: 3, servico: true },
        { text: 'Desenvolvimento Imobiliário', value: 26, categoria: 3, servico: true },
        { text: 'Gestão de Propriedades', value: 27, categoria: 3, servico: true },
        { text: 'Construção Residencial', value: 28, categoria: 3, servico: true },
        { text: 'Construção Comercial', value: 29, categoria: 3, servico: true },
        { text: 'Serviços de Manutenção Predial', value: 30, categoria: 3, servico: true },
        { text: 'Investimento Imobiliário', value: 31, categoria: 3, servico: true },
        { text: 'Avaliação de Propriedades', value: 32, categoria: 3, servico: true },
        { text: 'Empreendimentos Sustentáveis', value: 33, categoria: 3, servico: false },
        { text: 'Arquitetura e Design de Interiores', value: 34, categoria: 3, servico: true },

        // Design e Criatividade
        { text: 'Design Gráfico', value: 35, categoria: 4, servico: true },
        { text: 'Design de Produto', value: 36, categoria: 4, servico: true },
        { text: 'Design de Interiores', value: 37, categoria: 4, servico: true },
        { text: 'Design de Moda', value: 38, categoria: 4, servico: true },
        { text: 'Arquitetura', value: 39, categoria: 4, servico: true },
        { text: 'Design de Mídia', value: 40, categoria: 4, servico: true },
        { text: 'Ilustração e Arte Gráfica', value: 41, categoria: 4, servico: true },
        { text: 'Web Design e Desenvolvimento', value: 42, categoria: 4, servico: true },

        // Moda e Estilo
        { text: 'Roupa Feminina', value: 42, categoria: 5, servico: false },
        { text: 'Roupa Unissex', value: 43, categoria: 5, servico: false },
        { text: 'Roupa Masculina', value: 44, categoria: 5, servico: false },
        { text: 'Roupa Infantil', value: 45, categoria: 5, servico: false },
        { text: 'Ótica', value: 46, categoria: 5, servico: false },
        { text: 'Pronta Entrega', value: 47, categoria: 5, servico: false },
        { text: 'Calçados e Bolsas', value: 48, categoria: 5, servico: false },
        { text: 'Conserto de Roupa', value: 49, categoria: 5, servico: false },
        { text: 'Atelier', value: 50, categoria: 5, servico: true },
        { text: 'Acessórios', value: 51, categoria: 5, servico: false },
        { text: 'Armarinho', value: 52, categoria: 5, servico: false }, // verificar
        { text: 'Roupas Esportivas e Ativewear', value: 53, categoria: 5, servico: false },

        // Saúde e Bem-Estar
        { text: 'Clínica', value: 53, categoria: 6, servico: true }, // verificar
        { text: 'Farmácia', value: 54, categoria: 6, servico: false },
        { text: 'Plano de Saúde', value: 55, categoria: 6, servico: false },
        { text: 'Serviços Médicos', value: 56, categoria: 6, servico: true },
        { text: 'Serviços Odontológico', value: 57, categoria: 6, servico: true },
        { text: 'Laboratório', value: 58, categoria: 6, servico: true },
        { text: 'Posto de Saúde', value: 59, categoria: 6, servico: true },
        { text: 'Nutrição', value: 60, categoria: 6, servico: true },
        { text: 'Farmácia de Manipulação', value: 61, categoria: 6, servico: false },
        { text: 'Fitness', value: 62, categoria: 6, servico: true },

        // Beleza e Estética
        { text: 'Salão de Beleza', value: 63, categoria: 7, servico: true },
        { text: 'Depilação', value: 64, categoria: 7, servico: true },
        { text: 'Perfumaria', value: 65, categoria: 7, servico: false },
        { text: 'Cosméticos', value: 66, categoria: 7, servico: false },
        { text: 'Massagem', value: 67, categoria: 7, servico: true },
        { text: 'Barbearia', value: 68, categoria: 7, servico: true },
        { text: 'Tatuagem', value: 69, categoria: 7, servico: true },
        { text: 'Piercing', value: 70, categoria: 7, servico: true },
        { text: 'Esmalteria', value: 71, categoria: 7, servico: false },
        { text: 'Bronzeamento', value: 72, categoria: 7, servico: true },
        { text: 'Maquiagem', value: 73, categoria: 7, servico: false },
        { text: 'Cuidados com a Pele', value: 74, categoria: 7, servico: true },
        { text: 'Manicure e Pedicure', value: 75, categoria: 7, servico: true },
        { text: 'Spas de Beleza', value: 76, categoria: 7, servico: true },
        { text: 'Cirurgia Plástica Estética', value: 77, categoria: 7, servico: true },
        { text: 'Design de Sobrancelhas e Cílios', value: 77, categoria: 7, servico: true },

        // Educação e Treinamento
        { text: 'Instituições Educacional', value: 78, categoria: 8, servico: true },
        { text: 'Treinamento Corporativo', value: 79, categoria: 8, servico: true },
        { text: 'Educação à Distância', value: 79, categoria: 8, servico: true },
        { text: 'Cursos de Idiomas', value: 80, categoria: 8, servico: true },
        { text: 'Cursos Técnicos', value: 81, categoria: 8, servico: true },
        { text: 'Educação Especial', value: 82, categoria: 8, servico: true },
        { text: 'Faculdade', value: 83, categoria: 8, servico: true },
        { text: 'Livrarias', value: 84, categoria: 8, servico: false },
        { text: 'Transporte Escolar', value: 85, categoria: 8, servico: true },
        { text: 'Escola Ensino Fundamental', value: 86, categoria: 8, servico: true },
        { text: 'Escola Ensino Infantil', value: 87, categoria: 8, servico: true },
        { text: 'Escola Ensino Médio', value: 88, categoria: 8, servico: true },

        // Outros
        { text: 'Lavanderia', value: 89, categoria: 9, servico: true },
        { text: 'Serigrafia', value: 90, categoria: 9, servico: true },
        { text: 'Assistencia Tecnica', value: 91, categoria: 9, servico: true },
        { text: 'Contabilidade', value: 92, categoria: 9, servico: true },
        { text: 'Associação', value: 93, categoria: 9, servico: true },
        { text: 'Eletrica', value: 94, categoria: 9, servico: true },
        { text: 'Eletrônica', value: 95, categoria: 9, servico: true },
        { text: 'Sistema de Segurança', value: 96, categoria: 9, servico: true },
        { text: 'Venda de Gás de Cozinha', value: 97, categoria: 9, servico: false }
      ]
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    btnPular () {
      if (this.step < 2) return false
      else if (this.step === 2 && this.imagem !== this.empresa.url_logo) return false
      else if (this.step === 3 && this.capa !== this.empresa.url_imagem_posto) return false
      return true
    },
    empresaLogada () {
      return this.$store.getters.empresa
    },
    estados () {
      return this.$store.getters.estados
    }
  },
  mounted () {
    if (this.empresa) {
      this.imagem = this.empresa.url_logo
      this.capa = this.empresa.url_imagem_posto
    }
  },
  methods: {
    validarMatematicamenteCNPJ () {
      if (this.empresa.cnpj.length === 14) {
        var digito1 = null
        var digito2 = null
        var indiceDeCalculo = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
        digito1 = this.calculoDv(indiceDeCalculo, 2)
        indiceDeCalculo = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
        digito2 = this.calculoDv(indiceDeCalculo, 1)
        if (digito1 > 9) {
          if (parseInt(this.empresa.cnpj.substring(12, 13)) === 0 && digito2 === parseInt(this.empresa.cnpj.substring(13))) {
            return true
          }
        } else {
          if (digito1 === parseInt(this.empresa.cnpj.substring(12, 13)) || digito2 === parseInt(this.empresa.cnpj.substring(13))) {
            return true
          }
        }
      }
    },
    botaoPular () {
      if (this.step === 2) this.step++
      else this.$emit('fechar')
    },
    calculoDv (indiceDeCalculo, dig) {
      var somaCnpj = 0
      var i = 0
      while (i < this.empresa.cnpj.length - dig) {
        somaCnpj = somaCnpj + this.empresa.cnpj.substring(i, i + 1) * indiceDeCalculo[i]
        i++
      }
      return (somaCnpj * 10) % 11
    },
    fechar () {
      // this.$refs.form.reset()
      this.$emit('fechar')
    },
    searchCep () {
      if (!this.empresa || this.empresa.endereco || this.empresa.endereco.cep || this.empresa.endereco.cep.length < 8) {
        return
      }
      fetch(`https://viacep.com.br/ws/${this.empresa.endereco.cep.replace(/[^\d]+/g, '')}/json/`,
        {
          method: 'get',
          mode: 'cors',
          headers: {
            Accept: 'application/json'
          }
        })
        .then(res => res.json())
        .then(json => {
          this.empresa.endereco.logradouro = json.logradouro
          this.empresa.endereco.bairro = json.bairro
          this.empresa.endereco.cidade = json.localidade
          this.empresa.endereco.estado = json.uf
        })
    },
    btnConfirmar () {
      if (this.step === 1) {
        this.alterarEmpresa()
      } else this.inserirImagem()
    },
    async alterarEmpresa () {
      if (this.$refs.form.validate()) {
        try {
          await this.buscarCoordenadas()
        } catch {
          this.$store.dispatch('setError', 'Não foi possível pegar coordenas para esse endereço.')
        }
        this.empresa.contato1 = this.empresa.contato1 != null ? this.empresa.contato1.replace(/[^\d]+/g, '') : null
        this.empresa.contato2 = this.empresa.contato2 != null ? this.empresa.contato2.replace(/[^\d]+/g, '') : null
        this.empresa.contato3 = this.empresa.contato3 != null ? this.empresa.contato3.replace(/[^\d]+/g, '') : null
        empresasDao.editarEmpresa(this.empresa.cnpj, this.empresa, this.headers)
          .then(response => {
            if (response.ok) {
              this.step = 2
            } else {
              this.$emit('error', 'Desculpe, não foi possível modificar a empresa no momento')
              this.fechar()
            }
          })
      }
    },
    carregarImagem (event) {
      if (this.step === 2) {
        this.imagem = null
        this.mostrar = false
      } else {
        this.capa = null
        this.mostrar2 = false
      }
      if (event.target.files && event.target.files[0]) {
        const imagem = event.target.files[0]
        const reader = new FileReader()
        reader.onloadend = e => {
          if (this.step === 2) {
            this.croppingLogo = true
            this.$refs.croppieRef.bind({
              url: reader.result
            })
            this.$nextTick(() => {
              this.$refs.croppieRef.setZoom(0.1)
            })
          } else {
            this.croppingCapa = true
            this.$refs.croppieCapaRef.bind({
              url: reader.result
            })
            this.$nextTick(() => {
              this.$refs.croppieCapaRef.setZoom(0.1)
            })
          }
        }
        reader.readAsDataURL(imagem)
      }
    }, // carregarImagem
    recortarImagem () {
      const options = {
        type: 'base64',
        format: 'png'
      }
      if (this.step === 2) {
        this.mostrar = true
        options.circle = true
      } else this.mostrar2 = true

      const refscroppie = this.step === 2 ? this.$refs.croppieRef : this.$refs.croppieCapaRef
      refscroppie.result(options, output => {
        if (this.step === 2) {
          this.imagem = output
          this.croppingLogo = false
        } else {
          this.capa = output
          this.croppingCapa = false
        }
      })
    }, // recortarImagem
    async inserirImagem () {
      const cnpj = this.empresa.cnpj
      let url
      await this.$store.dispatch('inserirFirebase', {
        prefixo: `empresa_${this.step === 2 ? 'logo' : 'capa'}`,
        id: cnpj,
        arquivo: this.step === 2 ? this.imagem : this.capa,
        local: 'empresas',
        tipo: 'png'
      }).then(downloadUrl => {
        url = downloadUrl
      })
      const data = { cnpj: cnpj }
      if (this.step === 2) {
        data.url_logo = url
      } else {
        data.url_imagem_posto = url
      }
      await this.$store.dispatch('atualizarImagemEmpresa', data).then(() => {
        this.$store.dispatch('setSuccess', 'As informações da empresa foram alteradas com sucesso')
        this.$emit('carregar')
        if (this.step === 2) {
          this.step++
        } else {
          this.fechar()
        }
      })
    },
    buscarCoordenadas () {
      return this.$store.dispatch('pegarCoordenadas', {
        logradouro: this.empresa.endereco.logradouro,
        numero: this.empresa.endereco.numero,
        bairro: this.empresa.endereco.bairro,
        cidade: this.empresa.endereco.cidade,
        estado: this.empresa.endereco.estado
      }).then(json => {
        this.empresa.latitude = json.latitude
        this.empresa.longitude = json.longitude
      })
    }
  }
}
</script>
