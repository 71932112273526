import settings from '@/settings'

const getFuncionariosEmpresa = (headers, filtros) => {
  let url = `${settings.apetrusApi}/funcionarios/`

  if (filtros) {
    const keys = Object.keys(filtros)
    for (let i = 0; i < keys.length; i++) {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${keys[i]}=${filtros[keys[i]]}`
    }
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: headers
  })
}

const ativarOuDesativarFuncionario = (data, headers) => {
  return fetch(`${settings.apetrusApi}/funcionarios/bloqueio/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const getFuncionariosSemPaginacao = (cnpj, headers) => {
  return fetch(`${settings.apetrusApi}/funcionarios/?empresa=${cnpj}`, {
    method: 'GET',
    mode: 'cors',
    headers: headers
  })
}

const getFuncionariosEmpresaConfirmados = (data, headers) => {
  return fetch(`${settings.apetrusApi}/funcionarios/funcionariosempresaconfirmados/?page=${data.pagination.page}&page_size=${data.pagination.rowsPerPage}&empresa=${data.empresa}`, {
    method: 'GET',
    mode: 'cors',
    headers: headers
  })
}

const getFuncionariosEmpresaConfirmadosSemPaginacao = (data, headers) => {
  return fetch(`${settings.apetrusApi}/funcionarios/funcionariosempresaconfirmados/?notPagination=${true}&empresa=${data}`, {
    method: 'GET',
    mode: 'cors',
    headers: headers
  })
}

const carregarFuncionarios = (empresa, headers) => {
  return fetch(`${settings.apetrusApi}/funcionarios/carregarfuncionarios/?empresa=${empresa}`, {
    method: 'GET',
    mode: 'cors',
    headers: headers
  })
}

const verificarfuncionario = (mail, headers) => {
  return fetch(`${settings.apetrusApi}/funcionarios/verificarfuncionario/${mail}`, {
    method: 'GET',
    mode: 'cors',
    headers: headers
  })
}

const inserir = (data, headers) => {
  return fetch(`${settings.apetrusApi}/funcionarios/cadastrarfuncionario/`, {
    method: 'post',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const atualizar = (headers) => {
  return fetch(`${settings.apetrusApi}/funcionarios/updatefuncionario/`, {
    method: 'PUT',
    mode: 'cors',
    headers: headers
  })
}

const atualizarColaborador = (data, headers) => {
  return fetch(`${settings.apetrusApi}/funcionarios/updatefuncionarioempresa/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const removerFuncionario = (headers, data) => {
  return fetch(`${settings.apetrusApi}/funcionarios/removerfuncionario/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const darPermissaoCompraaberta = (data, id, headers) => {
  return fetch(`${settings.apetrusApi}/funcionarios/compraabertafuncionario/?proposta=${id}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const retirarPermissaoCompraaberta = (data, headers) => {
  return fetch(`${settings.apetrusApi}/funcionarios/retirarpermissaofuncionario/`, {
    method: 'PATCH',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const contact = (mail, headers) => {
  return fetch(`${settings.apetrusApi}/sendmail/`, {
    method: 'post',
    body: JSON.stringify(mail),
    mode: 'cors',
    headers: headers
  })
}

export default {
  getFuncionariosEmpresa,
  getFuncionariosEmpresaConfirmados,
  verificarfuncionario,
  inserir,
  contact,
  atualizar,
  carregarFuncionarios,
  ativarOuDesativarFuncionario,
  removerFuncionario,
  darPermissaoCompraaberta,
  retirarPermissaoCompraaberta,
  atualizarColaborador,
  getFuncionariosSemPaginacao,
  getFuncionariosEmpresaConfirmadosSemPaginacao
}
