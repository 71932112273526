<template>
  <v-card>
    <v-card-title>Selecione uma empresa para entrar</v-card-title>
    <v-divider></v-divider>
    <v-card-text style="height: 400px;">
      <v-list>
        <template v-for="item in empresas">
          <v-list-item :key="item.cnpj" :value="item" @click="selecionarEmpresa(item)" ripple>
            <v-list-item-content>
              <v-list-item-title v-html="item.nome_fantasia">
              </v-list-item-title>
              <v-list-item-subtitle v-html="`CNPJ: ${item.cnpj}`">
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-avatar v-on="on">
                    <v-icon v-if="item.ativo" color="green">done_all</v-icon>
                    <v-icon v-else color="red">close</v-icon>
                  </v-avatar>
                </template>
                <span v-if="item.ativo">Ativo</span>
                <span v-else>Inativo</span>
              </v-tooltip>
            </v-list-item-avatar>
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="cadastrarEmpresa()">Cadastrar nova Empresa</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xs"
      persistent
      v-model="cadastroEmpresa"
      width="600"
    >
      <cad-empresa @fechar="cadastroEmpresa = false"></cad-empresa>
    </v-dialog>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xs"
      persistent
      v-model="dialogEditarEmpresa"
      width="600"
    >
      <edit-empresa @fechar="dialogEditarEmpresa = false"></edit-empresa>
    </v-dialog>
  </v-card>
</template>

<script>
import CadEmpresa from '@/components/cadastros/CadNovaEmpresa'
import EditEmpresa from '@/components/cadastros/EditNovaEmpresa'

export default {
  components: { CadEmpresa, EditEmpresa },
  data () {
    return {
      empresa: null,
      cadastroEmpresa: false,
      dialogEditarEmpresa: false
    }
  },
  watch: {
    headers (val) {
      if (val !== null && val !== undefined) {
        this.listarEmpresas()
      }
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    empresas () {
      return this.$store.getters.empresas
    }
  },
  mounted () {
    if (this.headers) {
      this.listarEmpresas()
    }
  },
  methods: {
    fecharEmpresa () {
      this.cadastroEmpresa = false
    },
    selecionarEmpresa (empresa) {
      this.$emit('empresaSelected', empresa)
      this.empresa = null
    },
    listarEmpresas () {
      this.$store.dispatch('minhasEmpresas')
    },
    cadastrarEmpresa () {
      this.cadastroEmpresa = true
    },
    editarEmpresa (empresa) {
      this.empresa = empresa
      this.dialogEditarEmpresa = true
    }
  }
}
</script>
