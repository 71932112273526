<template>
  <v-card>
    <v-toolbar color="primary">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="fechar">
            <v-icon color="white">close</v-icon>
          </v-btn>
        </template>
        <span>Fechar</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-toolbar-title style="color: white;">
        Detalhes da proposta
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-alert v-if="error" type="error" class="mt-4 ml-1 mr-1 mb-1 pa-1">
      <h4>{{error}}</h4>
    </v-alert>
    <v-card-text>
      <v-row v-if="this.proposta">
        <v-col cols="12" sm="6" md="12">
          <div class="my-1 subtitle-1 black--text">Nome: <b>{{ getDestinatario(proposta.posto || proposta.rede)}}</b> </div>
           <div class="my-1 subtitle-1 black--text">Modelo Pagamento: <b>{{ tipos_proposta[proposta.modelo_faturamento] }}</b></div>
           <div class="my-1 subtitle-1 black--text" v-if="proposta.modelo_faturamento === 'pospago'">
             Faturas: <b>{{proposta.periodo === 0 ? 'Semanal' : (proposta.periodo === 1 ? 'Quinzenais' : 'Mensais')}}</b>
            </div>
          <div class="my-1 subtitle-1 black--text">Data de envio: <b>{{proposta.data_abertura | formatDate('DD/MM/YYYY')}}</b></div>
          <div class="my-1 subtitle-1 black--text" v-if="proposta.desconto != null">
            {{proposta.modelo_faturamento === 'prepago'? 'Acréscimo': 'Desconto'}}: <b>{{proposta.desconto}}%</b>
          </div>
          <div class="my-1 subtitle-1 black--text">Status da proposta: <b :style="{'color': cor}">{{statusProposta()}}</b></div>
          <div class="my-1 subtitle-1 black--text">Valor total: <b>{{ (proposta.valor || proposta.credito_apetrus) | currency({ fractionCount: 2 })}}</b></div>
          <div class="my-1 subtitle-1 black--text">
            Para:
            <b>
              <template v-if="proposta.combustivel">
                {{ proposta.combustivel.nome }}
              </template>
              <template v-if="proposta.item">
                {{ proposta.item.nome }}
              </template>
              <template v-else>
                Todos os combustíveis
              </template>
            </b>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="proposta.status == null && proposta.data_resposta && mostra" align-content-space-between>
      <v-btn text color="red" dark @click="finalizarNegociacao(false)">Recusar</v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="blue" dark  @click="finalizarNegociacao(true)">Aceitar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['proposta'],
  data () {
    return {
      mostra: true,
      error: null,
      cor: '',
      tipos_proposta: {
        credito: 'Crédito Apetrus',
        // pospago: 'Pós-Pago',
        prepago: 'Pré-pago',
        presencial: 'Presencial'
      }
    }
  },
  methods: {
    fechar () {
      this.mostra = true
      this.error = null
      this.$emit('fechar')
    },
    finalizarNegociacao (status) {
      this.proposta.status = status
      this.$store.dispatch('responderProposta', this.proposta).then(() => {
        this.mostra = false
        this.$emit('carregar')
      }).catch(() => {
        this.error = 'Não foi possível enviar resposta'
      })
    },
    getDestinatario  (destinatario) {
      if (!destinatario) return 'Apetrus'
      else if (destinatario.nome) return `Rede ${destinatario.nome.replace('Rede', '')}`
      else if (destinatario.nome_fantasia) return `Posto ${destinatario.nome_fantasia.replace('Posto', '')}`
      return undefined
    },
    statusProposta () {
      if (this.proposta.status === false) {
        this.cor = 'red'
        return 'Proposta Rejeitada'
      } else if (this.proposta.status === true) {
        this.cor = 'green'
        return 'Proposta Aceita'
      } else if (this.proposta.status === null && this.proposta.data_resposta) {
        this.cor = '#1E90FF'
        return 'Responda o Posto'
      } else {
        this.cor = '#1E90FF'
        return 'Aguardando Resposta'
      }
    }
  }
}
</script>
