<template>
  <v-card class="mx-auto rounded-lg" color="grey lighten-4" max-width="285" v-if="posto">
    <v-img height="130" v-if="posto.url_imagem_posto" :src="posto.url_imagem_posto"/>
    <v-card v-else color="grey" height="130" class="rounded-0">
      <v-layout justify-center align-center fill-height>
          <div class="title white--text pa-4 text-xs-center">Sem Imagem</div>
      </v-layout>
    </v-card>
    <v-card-title primary-title>
      <v-layout>
        <h1 style="font-size: 0.75em;">{{ posto.nome_fantasia }}</h1>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="dialogDetalhes = true">
              <v-icon class="mr-2">visibility</v-icon>
            </v-btn>
          </template>
          <span>Detalhes</span>
        </v-tooltip>
      </v-layout>
    </v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="pegarPosto"
                >
                  <v-icon>build</v-icon>
                </v-btn>
              </template>
              <v-list v-if="postoSelecionado">
                <v-list-item v-if="postoSelecionado.borracharia">
                  <v-list-item-content>
                      <v-list-item-title v-text="'Borracharia'"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="postoSelecionado.trocaDeOleo">
                  <v-list-item-content>
                      <v-list-item-title v-text="'Troca de Oleo'"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="postoSelecionado.oficina">
                  <v-list-item-content>
                      <v-list-item-title v-text="'Oficina'"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="postoSelecionado.restaurante">
                  <v-list-item-content>
                      <v-list-item-title v-text="'Restaurante'"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="postoSelecionado.hospedagem">
                  <v-list-item-content>
                      <v-list-item-title v-text="'Hospedagem'"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="postoSelecionado.conveniencia">
                  <v-list-item-content>
                      <v-list-item-title v-text="'Conveniencia'"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
        <span>Serviços</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="pegarPosto"
                  >
                    <v-icon>alarm</v-icon>
                  </v-btn>
                </template>
                <v-list v-if="postoSelecionado &&  postoSelecionado.horarios_funcionamento">
                  <v-list-item v-for="(horario, index) in postoSelecionado.horarios_funcionamento" :key="index">
                    <v-list-item-content>
                        <v-list-item-title v-text="formatarTexto(horario.dia_semana)"></v-list-item-title>
                        <v-list-item-subtitle class="grey--text text--darken-3 caption font-weight-medium" v-text="`${horario.hora_abertura.substring(0, 5)} - ${horario.hora_fechamento.substring(0, 5)}`"></v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="postoSelecionado.horarios_funcionamento.length == 0">
                    <v-list-item-title v-text="'Não possui horário de funcionamento cadastrado'"></v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
        </template>
        <span>Horários</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn
                icon
                @click="$emit('postoSelecionado', posto)"
              >
                <v-icon>business_center</v-icon>
              </v-btn>
            </div>
        </template>
        <span>Enviar proposta</span>
      </v-tooltip>
        <v-spacer></v-spacer>
    </v-card-actions>
    <v-dialog v-model="dialogDetalhes" scrollable v-if="dialogDetalhes" width="700" min-height="1000">
      <dialog-detalhes-do-posto @fechar="dialogDetalhes = false" :posto="posto" />
    </v-dialog>
  </v-card>
</template>

<script>
import DialogDetalhesDoPosto from '@/components/dialogs/DialogDetalhesDoPosto.vue'
export default {
  components: { DialogDetalhesDoPosto },
  props: ['posto'],
  data () {
    return {
      endereco: null,
      lista_ofertas: [],
      dialogPropostas: false,
      dialogDetalhes: false,
      combustivelSelecionada: null,
      ofertas: [],
      postoSelecionado: null,
      combustiveis: []
    }
  },
  methods: {
    pegarPosto () {
      if (!this.postoSelecionado) {
        this.$store.dispatch('pegarPosto', this.posto.cnpj).then(posto => {
          this.postoSelecionado = posto
        })
      }
    },
    formatarTexto (texto) {
      const text = texto[0].toUpperCase() + texto.slice(1)
      return text
    }
  }
}
</script>
