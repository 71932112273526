<template>
  <v-card>
    <v-alert v-if="apta" type="error" class="mb-1 pa-1">
      <v-layout justify-space-between wrap>
        <v-flex xs8 class="ma-0 pa-0">
        <h4>
          Existem informações que estão faltando em seu cadastro
        </h4>
      </v-flex>
      <v-flex
        xs2
        class="ma-0 pa-0"
        style="display: flex; justify-content: center; align-items: center;"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              elevation="1"
              color="white"
              class="error--text"
              v-on="on"
              small
              rounded
            >
              <v-icon class="mr-1">info</v-icon>
              Saiba mais
            </v-btn>
          </template>
          <v-card width="25em">
            <v-card-title class="justify-center error--text">
              INFORMAÇÕES NÃO PREENCHIDAS
            </v-card-title>
            <v-card-text>
              <h3 class="mb-2">
                Essas informações são necessárias, pois os clientes
                necessitam delas para contatar a sua empresa.
                Informe-as para poder negociar com eles.
              </h3>
              <ul>
                <li><h4>Um <b>Número de Telefone</b> precisa ser inserido</h4></li>
                <li><h4>O <b>Endereço</b> precisa está preenchido</h4></li>
                <li><h4>A <b>Geo localização</b> precisa está confirmada</h4></li>
              </ul>
            </v-card-text>
            <v-card-actions>
              <v-btn
                elevation="1"
                color="error"
                @click="$router.push('/editarempresa')"
                block
                small
              >
                <v-icon class="mr-1">edit</v-icon>
                Editar Empresa
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-flex>
      </v-layout>
    </v-alert>
    <v-alert v-else-if="alerta" :type="tipo" class="mb-1 pa-1">
      <h4>
        {{ alerta }}
      </h4>
    </v-alert>
    <v-card-text class="mt-2" style="font-size: 1em">
      <v-flex v-if="items.length > 0">
        <v-carousel
          height="30em"
          class="ma-0 pa-0"
          style="
            width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
          "
        >
          <v-carousel-item
            v-for="(item,i) in items"
            :key="i"
            :src="item.url"
            style="height: 30em; width: 100%;"
            reverse-transition="fade-transition"
            transition="fade-transition"
            cover
          ></v-carousel-item>
        </v-carousel>
      </v-flex>
      <v-layout
        v-else
        justify-center
        align-center
        fill-height
        style="
          height: 20em;
        "
        >
          <v-icon color="info" size="20em">{{getIcon(veiculo.tipo_veiculo)}}</v-icon>
      </v-layout>
      <v-layout
        wrap
        justify-space-between
        class="mt-4"
        v-if="leilao"
      >
        <v-flex xs4 class="centro">
          <h5>{{ leilao.ultimo_lance ? 'Último Lance' : 'Valor Inicial' }}</h5>
          <h2 v-if="leilao.ultimo_lance">{{ leilao.ultimo_lance.valor | currency({ fractionCount: 2 }) }}</h2>
          <h2 v-else>{{ leilao.valor_inicial | currency({ fractionCount: 2 }) }}</h2>
        </v-flex>
        <v-flex xs4 class="centro">
          <h2 style="font-size: 1.9em;"><i class="material-icons">timer</i>{{ tempo_restante }}</h2>
        </v-flex>
        <v-flex xs4 v-if="leilao.data_inicio" class="centro">
          <h4>
            <i>Horário do Fim: {{ leilao.data_fim | formatDate('HH:mm:ss') }}</i></h4>
          <h4><i>Final: {{ leilao.data_fim | formatDate('DD/MM/YYYY') }}</i></h4>
        </v-flex>
        <v-flex xs4 class="mb-4 mt-2">
          <h3 class="text-center green--text">
            Disputa {{
              leilao.status === 1 ?
              'em aberto' :
              leilao.status === 2 ? 'encerrada' :
              leilao.status === 3 ? 'cancelada' :
              leilao.status === 4 ? 'em andamento' :
              'concluída'
            }}
          </h3>
        </v-flex>
        <v-flex xs4 class="mb-4 mt-2">
          <v-menu
            offset-y
            left
            max-width="30em"
            transition="slide-x-transition"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                small
                v-on="on"
              >
                <v-icon size="2em" class="mr-1">history</v-icon>
                Histórico de Lances
              </v-btn>
            </template>
            <v-list>
              <v-subheader style="font-size: 1.4em;">HISTORÍCO DE LANCES</v-subheader>
              <template v-for="(item, index) in lances">
                <v-list-item :key="`${index}item`">
                  <v-list-item-icon>
                    <v-icon :color="item.responsavel.cnpj === empresa.cnpj ? 'success' : ''">pan_tool</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <template v-if="item.responsavel.cnpj === empresa.cnpj">
                        Seu lance
                      </template>
                      <template v-else>
                        {{ substituirPorAsteriscos(item.responsavel.nome_fantasia) }} - {{ substituirPorAsteriscos(item.responsavel.cnpj) }}
                      </template>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      As {{ item.data | formatDate('HH:mm') }} de {{ item.data | formatDate('DD/MM/YYYY') }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text>
                      {{ item.valor | currency({ fractionCount: 2 }) }}
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
                <v-divider v-if="index < lances.length - 1" :key="`${index}divider`"></v-divider>
              </template>
              <v-list-item v-if="lances.length === 0">
                <v-list-item-content>
                    <v-list-item-title>
                      Ainda nenhum lance foi dado
                    </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-flex>
      </v-layout>
      <v-stepper
        v-model="step"
        style="box-shadow: none;"
        class="mt-4 pa-0"
      >
        <v-stepper-items>
          <v-stepper-content step="1" class="ma-0 pa-0">
            <v-layout wrap justify-space-between>
              <v-flex xs12 v-if="veiculo_data.valor && mostrarBotao && !leilao" class="mb-4">
                <h2 class="text-center"><b>{{veiculo_data.valor | currency({ fractionCount: 2 })}}</b></h2>
              </v-flex>
              <template v-if="veiculo_data.modelo && veiculo_data.marca">
                <v-flex xs6 class="mb-2">Modelo: <b>{{veiculo_data.modelo}}</b></v-flex>
                <v-flex xs6 class="mb-2">Marca: <b>{{veiculo_data.marca}}</b></v-flex>
              </template>
              <v-flex xs6 class="mb-2" v-else-if="veiculo_data.modelo_marca">Modelo/Marca:<b>{{veiculo_data.modelo_marca}}</b></v-flex>
              <v-flex xs6 class="mb-2" v-if="veiculo_data.ano_modelo">Ano do modelo: <b>{{veiculo_data.ano_modelo}}</b></v-flex>
              <v-flex xs6 class="mb-2" v-if="veiculo_data.ano_fabricacao">Ano de Fabricação / modelo: <b>{{veiculo_data.ano_fabricacao}}</b></v-flex>
              <v-flex xs6 class="mb-2" v-if="veiculo_data.cor_texto">
                  Cor em Texto: <b>{{veiculo_data.cor_texto}}</b>
              </v-flex>
              <v-flex xs6 v-if="veiculo_data.cor" class="mb-2">
                  Cor: <v-btn x-small :color="formatarCor(veiculo_data.cor)"></v-btn>
              </v-flex>
              <v-flex xs6 v-if="veiculo_data.versao_motor" class="mb-2">
                  Potencia do motor: <b>{{veiculo_data.motor}}</b>
              </v-flex>
              <v-flex xs6 v-if="veiculo_data.cilindradas && is_moto" class="mb-2">
                  Cilindradas: <b>{{veiculo_data.cilindradas}}</b>
              </v-flex>
              <v-flex xs6 v-if="veiculo_data.tipo_veiculo" class="mb-2">
                  Tipo veículo: <b>{{veiculo_data.tipo_veiculo.denominacao}}</b>
              </v-flex>
              <template v-if="veiculo_data.motorista && mostrar_dados">
                <v-flex xs6 v-if="veiculo_data.motorista.pessoa" class="mb-2">
                    Nome Completo: <b>{{veiculo_data.motorista.pessoa.nome}} {{veiculo_data.motorista.pessoa.sobrenome}}</b>
                </v-flex>
              </template>
              <template v-if="veiculo_data.empresa && mostrar_dados">
                <v-flex xs6 v-if="veiculo_data.empresa.nome_fantasia" class="mb-2">
                    Empresa proprietária: <b>{{veiculo_data.empresa.nome_fantasia}}</b>
                </v-flex>
              </template>
              <v-flex xs6 v-if="veiculo_data.cambio && !is_moto" class="mb-2">
                Cambio: <b>{{cambio[veiculo_data.cambio - 1]}}</b>
              </v-flex>
              <v-flex xs6 v-if="veiculo_data.direcao && !is_moto" class="mb-2">
                Direção: <b>{{direcao[veiculo_data.direcao - 1]}}</b>
              </v-flex>
              <template v-if="veiculo_data.pessoa">
                <v-flex xs6 v-if="veiculo_data.pessoa.telefone" class="mb-2">
                  Contato: <b>{{veiculo_data.pessoa.telefone.startsWith('+55') ? veiculo_data.pessoa.telefone : `+55${veiculo_data.pessoa.telefone}`}}</b>
                </v-flex>
                <v-flex xs6 v-if="veiculo_data.pessoa.nome_completo" class="mb-2">
                  Cliente: <b>{{veiculo_data.pessoa.nome_completo}}</b>
                </v-flex>
              </template>
              <v-flex xs6 v-if="veiculo_data.valor && !mostrarBotao" class="mb-4">
                Valor: <b>{{veiculo_data.valor | currency({ fractionCount: 2 })}}</b>
              </v-flex>
              <v-flex xs6 v-if="veiculo_data.valor_estimado" class="mb-4">
                Valor estimado: <b>{{veiculo_data.valor_estimado | currency({ fractionCount: 2 })}}</b>
              </v-flex>
              <v-flex xs6 v-if="veiculo_data.endereco" class="mb-4">
                Endereço: <b>{{veiculo_data.endereco}}</b>
              </v-flex>
              <v-flex xs6 class="mb-4">
                Usado: <b>{{ veiculo_data.usado ? 'SIM' : 'NÃO' }}</b>
              </v-flex>
              <v-flex
                xs6
                v-if="
                  !veiculo_data.usado &&
                  veiculo_data.qtd_estoque
                "
                class="mb-4"
              >
                Quantidade em estoque: <b>{{ veiculo_data.qtd_estoque }}</b>
              </v-flex>
              <v-flex xs6 class="mb-4" v-if="veiculo_data.url_laudo_cautelar">
                Laudo Cautelar: <a target="_blank"  :href="veiculo_data.url_laudo_cautelar">clique para visualizar</a>
              </v-flex>
              <v-flex xs12 v-if="veiculo_data.descricao" class="mb-4">
                Descrição: <b>{{veiculo_data.descricao}}</b>
              </v-flex>
              <v-layout wrap justify-space-around style="width:100%;" v-if="veiculo_data && veiculo_data.opcionais && veiculo_data.opcionais.length > 0">
                <v-flex xs12>
                  <h3 class="text-center mb-4">OPCIONAIS</h3>
                </v-flex>
                <v-flex xs3 v-for="(opcional, index) in veiculo_data.opcionais" :key="index">
                  <v-icon
                    color="success"
                  >
                    done
                  </v-icon>
                  {{ opcional.nome }}
                </v-flex>
              </v-layout>
              <v-flex xs12 class="mt-4" v-if="!mostrar_dados && opcoesFinanciamento.length > 0">
                <h4 style="width: 100%; text-align: center" class="gray--text">Opções de Financiamento</h4>
                <v-list>
                  <template v-for="(item, index) in opcoesFinanciamento">
                    <v-divider :key="`divider-${index}`" v-if="index > 0"></v-divider>
                    <v-list-item :key="index">
                      <v-list-item-icon>
                        <v-icon class="mt-2" color="info">paid</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          Número de Parcelas: <b>{{item.numeroParcelas}}</b>
                        </v-list-item-title>
                        <v-list-item-subtitle>Juros: <b>{{item.juros}}%</b></v-list-item-subtitle>
                        <v-list-item-subtitle>Entrada: <b>{{item.entrada  | currency({ fractionCount: 2 }) }}</b></v-list-item-subtitle>
                        <v-list-item-subtitle>Financiadora: <b>{{item.financiadora }}</b></v-list-item-subtitle>
                        <template v-for="(item2, index2) in item.campos_dinamicos">
                          <v-list-item-subtitle :key="index2" v-if="item2.type == 'Texto'">{{ item2.label}}: <b>{{ item2.value }}</b></v-list-item-subtitle>
                          <v-list-item-subtitle :key="index2" v-if="item2.type == 'Percentual'">{{ item2.label}}: <b>{{ item2.value }}%</b></v-list-item-subtitle>
                          <v-list-item-subtitle :key="index2" v-if="item2.type == 'Valor financeiro'">{{ item2.label}}: <b>{{ item2.value | currency({ fractionCount: 2 }) }}</b></v-list-item-subtitle>
                        </template>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-flex>
            </v-layout>
          </v-stepper-content>
          <v-stepper-content step="2" class="ma-0 pa-0">
            <v-layout wrap justify-space-between>
              <v-flex xs6>
                <v-switch
                    v-model="tem_valor_oferecido"
                    label="Deseja estimar um valor para esse veículo ?"
                    color="primary"
                ></v-switch>
              </v-flex>
              <v-flex xs5>
                <v-btn class="mt-4" small rounded color="primary" @click="troca = !troca">
                  <v-icon class="mr-1">
                    {{ troca ? 'close' :'airport_shuttle'}}
                  </v-icon>
                  {{ troca ? 'Não Oferecer troca' : 'Oferecer troca' }}
                </v-btn>
              </v-flex>
              <v-slide-x-transition>
                <v-flex xs12 v-if="tem_valor_oferecido">
                  <h6 v-if="veiculo_data.valor" class="text-center mb-1" style="font-size: 0.8em;">
                    Valor que o usuário espera receber pelo veículo: {{veiculo_data.valor | currency({ fractionCount: 2 })}}
                  </h6>
                  <v-text-field
                    filled
                    label="Valor estimado oferecido"
                    v-model.lazy="valor_oferecido"
                    v-money="money"
                    prefix="R$"
                    class="mb-1"
                    hide-details
                  ></v-text-field>
                  <h6 class="text-center error--text mb-4" style="font-size: 0.8em;">
                    * Esse valor estará sujeito a alteração após o veículo ser avaliado presencialmente
                  </h6>
                </v-flex>
              </v-slide-x-transition>
              <v-layout justify-center v-if="troca" style="width:100;">
                <v-card class="mb-4" elevation="4" style="width: 90%;">
                  <v-card-title class="justify-center">
                    Opções de troca
                  </v-card-title>
                  <v-card-text>
                    <h3>Selecione um veículo para trocar</h3>
                    <v-list style="width: 100%" class="mb-0">
                      <v-list-item v-for="(v, index) in veiculos" :key="index">
                        <v-list-item-icon>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" :color="formatarCor(v.cor)">{{getIcon(v.tipo_veiculo)}}</v-icon>
                            </template>
                            Cor {{v.cor_texto}}
                          </v-tooltip>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{v.modelo_marca}}</v-list-item-title>
                          <v-list-item-subtitle>Ano: {{v.ano_fabricacao}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-tooltip bottom v-if="veiculoSelecionado && veiculoSelecionado.id_veiculo === v.id_veiculo">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                icon
                                @click="veiculoSelecionado = undefined, carregarVeiculos(false)"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </template>
                            <span>Remover Veículo</span>
                          </v-tooltip>
                          <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                icon
                                color="success"
                                @click="veiculoSelecionado = v, veiculos=[v]"
                              >
                                <v-icon>check</v-icon>
                              </v-btn>
                            </template>
                            <span>Adicionar veículo para troca</span>
                          </v-tooltip>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                    <div class="text-xs-center mb-4 mt-0" style="width: 100%" v-if="!veiculoSelecionado && pages && pages > 1">
                      <v-pagination class="mt-0" v-model="pagination.page" :length="pages" circle></v-pagination>
                    </div>
                    <v-layout wrap justify-space-between class="pa-4" v-show="veiculoSelecionado">
                      <v-flex xs12>
                        <v-switch slot="activator" class="ml-4" v-model="oferecer_retorno" color="info">
                          <template v-slot:label>
                            Oferecer um valor estimado de retorno na troca
                          </template>
                        </v-switch>
                        <template v-if="oferecer_retorno">
                          <v-text-field
                            v-if="oferecer_retorno"
                            outlined
                            label="Valor estimado oferecido para o cliente"
                            v-model.lazy="valor_oferecido_troca"
                            v-money="money"
                            prefix="R$"
                            class="mb-1"
                            hide-details
                          ></v-text-field>
                          <h6 class="text-center error--text mb-4" style="font-size: 0.8em;">
                            * Esse valor estará sujeito a alteração após o veículo ser avaliado presencialmente
                          </h6>
                        </template>
                      </v-flex>
                      <v-flex xs12>
                        <v-switch slot="activator" class="ml-4" v-model="pedir_retorno" color="info">
                          <template v-slot:label>
                              Solicitar um valor estimado de retorno na troca
                          </template>
                        </v-switch>
                        <template v-if="pedir_retorno">
                          <v-text-field
                            outlined
                            label="Valor estimado solicitado por você ao cliente"
                            v-model.lazy="valor_retorno"
                            v-money="money"
                            prefix="R$"
                            class="mb-1"
                            hide-details
                          ></v-text-field>
                          <h6 class="text-center error--text mb-4" style="font-size: 0.8em;">
                            * Esse valor estará sujeito a alteração após o veículo ser avaliado presencialmente
                          </h6>
                        </template>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-layout>
              <v-flex xs12>
                <v-textarea
                  label="Descrição"
                  v-model="descricao"
                  outlined
                  counter
                  maxlength="100"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-stepper-content>
          <v-stepper-content step="3" class="ma-0 pa-0">
            <h4
              v-if="meu_lance && leilao && leilao.ultimo_lance && meu_lance.id === leilao.ultimo_lance.id"
              class="error--text"
            >
              *Você não pode atualizar seu lance, porque ele é o último lance dado
            </h4>
            <v-form ref="formularioLance" v-model="lanceValido" lazy-validation>
              <v-text-field
                outlined
                label="Valor do seu lance"
                v-model.lazy="lance.valor"
                v-money="money"
                prefix="R$"
                class="mb-1 mt-4"
                required
                :rules="[
                  v => verificarValor(v) || 'O valor do lance precisar ser superior ao valor inicial'
                ]"
              ></v-text-field>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-card-actions>
      <v-layout column>
        <v-btn
          class="mb-2"
          rounded
          block
          @click="$emit('fechar')"
          :loading="loading"
        >
          Fechar
        </v-btn>
        <v-btn
          class="mb-2"
          :disabled="disabled"
          rounded
          block
          @click="confirmar"
          color="success"
          v-if="mostrarBotao"
          :loading="loading || salvando"
        >
          <v-icon class="mr-2" v-if="step != 1">
            {{
              step === 2 ?
              'handshake':
              'pan_tool'
            }}
          </v-icon>
          {{ step === 1 ?
            'Avançar' :
            step === 2 ?
            'Enviar Proposta':
            step === 3 && meu_lance && meu_lance.id == leilao.ultimo_lance.id ?
            'Atualizar meu lance':
            'Fazer lance'
          }}
          <v-icon class="ml-2" v-if="step == 1">
            arrow_forward
          </v-icon>
        </v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>

import { VMoney } from 'v-money'
import moment from 'moment'
export default {
  directives: { money: VMoney },
  props: ['veiculo', 'mostrarBotao', 'leilao'],
  data: () => ({
    veiculo_data: {},
    items: [],
    lance: {
      leilao: undefined,
      responsavel: undefined,
      valor: undefined
    },
    lanceValido: false,
    numero_propostas: 0,
    tem_solicitacao: undefined,
    salvando: false,
    alerta: '',
    tipo: 'error',
    step: 1,
    oferecer_retorno: false,
    pedir_retorno: false,
    valor_oferecido: undefined,
    tem_valor_oferecido: false,
    valor_oferecido_troca: undefined,
    valor_retorno: undefined,
    descricao: undefined,
    veiculos: [],
    veiculoSelecionado: undefined,
    opcoesFinanciamento: [],
    totalItens: 0,
    troca: false,
    is_moto: false,
    tempo_restante: '00d:00:00:00',
    cambio: ['Manual', 'Automático', 'Automatizado', 'CVT'],
    direcao: ['Mecânica', 'Hidráulica', 'Elétrica', 'Eletro-hidráulica'],
    lances: [],
    meu_lance: undefined,
    money: {
      decimal: ',',
      thousands: '.',
      precision: 2,
      masked: false
    },
    pagination: {
      page: 1,
      rowsPerPage: 5
    }
  }),
  computed: {
    empresa () {
      return this.$store.getters.empresa
    },
    pages () {
      if (this.pagination.rowsPerPage == null || this.totalItens == null) {
        return 0
      }
      return Math.ceil(this.totalItens / this.pagination.rowsPerPage)
    },
    apta () {
      if (!this.mostrarBotao) return false
      else if (!this.empresa) return true
      else if (!this.empresa.endereco) return true
      else if (!this.empresa.endereco.cep || !this.empresa.endereco.numero) return true
      else if (!this.empresa.contato1 && !this.empresa.contato2 && !this.empresa.contato3) return true
      else if (!this.empresa.latitude || !this.empresa.longitude) return true
      return false
    },
    loading () {
      return this.$store.getters.loading
    },
    disabled () {
      if (this.step === 1 && this.apta && this.tem_solicitacao) return true
      if (this.step === 3 && (
        this.meu_lance && this.leilao && this.leilao.ultimo_lance && this.meu_lance.id === this.leilao.ultimo_lance.id)) return true
      return false
    },
    mostrar_dados () {
      if (this.veiculo.empresa && this.empresa.cnpj !== this.veiculo.empresa.cnpj) return false
      return true
    },
    valor_lance () {
      if (!this.lance.valor) return 0
      return this.formatarValor(this.lance.valor)
    }
  },
  watch: {
    pagination: {
      handler (val, oldval) {
        this.carregarVeiculos(false)
      },
      deep: true
    },
    oferecer_retorno (val) {
      if (val) {
        this.pedir_retorno = false
        this.valor_retorno = undefined
      }
    },
    pedir_retorno (val) {
      if (val) {
        this.oferecer_retorno = false
        this.valor_oferecido_troca = undefined
      }
    },
    troca (val) {
      if (!val) {
        this.oferecer_retorno = false
        this.pedir_retorno = false
        this.valor_retorno = undefined
        this.valor_oferecido_troca = undefined
        this.veiculoSelecionado = undefined
        this.carregarVeiculos(false)
      }
    },
    tem_valor_oferecido (val) {
      if (!val) {
        this.valor_oferecido = null
      }
    }
  },
  methods: {
    ativarAlerta (texto, tipo, permanente) {
      this.alerta = texto
      this.tipo = tipo
      if (!permanente) {
        const interval = setInterval(() => {
          this.alerta = undefined
          clearInterval(interval)
        }, 5000)
      }
    },
    confirmar () {
      if (this.step === 1 && !this.leilao) this.step++
      else if (this.step === 1 && this.leilao) this.step = 3
      else if (this.step === 2) this.cadastrarSolicitacao()
      else this.fazerLance()
    },
    pegarFilaDeEspera () {
      this.$store.dispatch('pegarFilaDeEspera', {
        id_veiculo: this.veiculo.id_veiculo
      }).then(json => {
        this.numero_propostas = json.numero_negociacoes
      })
    },
    verificarValor (valor) {
      if (!this.leilao) return false
      let valorFormatado = valor
      if (!valor) return '0,00'
      if (this.is_number(valor)) {
        valorFormatado = Number(valor)
      } else {
        valorFormatado = Number(valor.replaceAll('.', '').replaceAll(',', '.'))
      }
      if (this.leilao.ultimo_lance) {
        const valorUltimo = Number(this.leilao.ultimo_lance.valor)
        return valorFormatado > valorUltimo
      }
      const valorInicial = Number(this.leilao.valor_inicial)
      return valorFormatado >= valorInicial
    },
    is_number (value) {
      try {
        const teste = Number(value)
        if (!teste) return false
        return true
      } catch (e) {
        return false
      }
    },
    formatarValor (valor) {
      if (!valor) return 0
      if (this.is_number(valor)) {
        return Number(valor)
      } else {
        return Number(valor.replaceAll('.', '').replaceAll(',', '.'))
      }
    },
    pegarDetalhes () {
      this.$store.dispatch('detalharVeiculo', this.veiculo.id_veiculo).then(json => {
        this.veiculo_data = json
        this.items = this.veiculo_data.fotos
        if (json.financiamentos) {
          this.opcoesFinanciamento = json.financiamentos.map((v) => {
            return {
              juros: v.juros,
              numeroParcelas: v.numero_parcelas,
              entrada: v.entrada,
              financiadora: v.financiadora,
              campos_dinamicos: v.campos_dinamicos
            }
          })
        }
      })
    },
    formatarCor (cor) {
      if (cor === null) return ''
      if (cor === undefined) return ''
      if (cor.length === 6) return `#${cor}`
      if (cor.length === 7) return cor
      if (cor.length === 9) return cor.slice(0, 7)
      else {
        let teste = parseInt(cor)
        teste = teste.toString(16)
        return `#${teste.slice(2, 8)}`
      }
    },
    getIcon (tipo) {
      if (tipo && typeof tipo === 'object') tipo = tipo.denominacao
      if (tipo === 'Passageiro' || tipo === 'Automóvel' || tipo === 'Outros') return 'airport_shuttle'
      else if (tipo === 'Caminhão') return 'local_shipping'
      else if (tipo === 'Caminhão trator') return 'agriculture'
      else if (tipo === 'Caminhonete') return 'agriculture'
      else if (tipo === 'Ciclomotor' || tipo === 'Motoneta' || tipo === 'Motocicleta' || tipo === 'Triciclo') return 'two_wheeler'
      else if (tipo === 'Micro-ônibus' || tipo === 'Ônibus') return 'directions_bus'
      return 'airport_shuttle'
    },
    carregarVeiculos (isBtn) {
      const data = {
        empresa: this.empresa.cnpj
      }
      data.page = isBtn ? 1 : this.pagination.page
      data.page_size = this.pagination.rowsPerPage
      if (this.tipo_veiculo) data.tipo_veiculo = this.tipo_veiculo
      if (this.modelo) data.modelo__icontains = this.modelo
      if (this.marca) data.marca__icontains = this.marca
      if (this.cor_texto) data.cor_texto__icontains = this.cor_texto
      this.$store.dispatch('listarVeiculosAVenda', data).then(json => {
        this.veiculos = json.results
        this.totalItens = json.count
      })
    },
    pegarSolicitacao () {
      this.$store.dispatch('solicitacaoVeiculo', {
        method: 'GET',
        id_veiculo: this.veiculo.id_veiculo,
        cnpj: this.empresa.cnpj
      }).then(() => {
        this.tem_solicitacao = false
        if (!this.apta && !this.leilao) {
          this.ativarAlerta(
            this.numero_propostas === 0 ? 'Seja o primeiro a enviar uma proposta por esse veículo' : `${this.numero_propostas} ${this.numero_propostas !== 1 ? 'pessoas estão interessadas' : 'pessoa está interessada'} nesse veículo`,
            'info',
            true
          )
        }
      }).catch(() => {
        this.tem_solicitacao = true
        this.ativarAlerta('Você já enviou uma solicitação para esse cliente', 'error', true)
      })
    },
    cadastrarSolicitacao () {
      this.$store.dispatch('solicitacaoVeiculo', {
        method: 'POST',
        id_veiculo: this.veiculo.id_veiculo,
        cnpj_empresa: this.empresa.cnpj,
        valor_oferecido: !this.tem_valor_oferecido || !this.valor_oferecido ? null : Number(this.valor_oferecido.replaceAll('.', '').replace(',', '.')),
        valor_retorno: !this.pedir_retorno || !this.valor_retorno ? null : Number(this.valor_retorno.replaceAll('.', '').replace(',', '.')),
        valor_oferecido_troca: !this.oferecer_retorno || !this.valor_oferecido_troca ? null : Number(this.valor_oferecido_troca.replaceAll('.', '').replace(',', '.')),
        is_empresa: true,
        descricao: this.descricao,
        id_veiculo_troca: this.veiculoSelecionado ? this.veiculoSelecionado.id_veiculo : null
      }).then(() => {
        this.$emit('fechar')
      })
    },
    pegarLances () {
      if (this.leilao) {
        this.$store.dispatch('lancesLeilao', {
          method: 'GET',
          parametros: {
            leilao: this.leilao.id
          }
        }).then(json => {
          this.lances = json
          this.meu_lance = this.lances.find((l) => l.responsavel.cnpj === this.empresa.cnpj)
        })
      }
    },
    substituirPorAsteriscos (texto) {
      if (!texto) return ''
      // Calcula quantos caracteres devem ser substituídos
      const numCaracteresSubstituir = Math.floor(texto.length * 0.7)

      // Gera uma string com asteriscos
      const asteriscos = '*'.repeat(numCaracteresSubstituir)

      // Concatena os asteriscos com o restante da string original
      const resultado = asteriscos + texto.slice(numCaracteresSubstituir)

      return resultado
    },
    fazerLance () {
      if (this.$refs.formularioLance.validate()) {
        this.salvando = true
        this.$store.dispatch('lancesLeilao', {
          method: this.meu_lance ? 'PUT' : 'POST',
          id: this.meu_lance ? this.meu_lance.id : undefined,
          body: {
            empresa: this.empresa.cnpj,
            ...this.lance,
            valor: this.valor_lance
          }
        }).then(() => {
          this.$emit('carregar')
          this.ativarAlerta(
            this.meu_lance ? 'O valor do seu lance foi atualizado' : 'Seu lance foi realizado com sucesso',
            'info',
            false
          )
          const interval = setInterval(() => {
            clearInterval(interval)
            this.$emit('fechar')
            this.salvando = false
          }, 5000)
        })
      } else {
        this.ativarAlerta('O valor do seu lance precisa ser maior.', 'error', false)
      }
    }
  },
  beforeMount () {
    if (this.leilao) {
      this.pegarLances()
      if (this.leilao.ultimo_lance) {
        this.lance.valor = this.leilao.ultimo_lance.valor
      } else {
        this.lance.valor = this.leilao.valor_inicial
      }
      this.lance.responsavel = this.empresa.cnpj
      this.lance.leilao = this.leilao.id
      const interval = setInterval(() => {
        const inicio = moment(new Date())
        const dataFinalMoment = moment(this.leilao.data_fim)
        const diff = dataFinalMoment.diff(inicio, 'seconds')
        const dias = Math.floor(diff / 86400) // 86400 segundos em um dia
        const tempoRestante = moment.utc(diff * 1000).format('HH:mm:ss')
        if (dias === 0) {
          const tempoRestanteInt = Number(tempoRestante.replaceAll(':', ''))
          if (tempoRestanteInt <= 0) {
            this.$store.dispatch('setError', 'Essa disputa de lances foi finalizada!')
            this.$emit('fechar')
            this.$emit('carregar')
          }
          this.tempo_restante = `${tempoRestante}`
        } else {
          if (Number(dias) < 0) {
            this.$store.dispatch('setError', 'Essa disputa de lances foi finalizada!')
            this.$emit('fechar')
            this.$emit('carregar')
          }
          this.tempo_restante = `${dias}d:${tempoRestante}`
        }
        if (diff <= 0) {
          clearInterval(interval) // Encerre o intervalo
        }
      }, 1000)
    } else {
      this.pegarFilaDeEspera()
      if (this.mostrarBotao) {
        this.carregarVeiculos(false)
        this.pegarSolicitacao()
      }
    }
    if (this.veiculo) {
      if (this.veiculo.tipo_veiculo) {
        this.is_moto = typeof this.veiculo.tipo_veiculo === 'object' ? this.veiculo.tipo_veiculo.denominacao === 'Motocicleta' : this.veiculo.tipo_veiculo === 'Motocicleta'
      }
      this.pegarDetalhes()
    }
  }
}
</script>

<style scoped>
  .centro {
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    flex-direction: column
  }
</style>
