import settings from '@/settings'

const insert = (data, headers) => {
  return fetch(`${settings.apetrusApi}/proposta/insert/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const buscarPropostas = (headers, cnpj, pagination, filtros) => {
  let url = `${settings.apetrusApi}/proposta/buscarpropostas/?empresa=${cnpj}&page=${pagination.page}`
  if (filtros) {
    const keys = Object.keys(filtros)
    for (let i = 0; i < keys.length; i++) {
      url = `${url}${filtros[keys[i]] ? `&${keys[i]}=${filtros[keys[i]]}` : ''}`
    }
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const getPropostaById = (id, headers) => {
  return fetch(`${settings.apetrusApi}/proposta/getpropostabyid/?id=${id}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const aceitarProposta = (data, headers) => {
  return fetch(`${settings.apetrusApi}/proposta/aceitarproposta/`, {
    method: 'PUT',
    mode: 'cors',
    body: JSON.stringify(data),
    cache: 'default',
    headers: headers
  })
}

const inserirComprovante = (data, headers) => {
  return fetch(`${settings.apetrusApi}/proposta/inserircomprovante/`, {
    method: 'PATCH',
    mode: 'cors',
    body: JSON.stringify(data),
    cache: 'default',
    headers: headers
  })
}

const temProposta = (cnpjEmpresa, cnpjPosto, idRede, headers) => {
  return fetch(`${settings.apetrusApi}/proposta/temproposta/${cnpjEmpresa}/?${cnpjPosto ? `cnpjPosto=${cnpjPosto}` : `id_rede=${idRede}`}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const solicitarRecargaProposta = (data, headers) => {
  return fetch(`${settings.apetrusApi}/proposta/recargarproposta/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const removerSolicitacaoRecarga = (data, headers) => {
  return fetch(`${settings.apetrusApi}/proposta/recargarproposta/${data.id}/`, {
    method: 'DELETE',
    mode: 'cors',
    headers: headers
  })
}

const meusCreditosSolicitados = (headers, payload) => {
  let url = `${settings.apetrusApi}/proposta/recargarproposta/?`

  if (payload) {
    const keys = Object.keys(payload)
    for (let i = 0; i < keys.length; i++) {
      url = `${url}${payload[keys[i]] ? `&${keys[i]}=${payload[keys[i]]}` : ''}`
    }
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const comprovanteRecargaProposta = (data, headers) => {
  return fetch(`${settings.apetrusApi}/proposta/comprovanterecargaproposta/`, {
    method: 'PATCH',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const submeterProposta = (id, headers) => {
  return fetch(`${settings.apetrusApi}/proposta/updatepropostapagamentocredito/?id=${id}`, {
    method: 'PATCH',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const desativarProposta = (id, headers) => {
  return fetch(`${settings.apetrusApi}/proposta/desativarproposta/?id=${id}`, {
    method: 'PATCH',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const cadastrarPropostaApetrus = (data, headers) => {
  return fetch(`${settings.apetrusApi}/proposta/apetrus/`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(data),
    cache: 'default',
    headers: headers
  })
}

export default {
  insert,
  buscarPropostas,
  getPropostaById,
  aceitarProposta,
  inserirComprovante,
  temProposta,
  solicitarRecargaProposta,
  removerSolicitacaoRecarga,
  meusCreditosSolicitados,
  comprovanteRecargaProposta,
  submeterProposta,
  desativarProposta,
  cadastrarPropostaApetrus
}
