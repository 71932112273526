<template>
  <v-card>
    <v-card-text>
      <v-stepper v-model="step" style="box-shadow: none;">
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card-title>
              <span class="headline">Qual o endereço de cobrança?</span>
            </v-card-title>
            <v-list>
              <v-list-item @click="selecionarEndereco(pessoa.endereco)" @mouseenter="hideCheckA = true" @mouseleave="hideCheckA = false" v-if="pessoa && pessoa.endereco">
                <v-list-item-icon>
                  <v-icon color="info">home</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="`${pessoa.endereco.logradouro}, Nº${pessoa.endereco.numero}.`"
                    class="font-weight-light"
                  ></v-list-item-title>
                  <v-list-item-subtitle v-text="`${pessoa.endereco.bairro}, ${pessoa.endereco.cidade} - ${pessoa.endereco.estado}`"></v-list-item-subtitle>
                  <v-list-item-subtitle v-text="`${pessoa.endereco.cep}`"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon v-if="hideCheckA" color="info">
                    check
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-list-item @click="selecionarEndereco(empresa.endereco)" @mouseenter="hideCheckB = true" @mouseleave="hideCheckB = false" v-if="!mesmoEndereco">
                <v-list-item-icon>
                  <v-icon color="info">business</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="`${empresa.endereco.logradouro}, Nº${empresa.endereco.numero}.`"
                    class="font-weight-light"
                  ></v-list-item-title>
                  <v-list-item-subtitle v-text="`${empresa.endereco.bairro}, ${empresa.endereco.cidade} - ${empresa.endereco.estado}`"></v-list-item-subtitle>
                  <v-list-item-subtitle v-text="`${empresa.endereco.cep}`"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon v-if="hideCheckB" color="info">
                    check
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-list-item @click="step = 2">
                <v-list-item-icon>
                  <v-icon>add</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  Adicionar Endereço
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-card-title>
              <span class="headline">Adicionar Novo Endereço</span>
            </v-card-title>
            <v-card-text>
              <v-layout wrap justify-space-between>
                <v-flex xs12>
                  <v-text-field @keyup="searchCep" v-model="card.endereco_cobranca.cep" label="CEP"/>
                </v-flex>
                <v-flex xs9>
                  <v-text-field v-model="card.endereco_cobranca.logradouro" label="Logradouro"></v-text-field>
                </v-flex>
                <v-flex xs2>
                  <v-text-field v-model="card.endereco_cobranca.numero" label="Número"></v-text-field>
                </v-flex>
                <v-flex xs4>
                  <v-text-field v-model="card.endereco_cobranca.bairro" label="Bairro"></v-text-field>
                </v-flex>
                <v-flex xs4>
                  <v-text-field v-model="card.endereco_cobranca.cidade" label="Cidade"></v-text-field>
                </v-flex>
                <v-flex xs2>
                  <v-text-field  v-model="card.endereco_cobranca.estado" label="Estado"></v-text-field>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-stepper-content>
          <v-stepper-content step="3">
              <h1 class="mb-3">Ativar Cartão</h1>
              <v-row>
                <v-col cols="15" md="12">
                  <v-text-field
                    v-model="card.numero_cartao"
                    label="Numero do Cartão"
                    v-mask="'#### #### #### ####'"
                    :rules="requiredRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="5" md="4">
                  <v-text-field
                    v-model="card.validade_cartao"
                    label="Mês/Ano"
                    v-mask="'##/##'"
                    :rules="requiredRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="15" md="8">
                  <v-text-field
                    v-model="card.titular.nome"
                    label="Nome do titular escrito no cartão"
                    :rules="requiredRules"
                    required
                  ></v-text-field>

                  <v-col cols="5" md="4">
                    <v-text-field
                      v-model="card.cvv_cartao"
                      label="CVV"
                      v-mask="'###'"
                      :rules="requiredRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-col>
              </v-row>
          </v-stepper-content>
          <v-stepper-content step="4">
            <h1 style="width: 100%; height: 1em; text-align: center;">Deseja Desativar Cartão?</h1>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-card-actions class="justify-space-around" v-if="step == 4">
      <v-btn text @click="fechar">
        NÃO
      </v-btn>
      <v-btn text color="error" @click="desativarCartao">
        SIM
      </v-btn>
    </v-card-actions>
    <v-card-actions class="justify-space-around" v-if="step == 1">
      <v-btn text block @click="fechar">
        Cancelar
      </v-btn>
    </v-card-actions>
    <v-card-actions class="justify-space-around" v-if="step != 4 && step != 1">
      <v-btn text @click="step--">
        Voltar
      </v-btn>
      <v-btn text color="primary" @click="confirmar">
        Confirmar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['cartao'],
  data () {
    return {
      step: 1,
      endereco_cartao: {
        cep: null,
        rua: null,
        numero: null,
        complemento: null,
        cidade: null,
        estado: null,
        pais: null,
        bairro: null
      },
      card: {
        titular: {
          nome: ''
        },
        numero_cartao: null,
        validade_cartao: null,
        cvv_cartao: null,
        bandeira_card: null,
        credito: true,
        descricao: null,
        endereco_cobranca: {
          cep: null,
          rua: null,
          numero: null,
          complemento: null,
          cidade: null,
          estado: null,
          pais: 'BRA',
          bairro: null
        }
      },
      requiredRules: [v => !!v || 'Esse campo é obrigatório'],
      hideCheckA: false,
      hideCheckB: false
    }
  },
  computed: {
    pessoa () {
      return this.$store.getters.pessoa
    },
    loading () {
      return this.$store.getters.loading
    },
    empresa () {
      return this.$store.getters.empresa
    },
    cartao_local () {
      const cartao = this.$store.getters.cartao
      if (cartao && typeof cartao === 'object') {
        return cartao
      } else if (cartao && typeof cartao === 'string') {
        const idCartao = Number(cartao.split('-')[0])
        return {
          id_cartao: idCartao,
          hash_code: cartao.split('-')[1]
        }
      }
      return this.$store.getters.cartao
    },
    mesmoEndereco () {
      if (this.empresa.endereco == null) return false
      else if (this.pessoa.endereco && this.empresa.endereco.cep === this.pessoa.endereco.cep && this.empresa.endereco.numero === this.pessoa.endereco.numero) return true
      else return false
    }
  },
  beforeMount () {
    if (this.cartao) {
      if (this.cartao_local && this.cartao.id_cartao === this.cartao_local.id_cartao) {
        this.step = 4
      } else {
        if (this.cartao.endereco) {
          const endereco = this.cartao.endereco === this.pessoa.endereco ? this.pessoa.endereco : this.empresa.endereco
          if (endereco) {
            endereco.rua = endereco.logradouro
            endereco.num = endereco.numero
            this.card.endereco_cobranca = endereco
          }
          this.step = 3
        } else this.step = 1
      }
      this.card.id_cartao = this.cartao.id_cartao
      this.card.descricao = this.cartao.descricao
      this.card.verificado = this.cartao.verificado
    }
  },
  methods: {
    searchCep () {
      if (this.card.endereco_cobranca.cep.length < 8) return
      fetch(`https://viacep.com.br/ws/${this.card.endereco_cobranca.cep}/json/`, {
        method: 'get',
        mode: 'cors',
        headers: {
          Accept: 'application/json'
        }
      })
        .then((res) => res.json())
        .then((json) => {
          this.card.endereco_cobranca.logradouro = json.logradouro
          this.card.endereco_cobranca.bairro = json.bairro
          this.card.endereco_cobranca.cidade = json.localidade
          this.card.endereco_cobranca.estado = json.uf
        })
    },
    desativarCartao () {
      this.$store.dispatch('desativarCartao').then(() => {
        this.fechar()
      })
    },
    selecionarEndereco (endereco) {
      endereco.num = endereco.numero
      endereco.rua = endereco.logradouro
      this.card.endereco_cobranca = endereco
      this.step = 3
    },
    confirmar () {
      if (this.step === 2) {
        this.card.endereco_cobranca.rua = this.card.endereco_cobranca.logradouro
        this.card.endereco_cobranca.num = this.card.endereco_cobranca.numero
        this.step++
      } else {
        this.card.numero_cartao = this.card.numero_cartao.split(' ').join('')
        const listMesAno = this.card.validade_cartao.split('/')
        this.card.mes_expiracao = listMesAno[0]
        this.card.ano_expiracao = listMesAno[1]
        this.$store.dispatch('obterHash', this.card).then(() => {
          this.$emit('carregarCartoes')
          this.fechar()
        })
      }
    },
    fechar () {
      this.card = {
        titular: null,
        numero_cartao: null,
        validade_cartao: null,
        cvv_cartao: null,
        bandeira_card: null,
        credito: true,
        descricao: null,
        mes_expiracao: '',
        ano_expiracao: '',
        endereco_cobranca: {
          cep: null,
          rua: null,
          numero: null,
          complemento: null,
          cidade: null,
          estado: null,
          pais: null,
          bairro: null
        }
      }
      this.step = 1
      this.$emit('fechar')
    }
  }
}
</script>
