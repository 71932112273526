<template>
  <v-card
      class="mx-auto rounded-lg"
      width="20em"
      :height="leilao ? '27em' : '20em'"
      v-if="veiculo"
      hover
      @click="$emit('visualizar', veiculo)"
    >
    <v-img
      height="10em"
      v-if="veiculo.fotos && veiculo.fotos.length > 0"
      :src="veiculo.fotos[0].url"
    />
    <v-layout
      v-else
      justify-center
      align-center
      fill-height
      style="
          height: 10em;
      "
      >
        <v-icon color="gray" size="8em">{{getIcon(veiculo.tipo_veiculo)}}</v-icon>
    </v-layout>
    <v-card-title primary-title>
      <v-layout wrap style="font-size: 0.75em;">
        <div
          v-if="veiculo.valor && veiculo.valor != '0.00' && !leilao"
          style="font-size: 1.4em;"
          class="mb-2"
        >
          {{veiculo.valor | currency({ fractionCount: 2 })}}
        </div>
        <div
          :style="{
            'width': '100%',
            'font-size': leilao ? '1.4em' : '1.1em'
          }"
          v-if="veiculo.modelo && veiculo.marca"
        >
          {{veiculo.modelo.split(' ')[0]}}/{{veiculo.marca}}
        </div>
        <div
          style="width: 100%; font-size: 1.1em;"
          v-else>
            {{ veiculo.modelo_marca }}
        </div>
        <template v-if="leilao">
          <h5 style="text-align: center; width: 100%;">{{ leilao.ultimo_lance ? 'Último Lance' : 'Valor Inicial' }}</h5>
          <h2 style="text-align: center; width: 100%; height: 1.6em;" v-if="leilao.ultimo_lance">{{ leilao.ultimo_lance.valor | currency({ fractionCount: 2 }) }}</h2>
          <h2 style="text-align: center; width: 100%; height: 1.6em;" v-else>{{ leilao.valor_inicial | currency({ fractionCount: 2 }) }}</h2>
          <h2 style="text-align: center; width: 100%; font-size: 1.9em;"><i class="material-icons">timer</i>{{ tempo_restante }}</h2>
          <h4 style="text-align: center; width: 100%;" v-if="leilao.data_inicio"><i>Início: {{ leilao.data_inicio | formatDate('DD/MM/YYYY') }}</i> <i>Final: {{ leilao.data_fim | formatDate('DD/MM/YYYY') }}</i></h4>
        </template>
      </v-layout>
    </v-card-title>
    <v-footer absolute style="height: 4em" color="transparent">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div
            v-if="veiculo.endereco"
            style="justify-content: center; align-items: center; display: flex; font-size: 0.8em;"
            v-on="on"
          >
            <v-icon class="mr-1">location_on</v-icon> {{ veiculo.endereco }}
          </div>
        </template>
        <span>Localização</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div
            v-if="veiculo.data_avenda"
            style="
              justify-content: center; align-items: center; display: flex; font-size: 0.8em;
            "
            v-on="on"
          >
            <v-icon>calendar_month</v-icon>{{veiculo.data_avenda | formatDate('DD/MM/YYYY')}}
          </div>
        </template>
        <span>Data de Publicação</span>
      </v-tooltip>
    </v-footer>
  </v-card>
</template>

<script>
import moment from 'moment'
export default {
  props: ['veiculo', 'leilao'],
  data () {
    return {
      alterou: false,
      tempo_restante: '00d:00:00:00'
    }
  },
  methods: {
    getIcon (tipo) {
      if (tipo && typeof tipo === 'object') tipo = tipo.denominacao
      if (tipo === 'Passageiro' || tipo === 'Automóvel' || tipo === 'Outros') return 'airport_shuttle'
      else if (tipo === 'Caminhão') return 'local_shipping'
      else if (tipo === 'Caminhão trator') return 'agriculture'
      else if (tipo === 'Caminhonete') return 'agriculture'
      else if (tipo === 'Ciclomotor' || tipo === 'Motoneta' || tipo === 'Motocicleta' || tipo === 'Triciclo') return 'two_wheeler'
      else if (tipo === 'Micro-ônibus' || tipo === 'Ônibus') return 'directions_bus'
      return 'airport_shuttle'
    }
  },
  beforeMount () {
    if (this.leilao) {
      const interval = setInterval(() => {
        const inicio = moment(new Date())
        const dataFinalMoment = moment(this.leilao.data_fim)
        const diff = dataFinalMoment.diff(inicio, 'seconds')
        const dias = Math.floor(diff / 86400) // 86400 segundos em um dia
        const tempoRestante = moment.utc(diff * 1000).format('HH:mm:ss')
        if (dias === 0) {
          const tempoRestanteInt = Number(tempoRestante.replaceAll(':', ''))
          if (tempoRestanteInt <= 0) {
            this.$emit('carregar')
          }
          this.tempo_restante = `${tempoRestante}`
        } else {
          if (Number(dias) < 0) {
            this.$emit('carregar')
          }
          this.tempo_restante = `${dias}d:${tempoRestante}`
        }
        if (diff <= 0) {
          clearInterval(interval) // Encerre o intervalo
        }
      }, 1000)
    }
  }
}
</script>
