import settings from '@/settings'

const pegarFaturas = (headers, cnpj, filters) => {
  let url = `${settings.apetrusApi}/faturamentos/pegarfaturamentoempresas/?empresa=${cnpj}`

  Object.keys(filters).forEach(key => {
    url = `${url}&${key}=${filters[key]}`
  })

  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const inserirComprovante = (data, cnpj, headers) => {
  return fetch(`${settings.apetrusApi}/faturamentos/inserircomprovante/?empresa=${cnpj}`, {
    method: 'PATCH',
    mode: 'cors',
    body: JSON.stringify(data),
    headers: headers
  })
}

const info = (id, cnpj, headers) => {
  return fetch(`${settings.apetrusApi}/faturamentos/${id}/?empresa=${cnpj}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

export default {
  pegarFaturas,
  inserirComprovante,
  info
}
