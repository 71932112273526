import settings from '@/settings'

const allMethods = (headers, data) => {
  let url = `${settings.apetrusApi}/premios/`

  if (data.id) {
    url += `${data.id}/`
  }

  const parameter = {
    method: data.method,
    mode: 'cors',
    headers: headers
  }

  if (data.parameters) {
    Object.keys(data.parameters).forEach((filter) => {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${filter}=${data.parameters[filter]}`
    })
  }

  if (data.method === 'GET') {
    parameter.cache = 'default'
  } else if (data.body) parameter.body = JSON.stringify(data.body)

  return fetch(url, parameter)
}

export default {
  allMethods
}
