<template>
  <v-layout align-space-around justify-start fill-height column class="pt-5">
    <v-toolbar
      color="transparent"
      class="elevation-0 secondary--text"
      style="background: transparent">
      <v-toolbar-title>Minhas Empresas</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="dialogNovaEmpresa = true">
            <v-icon color="info">add</v-icon>
          </v-btn>
        </template>
        <span>
          Adicionar Empresa
        </span>
      </v-tooltip>
    </v-toolbar>
      <v-data-table
        hide-default-footer
        :headers="empresaHeaders"
        :items="empresas"
        :options.sync="pagination"
        no-data-text="Você ainda não possui empresa cadastrada"
        class="elevation-1"
      >
        <template v-slot:item="props">
          <tr>
            <td>{{ props.item.cnpj }}</td>
            <td>{{ props.item.nome_fantasia }}</td>
            <td>{{ props.item.razao_social }}</td>
            <td class="text-xs-right">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    color="primary"
                    @click="
                      empresaSelecionada = props.item,
                      dialogCadFilial = true
                    ">
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <span>Adicionar Filial</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon :color="props.item.is_empresa_parceira ? 'success' : 'primary'" @click="tornarParceira(props.item)">
                    <v-icon>handshake</v-icon>
                  </v-btn>
                </template>
                <span>{{props.item.is_empresa_parceira ? 'Editar anúncio' : 'Solicitar entrada em programa de Parceria' }}</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon color="primary" @click="detalharEmpresa(props.item)">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>Editar empresa</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    color="primary"
                    @click="
                      dialogVisualizar = true,
                      empresaSelecionada = props.item
                    "
                  >
                      <v-icon>visibility</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar dados da Empresa</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    <div class="text-xs-center">
      <v-pagination v-model="pagination.page" :length="pages" circle></v-pagination>
    </div>
    <v-snackbar
      v-model="snackbar.active"
      :color="snackbar.color"
      :multi-line="snackbar.multiLine"
      :timeout="snackbar.timeout"
      :vertical="snackbar.vertical"
    >
      {{ snackbar.text }}
      <v-btn
        text
        @click="snackbar.active = false"
      >
        Fechar
      </v-btn>
    </v-snackbar>
    <v-dialog v-if="dialogDetalhes" v-model="dialogDetalhes" width="600">
      <edit-nova-empresa @fechar="dialogDetalhes = false" @carregar="carregarEmpresas" :empresa="empresaSelecionada"/>
    </v-dialog>
    <v-dialog v-model="dialogParceiria" v-if="dialogParceiria" width="600">
      <cad-parceiria @fechar="dialogParceiria = false" @carregar="carregarEmpresas" :empresa="empresaSelecionada"/>
    </v-dialog>
    <v-dialog v-model="dialogNovaEmpresa" v-if="dialogNovaEmpresa" width="600" scrollable :fullscreen="$vuetify.breakpoint.xs">
      <cad-nova-empresa
        @fechar="dialogNovaEmpresa = false"
        @carregar="carregarEmpresas"
      />
    </v-dialog>
    <v-dialog v-model="dialogVisualizar" v-if="dialogVisualizar" width="35em" scrollable :fullscreen="$vuetify.breakpoint.xs">
      <visualizar-empresa
        @fechar="
          dialogVisualizar = false,
          empresaSelecionada = null
        "
        :empresa="empresaSelecionada"
      />
    </v-dialog>
    <v-dialog v-model="dialogCadFilial" v-if="dialogCadFilial" width="40em" scrollable :fullscreen="$vuetify.breakpoint.xs">
      <cad-filial
        @fechar="
          dialogCadFilial = false,
          empresaSelecionada = null
        "
        :empresa="empresaSelecionada"
      />
    </v-dialog>
  </v-layout>
</template>
<script>

import EditNovaEmpresa from '@/components/cadastros/EditNovaEmpresa.vue'
import CadParceiria from '@/components/cadastros/CadParceiria'
import CadNovaEmpresa from '@/components/cadastros/CadNovaEmpresa'
import CadFilial from '@/components/cadastros/CadFilial'
import VisualizarEmpresa from '@/components/dialogs/VisualizarEmpresa'

export default {
  components: { EditNovaEmpresa, CadParceiria, CadNovaEmpresa, VisualizarEmpresa, CadFilial },
  data () {
    return {
      dialogDetalhes: false,
      dialogParceiria: false,
      dialogNovaEmpresa: false,
      empresaSelecionada: null,
      dialogVisualizar: false,
      dialogCadFilial: false,
      totalItens: 0,
      empresas: [],
      snackbar: {
        active: false,
        color: '',
        multiLine: true,
        timeout: 4000,
        vertical: true,
        text: ''
      },
      pagination: {
        page: 1,
        rowsPerPage: 10
      },
      empresaHeaders: [
        {
          text: 'CNPJ',
          value: 'cnpj',
          sortable: false,
          align: 'left'
        },
        {
          text: 'Nome',
          align: 'left',
          sortable: false,
          value: 'nome_fantasia'
        },
        {
          text: 'Razão Social',
          value: 'razao_social',
          sortable: false,
          align: 'left'
        },
        {
          align: 'right',
          sortable: false
        }
      ]
    }
  },
  watch: {
    pagination: {
      handler (val, oldval) {
        if (this.headers) this.carregarEmpresas()
      },
      deep: true
    },
    headers (val) {
      if (val) this.carregarEmpresas()
    },
    todas_empresas (val) {
      if (this.headers) {
        this.carregarEmpresas()
      }
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    empresa () {
      return this.$store.getters.empresa
    },
    todas_empresas () {
      return this.$store.getters.empresas
    },
    pages () {
      const count = this.totalItens
      if (this.pagination.rowsPerPage == null || count == null) return 0

      return Math.ceil(count / this.pagination.rowsPerPage)
    }
  },
  beforeMount () {
    if (this.headers) {
      this.carregarEmpresas()
    }
  },
  methods: {
    detalharEmpresa (empresa) {
      this.empresaSelecionada = empresa
      this.dialogDetalhes = true
    },
    tornarParceira (empresa) {
      this.empresaSelecionada = empresa
      this.dialogParceiria = true
    },
    carregarEmpresas () {
      this.$store.dispatch('carregarEmpresasComPaginacao', {
        page: this.pagination.page,
        page_size: this.pagination.rowsPerPage
      }).then(json => {
        this.empresas = json.results
        this.totalItens = json.count
      })
    },
    showError (error) {
      this.snackbar.color = 'error'
      this.snackbar.text = error
      this.snackbar.active = true
    },
    showInfo (info) {
      this.snackbar.color = 'info'
      this.snackbar.text = info
      this.snackbar.active = true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
