<template>
  <v-card>
    <v-layout column class="pa-4">
      <h2 style="width: 100%; text-align: center;" class="mb-2">Transferência</h2>
      <v-form ref="form" v-model="valid">
        <v-container fluid grid-list-xl>
          <v-layout wrap align-center>
            <v-flex xs12 sm6 d-flex>
              <v-select :items="items" item-value="value" item-text="text" label="Transferir para" v-model="tipo" :rules="requiredRules"></v-select>
            </v-flex>
          <v-flex xs12 sm6 d-flex>
            <v-select :readonly='!tipo' :items="filtros" item-text="text" item-value="value" :rules="requiredRules" label="Pesquisar por" v-model="pesquisa">
            </v-select>
          </v-flex>
          <v-flex xs12 sm6 d-flex>
            <v-autocomplete :items="destinatarios" :item-text="item_text" :item-value="item_value" :rules="requiredRules" label="Destinatário" return-object v-model="destinatario">
            </v-autocomplete>
          </v-flex>
          <v-flex>
            <v-text-field label="Valor de retirada" v-model.lazy="valor" prefix="R$" v-money="money"></v-text-field>
          </v-flex>
          </v-layout>
          <v-layout row>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="!valid || valor_decimal <= 0 || valor_decimal > parseFloat(saldo)" @click="transferirSaldo">Transferir</v-btn>
          </v-layout>
        </v-container>
      </v-form>
    </v-layout>
  </v-card>
</template>

<script>

import { VMoney } from 'v-money'

export default {
  props: ['saldo'],
  directives: { money: VMoney },
  data () {
    return {
      funcionarios: '',
      empresas: '',
      destinatario: '',
      destinatarios: '',
      isPosto: false,
      valor: 0.00,
      valor_decimal: 0.00,
      item_text: '',
      item_value: '',
      valid: false,
      items: [
        { value: 'colaborador', text: 'Colaborador' },
        { value: 'empresa', text: 'Empresa' },
        { value: 'posto', text: 'Posto' }
      ],
      tipo: '',
      mask: '',
      rules: '',
      pesquisa: '',
      filtros: [],
      requiredRules: [
        v => !!v || 'Este campo é obrigatório'
      ],
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false
      }
    }
  },
  watch: {
    tipo (value) {
      if (value === 'colaborador') {
        this.isPosto = false
        this.getFuncionariosEmpresaConfirmadosSemPaginacao()
        this.destinatarios = ''
        this.destinatarios = [...this.funcionarios]
        this.rules = [
          v => !!v || 'Selecione quem vai receber a transferência de saldo'
        ]
        this.filtros = [
          { value: 'nome_email', text: 'Nome' },
          { value: 'cpf', text: 'CPF' }
        ]
      } else {
        if (value === 'posto') {
          this.isPosto = true
          this.rules = [
            v => !!v || 'Selecione o posto que vai receber a transferência de saldo'
          ]
          this.destinatarios = ''
          this.destinatarios = [...this.postos]
        } else if (value === 'empresa') {
          this.isPosto = false
          this.rules = [
            v => !!v || 'Selecione a empresa que vai receber a transferência de saldo'
          ]
          this.destinatarios = ''
          this.destinatarios = [...this.empresas]
        }
        this.filtros = [
          { value: 'cnpj', text: 'CNPJ' },
          { value: 'nome_fantasia', text: 'Nome Fantasia' }
        ]
      }
    },
    valor (value) {
      this.valor_decimal = value
      this.valor_decimal = this.valor_decimal.replaceAll('.', '')
      this.valor_decimal = this.valor_decimal.replace(',', '.')
    },
    pesquisa (value) {
      this.item_text = value
    }
  },
  methods: {
    transferirSaldo () {
      this.$refs.form.validate()
      if (this.valid) {
        this.$emit('transferirSaldo', { is_posto: this.isPosto, cpf: this.destinatario.cpf, valor: this.valor_decimal, cnpj_receptor: this.destinatario.cnpj, cnpj_pagador: this.empresa.cnpj })
      }
    },
    getFuncionariosEmpresaConfirmadosSemPaginacao () {
      this.$store.dispatch('getFuncionariosEmpresaConfirmadosSemPaginacao', this.empresa.cnpj).then((json) => {
        this.funcionarios = json
      })
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    empresa () {
      return this.$store.getters.empresa
    },
    loading () {
      return this.$store.getters.loading
    },
    postos () {
      return this.$store.getters.postos
    }
  },
  beforeMount () {
    this.getFuncionariosEmpresaConfirmadosSemPaginacao()
    this.$store.dispatch('pegarPostos')
    this.$store.dispatch('pegarEmpresasAtivas').then(response => {
      this.empresas = response
    })
  },
  mounted () {
    this.destinatarios = [...this.funcionarios]
  }
}
</script>
