<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || empresa.tipo_empresa !== 2)"/>
  <v-container grid-list-md fluid class="pt-5" v-else>
    <v-toolbar color="transparent" class="elevation-0 secondary--text">
      <v-toolbar-title class="text-h5">Repasse de Veículos</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small fab color="info" @click="showFilter = !showFilter">
            <v-icon>{{ !showFilter ? 'filter_list' : 'filter_list_off' }}</v-icon>
          </v-btn>
        </template>
        <span>{{!showFilter ? 'Mostrar filtros' : 'Recolher filtros'}}</span>
      </v-tooltip>
    </v-toolbar>
    <v-slide-x-transition>
      <v-card v-show="showFilter" class="ml-4 mr-4">
        <v-card-text>
          <v-layout wrap justify-space-between>
            <v-flex xs10 md11>
              <v-layout wrap>
                <v-flex xs6 sm6 md4>
                  <v-select
                    label="Tipo de Veículos"
                    :items="tipos_veiculo"
                    v-model="tipo_veiculo"
                    item-text="denominacao"
                    item-value="id_tipo_veiculo"
                    clearable
                  ></v-select>
                </v-flex>
                <v-flex xs6 sm6 md4>
                  <v-text-field label="Marca" v-model="marca"></v-text-field>
                </v-flex>
                <v-flex xs6 sm6 md4>
                  <v-text-field label="Modelo" v-model="modelo"></v-text-field>
                </v-flex>
                <v-flex xs6 sm6 md4>
                  <v-select
                    label="Estado"
                    :items="estados"
                    v-model="estado"
                    clearable
                  ></v-select>
                </v-flex>
                <v-flex xs6 sm6 md4>
                  <v-text-field label="Cidade" v-model="cidade"></v-text-field>
                </v-flex>
                <v-flex xs6 sm6 md4>
                  <v-text-field
                    label="CNPJ da empresa"
                    v-model="cnpj"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2 md1 align-self-center>
              <v-btn
                fab
                color="info"
                @click="function () {
                  pegarLeilao(true)
                  pegarVeiculos(true)
                }"
              >
                <v-icon>search</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-slide-x-transition>
    <h2 class="text-center" v-if="leiloes && leiloes.length > 0">Veículos Leiloados</h2>
    <v-layout row wrap justify-center class="mt-4" v-if="!loading">
      <v-layout column class="ma-4" v-if="leiloes && leiloes.length > 0">
        <v-layout align-start justify-start row wrap fill-height style="min-height: 18em;">
          <v-flex xs12 sm6 md4 lg4 xl3 v-for="(item, index) in leiloes" :key="index" class="mb-3">
            <card-veiculo
              :veiculo="item.veiculo_leiloado"
              :leilao="item"
              @visualizar="abrirVisualizar(item)"
              @carregar="function () {
                pegarLeilao(true)
                pegarVeiculos(true)
              }"
            />
          </v-flex>
        </v-layout>
        <v-pagination v-model="pagination.page" :length="pages" class="mb-4"></v-pagination>
      </v-layout>
    </v-layout>
    <h2 class="text-center" v-if="veiculos && veiculos.length > 0">Veículos a Venda</h2>
    <v-layout row wrap justify-center class="mt-4" v-if="veiculos && veiculos.length > 0">
      <v-layout column class="ma-4">
        <v-layout align-start justify-start row wrap fill-height style="min-height: 18em;">
          <v-flex xs12 sm6 md4 lg4 xl3 v-for="(item, index) in veiculos" :key="`${index}-veiculo`" class="mb-3">
            <card-veiculo :veiculo="item" @visualizar="function () {
              leilaoSelecionado = undefined
              veiculoSelecionado = item
              visualizar = true
            }"
            @carregar="function () {
              pegarLeilao(true)
              pegarVeiculos(true)
            }"
            />
          </v-flex>
        </v-layout>
        <v-pagination v-model="paginationVeiculos.page" :length="pagesVeiculos" class="mb-4"></v-pagination>
      </v-layout>
    </v-layout>
    <v-dialog v-model="visualizar" v-if="visualizar" scrollable width="650" fullscreen>
      <visualizar-veiculo
        :veiculo="leilaoSelecionado ? leilaoSelecionado.veiculo_leiloado : veiculoSelecionado"
        :leilao="leilaoSelecionado"
        :mostrarBotao="true"
        @fechar="visualizar = false, leilaoSelecionado = undefined, veiculoSelecionado = undefined"
        @carregar="function () {
          pegarLeilao(true)
          pegarVeiculos(true)
        }"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import CardVeiculo from '@/components/Veiculo'
import VisualizarVeiculo from '@/components/dialogs/VisualizarVeiculo'
import AvisoBloqueio from '@/components/AvisoBloqueio'

export default {
  components: { CardVeiculo, VisualizarVeiculo, AvisoBloqueio },
  data () {
    return {
      visualizar: false,
      leilaoSelecionado: undefined,
      veiculoSelecionado: undefined,
      showFilter: false,
      leiloes: [],
      totalItens: 0,
      pagination: {
        rowsPerPage: 8,
        page: 1
      },
      paginationVeiculos: {
        rowsPerPage: 8,
        page: 1
      },
      estado: undefined,
      cidade: undefined,
      cnpj: undefined,
      modelo: undefined,
      marca: undefined,
      tipo_veiculo: undefined,
      tipos_veiculo: [],
      veiculos: [],
      totalItensVeiculo: 0
    }
  },
  mounted () {
    if (!!this.empresa && !!this.headers) {
      this.pegarLeilao(false)
      this.pegarVeiculos(false)
    }
  },
  watch: {
    pagination: {
      handler (val, oldval) {
        if (this.empresa) {
          this.pegarLeilao(false)
        }
      },
      deep: true
    },
    paginationVeiculos: {
      handler (val, oldval) {
        if (this.empresa) {
          this.pegarVeiculos(false)
        }
      },
      deep: true
    },
    showFilter (val) {
      if (val) this.pegarTipoVeiculo()
    },
    empresa: {
      handler (val, oldval) {
        if (val && val !== oldval) {
          this.pegarLeilao(false)
          this.pegarVeiculos(false)
        }
      },
      deep: true
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    empresa () {
      return this.$store.getters.empresa
    },
    pages () {
      const count = this.totalItens
      if (this.pagination.rowsPerPage == null || count == null) return 0

      return Math.ceil(count / this.pagination.rowsPerPage)
    },
    pagesVeiculos () {
      const count = this.totalItensVeiculo
      if (this.paginationVeiculos.rowsPerPage == null || count == null) return 0

      return Math.ceil(count / this.paginationVeiculos.rowsPerPage)
    },
    tela () {
      return Number(window.innerWidth)
    },
    estados () {
      return this.$store.getters.estados
    },
    loading () {
      return this.$store.getters.loading
    }
  },
  methods: {
    pegarTipoVeiculo () {
      this.$store.dispatch('listarTipoVeiculo').then(json => {
        this.tipos_veiculo = json
      })
    },
    abrirVisualizar (leilao) {
      this.veiculoSelecionado = undefined
      this.leilaoSelecionado = leilao
      this.visualizar = true
    },
    pegarLeilao () {
      const agoraRecife = new Date().toLocaleString('pt-BR', { timeZone: 'America/Recife' })
      const dataSeparada = agoraRecife.split(', ')
      const dataFormatada = dataSeparada[0].split('/').reverse().join('-')
      const dataFormatoISO = `${dataFormatada}T${dataSeparada[1]}`
      const data = {
        method: 'GET',
        parametros: {
          status__in: [1, 4],
          data_inicio__lte: dataFormatoISO,
          data_fim__gte: dataFormatoISO,
          empresa_logada: this.empresa.cnpj,
          page: this.pagination.page,
          page_size: this.pagination.rowsPerPage,
          veiculo_leiloado__aVenda: true,
          cliente__isnull: true
        }
      }
      if (this.showFilter) {
        if (this.tipo_veiculo) data.parametros.veiculo_leiloado__tipo_veiculo = this.tipo_veiculo
        if (this.modelo) data.parametros.veiculo_leiloado__modelo__icontains = this.modelo
        if (this.marca) data.parametros.veiculo_leiloado__marca__icontains = this.marca
        else if (this.cnpj) data.parametros.empresa__cnpj = this.cnpj
        if (this.cidade) data.parametros.empresa__endereco__cidade__iexact = this.cidade
        if (this.estado) data.parametros.empresa__endereco__estado__iexact = this.estado
      }
      this.$store.dispatch('leilaoVeiculos', data).then(json => {
        this.leiloes = json.results
        this.totalItens = json.count
      })
    },
    pegarVeiculos (isBtn) {
      const data = {
        aVenda: true,
        cnpj: this.empresa.cnpj,
        naoMostrar: true, // Não permite mostrar os veículos da empresa logada
        destinatario_oferta: 2,
        empresa__isnull: false,
        page_size: this.paginationVeiculos.rowsPerPage,
        page: isBtn ? 1 : this.paginationVeiculos.page
      }
      if (this.showFilter) {
        if (this.tipo_veiculo) data.tipo_veiculo = this.tipo_veiculo
        if (this.modelo) data.modelo__icontains = this.modelo
        if (this.marca) data.marca__icontains = this.marca
        else if (this.cnpj) data.empresa__cnpj = this.cnpj
        if (this.cidade) data.empresa__endereco__cidade__iexact = this.cidade
        if (this.estado) data.empresa__endereco__estado__iexact = this.estado
      }
      this.$store.dispatch('listarVeiculosAVenda', data).then(json => {
        this.veiculos = json.results
        this.totalItensVeiculo = json.count
      })
    }
  },
  beforeMount () {
    if (this.$vuetify.breakpoint.xl) {
      this.pagination.rowsPerPage = 4
      this.paginationVeiculos.rowsPerPage = 4
    } else if (this.$vuetify.breakpoint.lg) {
      this.pagination.rowsPerPage = 3
      this.paginationVeiculos.rowsPerPage = 3
    } else if (this.$vuetify.breakpoint.md) {
      this.pagination.rowsPerPage = 3
      this.paginationVeiculos.rowsPerPage = 3
    } else if (this.$vuetify.breakpoint.sm) {
      this.pagination.rowsPerPage = 2
      this.paginationVeiculos.rowsPerPage = 2
    } else if (this.$vuetify.breakpoint.xs) {
      this.pagination.rowsPerPage = 1
      this.paginationVeiculos.rowsPerPage = 1
    }
  }
}
</script>
