<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || ![4, 6].includes(empresa.tipo_empresa))"/>
  <v-container grid-list-md fluid v-else>
    <v-layout column>
      <v-flex>
        <v-toolbar color="#eee" class="elevation-0 secondary--text mb-4" style="background: transparent">
          <v-layout justify-space-between wrap>
            <v-flex xs12 sm4 md3>
              <v-toolbar-title>
                MEUS PRODUTOS
              </v-toolbar-title>
            </v-flex>
            <v-flex
              xs12 sm7 md5 lg5
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon color="secondary" @click="promocao = true">
                    <v-icon color="info">sell</v-icon>
                  </v-btn>
                </template>
                <span>Cadastrar nova promoção</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon color="secondary" @click="codigo = true">
                    <v-icon color="info">qr_code_2</v-icon>
                  </v-btn>
                </template>
                <span>Código promocional</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon color="secondary" @click="dialogCadItem = true">
                    <v-icon color="info">add</v-icon>
                  </v-btn>
                </template>
                <span>Cadastrar novo Item</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </v-toolbar>
      </v-flex>
    </v-layout>
    <v-layout row class="card-row">
      <v-flex
        align-self-center
        style="
          justify-content: start;
          align-items: center;
          display: flex;
        "
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              @click="pagination.page--"
              v-on="on"
              :disabled="pagination.page == 1"
            >
              <v-icon size="40">arrow_back_ios</v-icon>
            </v-btn>
          </template>
          <span>Ir para esquerda</span>
        </v-tooltip>
      </v-flex>
      <v-layout
        column
        v-for="(objeto, index) in itens"
        :key="index"
      >
        <item
          v-on:error="showError"
          :item="objeto"
          @editarItem="editarItem"
          @visualizarItem="visualizarItem"
          @deletarItem="abrirDialogConfirmacao"
          @editarDisponibilidade="editarDisponibilidade"
          @carregar="pegarItens"
        />
      </v-layout>
      <v-flex
        align-self-center
        style="
          justify-content: end;
          align-items: center;
          display: flex;
        "
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              @click="pagination.page++"
              v-on="on"
              :disabled="pagination.totalItems <= pagination.page * visibleCards"
            >
              <v-icon size="40">arrow_forward_ios</v-icon>
            </v-btn>
          </template>
          <span>Ir para Direita</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
    <v-dialog v-if="promocao" v-model="promocao"  max-width="500">
      <cad-promocao
        @fechar="promocao = false"
        @showMessage="showInfo"
        @error="showError"
        @carregar="pegarItens"
      />
    </v-dialog>
    <v-dialog v-if="codigo" v-model="codigo"  max-width="600">
      <listar-codigos @fechar="codigo = false"/>
    </v-dialog>
    <v-dialog v-if="dialogTipoDesconto" v-model="dialogTipoDesconto"  max-width="500">
      <tipo-desconto
        @fechar="dialogTipoDesconto = false"
        @success="showInfo"
        @error="showError"
        @carregar="pegarItens"
      />
    </v-dialog>
    <v-dialog v-if="dialogCadItem" v-model="dialogCadItem"  width="65em" :fullscreen="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
      <cad-item
        @fechar="dialogCadItem = false, itemSelecionado = undefined"
        @success="showInfo"
        @error="showError"
        @carregar="pegarItens"
        :itemSelecionado="itemSelecionado"
      />
    </v-dialog>
    <v-dialog v-if="dialogDetalhesItem" v-model="dialogDetalhesItem"  width="65em" :fullscreen="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
      <detalhes-item
        :item="itemSelecionado"
        @fechar="
          dialogDetalhesItem = false,
          itemSelecionado = undefined
        "
      />
    </v-dialog>
    <v-dialog
      v-if="dialogConfirmacaoExclusao"
      v-model="dialogConfirmacaoExclusao"
      width="55em"
    >
      <confirmacao-exclusao
        @confirmado="deletarItem"
        @fechar="dialogConfirmacaoExclusao = false, itemSelecionado = undefined"
      >
        <template v-slot:principal>
          <h2
            v-if="itemSelecionado"
            class="error--text text-xs-center mb-2"
          >
            Tem certeza que você deseja excluir {{ itemSelecionado.nome }}?
          </h2>
        </template>
      </confirmacao-exclusao>
    </v-dialog>
    <v-dialog
      v-if="dialogDisponibilidadeItem"
      v-model="dialogDisponibilidadeItem"
      width="45em"
    >
      <item-disponibilidade
        :item="itemSelecionado"
        @fechar="
          dialogDisponibilidadeItem = false,
          itemSelecionado = undefined
        "
        @success="showInfo"
        @error="showError"
        @carregar="pegarItens"
      />
    </v-dialog>
    <v-snackbar
      v-model="snackbarerror.active"
      :color="snackbarerror.color"
      :multi-line="snackbarerror.multiLine"
      :timeout="snackbarerror.timeout"
      :vertical="snackbarerror.vertical"
    >
      {{ snackbarerror.errorText }}
      <v-btn
        text
        @click="snackbarerror.active = false"
      >
        Fechar
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import Item from '@/components/Item'
import CadPromocao from '@/components/cadastros/CadPromocao'
import CadItem from '@/components/cadastros/CadItem'
import ListarCodigos from '@/components/cadastros/CodigosPromocionais'
import DetalhesItem from '@/components/dialogs/DetalhesItem'
import ItemDisponibilidade from '@/components/dialogs/ItemDisponibilidade.vue'
import ConfirmacaoExclusao from '@/components/dialogs/ConfirmacaoExclusao.vue'
import AvisoBloqueio from '@/components/AvisoBloqueio'

export default {
  components: {
    CadPromocao,
    ListarCodigos,
    Item,
    CadItem,
    DetalhesItem,
    ItemDisponibilidade,
    ConfirmacaoExclusao,
    AvisoBloqueio
  },
  data () {
    return {
      lagura_tela: 0,
      visibleCards: 1,
      dialogCadItem: false,
      dialogDetalhesItem: false,
      dialogDisponibilidadeItem: false,
      dialogConfirmacaoExclusao: false,
      itens: [],
      dialogTipoDesconto: false,
      codigo: false,
      promocao: false,
      snackbarerror: {
        active: false,
        color: 'error',
        multiLine: true,
        timeout: 4000,
        vertical: true,
        errorText: ''
      },
      pagination: {
        page: 1,
        totalItems: 0
      },
      itemSelecionado: undefined
    }
  },
  watch: {
    empresa (val) {
      if (val) {
        this.pegarItens()
      }
    },
    tela (val) {
      this.ajustarTela(val)
      this.pagination.page = 1
      this.pegarItens()
    },
    'pagination.page': {
      handler (val) {
        if (this.empresa) this.pegarItens()
      },
      deep: true
    }
  },
  beforeMount () {
    this.ajustarTela(this.$vuetify.breakpoint)
    if (this.empresa) {
      this.pegarItens()
    }
  },
  computed: {
    tela () {
      return this.$vuetify.breakpoint
    },
    empresa () {
      return this.$store.getters.empresa
    },
    loading () {
      return this.$store.getters.loading
    },
    pages () {
      if (this.visibleCards == null ||
        this.pagination.totalItems == null
      ) return 0

      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
    }
  },
  methods: {
    editarItem (item) {
      this.itemSelecionado = item
      this.dialogCadItem = true
    },
    visualizarItem (item) {
      this.itemSelecionado = item
      this.dialogDetalhesItem = true
    },
    editarDisponibilidade (item) {
      this.itemSelecionado = item
      this.dialogDisponibilidadeItem = true
    },
    abrirDialogConfirmacao (item) {
      this.itemSelecionado = item
      this.dialogConfirmacaoExclusao = true
    },
    deletarItem () {
      const data = {
        method: 'DELETE',
        body: {
          empresa: this.empresa.cnpj
        },
        id: this.itemSelecionado.id
      }
      this.$store.dispatch('itens', data).then(() => {
        this.dialogConfirmacaoExclusao = false
        this.pagination.page = 1
        this.itemSelecionado = undefined
        this.pegarItens()
        this.showInfo('Item deletado com sucesso.')
      })
    },
    ajustarTela (val) {
      if (val) {
        if (val.xs) this.visibleCards = 1
        else if (val.sm) this.visibleCards = 1
        else if (val.md) this.visibleCards = 2
        else if (val.lg) this.visibleCards = 2
        else if (val.xl) this.visibleCards = 3
      }
    },
    showInfo (text) {
      this.snackbarerror.color = 'info'
      this.snackbarerror.errorText = text
      this.snackbarerror.active = true
    },
    showError (error) {
      this.snackbarerror.color = 'error'
      this.snackbarerror.errorText = error
      this.snackbarerror.active = true
    },
    toggle (index) {
      alert(index)
    },
    pegarItens () {
      const data = {
        method: 'GET',
        parameters: {
          empresa: this.empresa.cnpj,
          empresa__cnpj: this.empresa.cnpj,
          page: this.pagination.page,
          page_size: this.visibleCards,
          is_conveniencia: false,
          combustivel__isnull: true,
          produtos: true
        }
      }
      this.$store.dispatch('itens', data).then(json => {
        this.itens = json.results
        this.pagination.totalItems = json.count
      })
    }
  }
}
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}

</style>
