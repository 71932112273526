<template>
  <v-card max-width="500">
    <v-card-title>
      Questionário de validação
    </v-card-title>
    <v-card-subtitle>
      São só algumas perguntinhas para verificar sua identidade
    </v-card-subtitle>
    <v-card-text>
      <template v-if="loading">
        <v-layout fill-height justify-center align-center>
          <v-progress-circular indeterminate size="100" color="primary"></v-progress-circular>
        </v-layout>
      </template>
      <template v-else>
        <v-form ref="form" v-model="valid">
        <template v-for="(respostas, pergunta, index) in questionario.questionario">
          <v-radio-group :rules="rules" required v-model="radios[index]" :key="index">
            <template v-slot:label>
              <div>{{ index+1 }}) {{ pergunta }}</div>
            </template>
            <v-radio v-for="resposta in respostas" :key="resposta" :value="resposta">
              <template v-slot:label>
                <div>{{ resposta }}</div>
              </template>
            </v-radio>
          </v-radio-group>
        </template>
        <v-btn block color="success" @click="validar">
          Validar
        </v-btn>
      </v-form>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['edicao'],
  data () {
    return {
      questionario: '',
      radios: [],
      valid: false,
      rules: [
        v => !!v || 'Preenchimento necessário'
      ]
    }
  },
  methods: {
    pegarQuestionario () {
      this.$store.dispatch('pegarQuestionario', { data_nascimento: this.edicao.data_nascimento }).then((json) => {
        this.questionario = json
      })
    },
    enviarQuestionario () {
      this.$store.dispatch('enviarQuestionario', { resposta: this.radios, ticketid: this.questionario.ticketid })
      this.$emit('close')
    },
    validar () {
      this.$refs.form.validate()
      if (this.valid) {
        this.enviarQuestionario()
      }
    }
  },
  computed: {
    loading () {
      return this.$store.getters.loading
    }
  },
  mounted () {
    this.pegarQuestionario()
  }
}
</script>

<style>

</style>
