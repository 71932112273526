<template>
   <v-card>
      <v-card-title class="justify-center">
        <h2>OPÇÕES DE FINANCIAMENTO</h2>
      </v-card-title>
      <v-alert v-show="temError" type="error" class="mt-4 mb-1 pa-3">
        <h4>{{mensagemError}}</h4>
      </v-alert>
        <v-card-text>
          <v-form lazy-validation v-model="validFinanciamento" ref="formFinanciamento" class="mt-2">
            <v-flex xs6 v-if="this.veiculo.valor" class="mb-4">
                Valor do veículo: <b>{{this.veiculo.valor | currency({ fractionCount: 2 })}}</b>
            </v-flex>
            <v-layout wrap justify-space-between>
              <v-flex xs5>
                <v-text-field
                  label="Entrada"
                  outlined
                  v-model.lazy="entrada"
                  prefix="R$"
                  v-money="money"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  label="Parcela (opcional)"
                  outlined
                  v-model.lazy="parcela"
                  prefix="R$"
                  v-money="money"
                />
              </v-flex>
              <v-flex xs5>
                <v-select
                    v-if="financiadoras.includes(financiadora) || financiadora == null"
                    outlined
                    :items="financiadorasTotal"
                    label="Financiadora"
                    v-model.lazy.trim="financiadora"
                  />
                <v-text-field
                  v-else
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                  label="Financiadora"
                  outlined
                  v-model.trim="financiadora"
                  type="text"
                />
              </v-flex>
              <v-flex xs3>
                <v-text-field
                  label="Número de Parcelas"
                  outlined
                  v-model="numeroParcelas"
                  type="number"
                  :rules="[
                    (v) => !!v || 'Esse campo é obrigatório',
                    (v) => v >= 0 || 'Valor inválido'
                  ]"
                />
              </v-flex>
              <v-flex xs2>
                <v-text-field
                  label="Juros"
                  outlined
                  v-model="juros"
                  type="number"
                  prefix="%"
                  :rules="[
                    (v) => !!v || 'Esse campo é obrigatório',
                    (v) => v >= 0 || 'Valor inválido'
                  ]"
                />
              </v-flex>
              <v-container>
              <v-row v-for="(key,index) in campos_dinamicos" :key="index">
                  <template v-if="campos_dinamicos[index].type === 'Texto'">
                    <v-col cols="10">
                      <v-text-field
                        :label="campos_dinamicos[index].label"
                        outlined
                        type="text"
                        v-model.trim="campos_dinamicos[index].value"
                        :rules="[
                        (v) => !!v || 'Esse campo é obrigatório'

                      ]"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-btn icon color="red" class="mt-3" @click="deletarParametro(index)">
                        <v-icon>remove</v-icon>
                      </v-btn>
                    </v-col>
                  </template>
                  <template v-if="campos_dinamicos[index].type === 'Percentual'">
                    <v-col cols="10">
                      <v-text-field
                        :label="campos_dinamicos[index].label"
                        outlined
                        v-model.trim="campos_dinamicos[index].value"
                        prefix="%"
                        :rules="[
                        (v) => !!v || 'Esse campo é obrigatório'

                      ]"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-btn icon color="red" class="mt-3" @click="deletarParametro(index)">
                        <v-icon>remove</v-icon>
                      </v-btn>
                    </v-col>
                  </template>
                  <template v-if="campos_dinamicos[index].type === 'Valor financeiro'">
                    <v-col cols="10">
                      <v-text-field
                        v-money="money"
                        :label="campos_dinamicos[index].label"
                        outlined
                        prefix="R$"
                        v-model="campos_dinamicos[index].value"
                        :rules="[
                        (v) => !!v || 'Esse campo é obrigatório'

                      ]"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-btn icon color="red" class="mt-3" @click="deletarParametro(index)">
                        <v-icon>remove</v-icon>
                      </v-btn>
                    </v-col>
                  </template>
              </v-row>
            </v-container>
            </v-layout>
            <v-flex xs12>
              <h3 class="text-center">Para adicionar novas informações</h3>
            </v-flex>
            <v-layout class="mt-4" justify-space-around>
              <v-flex xs5>
                <v-text-field label="Título da nova informação" outlined v-model.lazy.trim="novoParametro[0]" />
              </v-flex>
              <v-flex xs5>
                <v-select
                    outlined
                    :items="['Texto','Percentual','Valor financeiro']"
                    label="Tipo da nova informação"
                    v-model.lazy.trim="novoParametro[2]"
                  ></v-select>
              </v-flex>
              <v-flex xs1 class="pl-5">
                <v-btn icon color="info" class="mt-3" @click="adicionarParametro()">
                  <v-icon>add</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-space-between>
              <v-flex xs12>
                <v-divider class="mt-5"></v-divider>
                <v-list>
                  <template v-for="(item, index) in opcoesFinanciamento">
                    <v-divider :key="`divider-${index}`" v-if="index > 0"></v-divider>
                    <v-list-item :key="index">
                      <v-list-item-icon>
                        <v-icon class="mt-2" color="info">paid</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-subtitle>Número de Parcelas: <b>{{item.numero_parcelas}}</b></v-list-item-subtitle>
                        <v-list-item-subtitle v-if="parcela != '0,00'">Parcelas: <b>{{item.parcela  | currency({ fractionCount: 2 }) }}</b></v-list-item-subtitle>
                        <v-list-item-subtitle>Entrada: <b>{{item.entrada  | currency({ fractionCount: 2 }) }}</b></v-list-item-subtitle>
                        <template v-for="(item2, index2) in item.campos_dinamicos">
                          <v-list-item-subtitle :key="index2" v-if="item2.type == 'Texto'">{{ item2.label}}: <b>{{ item2.value }}</b></v-list-item-subtitle>
                          <v-list-item-subtitle :key="index2" v-if="item2.type == 'Percentual'">{{ item2.label}}: <b>{{ item2.value }}%</b></v-list-item-subtitle>
                          <v-list-item-subtitle :key="index2" v-if="item2.type == 'Valor financeiro'">{{ item2.label}}: <b>{{ item2.value | currency({ fractionCount: 2 }) }}</b></v-list-item-subtitle>
                        </template>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-subtitle>Juros: <b>{{item.juros}}%</b></v-list-item-subtitle>
                        <v-list-item-subtitle>Financiadora: <b>{{item.financiadora  }}</b></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn @click="deletarOpcaoFinanciamento(item.id)" color="error" icon>
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
              </v-flex>
            </v-layout>
          </v-form>
      </v-card-text>
      <v-card-actions class="justify-space-around">
        <v-flex xs6>
          <v-btn
            @click="$emit('fechar')"
            block
            text
          >
            Fechar
          </v-btn>
        </v-flex>
        <v-flex xs6>
          <v-btn
            color="primary"
            block
            @click="adicionarOpcaoFinanciamento"
            text
          >
            Adicionar financiamento
          </v-btn>
        </v-flex>
      </v-card-actions>
  </v-card>
</template>

<script>
import { VMoney } from 'v-money'
export default {
  props: ['veiculo'],
  directives: { money: VMoney },
  data: () => ({
    financiadorasTotal: ['Banco Itaú', 'Banco BV', 'Banco do Brasil', 'Porto Seguro', 'Bradesco', 'Santander', 'Caixa', 'Creditas', 'Outra'],
    financiadoras: ['Banco Itaú', 'Banco BV', 'Banco do Brasil', 'Porto Seguro', 'Bradesco', 'Santander', 'Caixa', 'Creditas'],
    validFinanciamento: false,
    opcoesFinanciamento: [],
    juros: 1,
    numeroParcelas: 1,
    entrada: '0,00',
    parcela: '0,00',
    financiadora: null,
    campos_dinamicos: {},
    novoParametro: [' ', ' ', 'Texto'],
    funcionarioSelected: '',
    temError: false,
    mensagemError: '',
    money: {
      decimal: ',',
      thousands: '.',
      precision: 2,
      masked: false
    }
  }),
  methods: {
    adicionarParametro () {
      const keys = Object.keys(this.campos_dinamicos)
      const existe = !!keys.find((item) => item === this.novoParametro[0])
      if (existe) {
        this.showError('Você já adicionou um campo com esse nome.')
        return undefined
      }
      if (this.novoParametro[0].trim() === '') {
        this.showError('Você deve inserir um nome para o campo Título da nova informação')
        return undefined
      }
      const toCamelCase = str => str.trim().replace(/[-_\s]+(.)?/g, (_, c) => c ? c.toUpperCase() : '')
      this.$set(this.campos_dinamicos, toCamelCase(this.novoParametro[0]), { label: this.novoParametro[0], value: '', type: this.novoParametro[2] })
    }, // adicionarParametro
    deletarParametro (item) {
      this.$delete(this.campos_dinamicos, item)
    }, // deletarParametro
    showError (msg) {
      this.temError = true
      this.mensagemError = msg
      setTimeout(() => {
        this.temError = false
        this.mensagemError = ''
      }, 5000)
    }, // showError
    adicionarOpcaoFinanciamento () {
      const existe = !!this.opcoesFinanciamento.find((item) => item.numeroParcelas === this.numeroParcelas)
      if (this.$refs.formFinanciamento.validate()) {
        if (existe) {
          this.showError('Esse opção já foi inserida.')
          return undefined
        } else {
          for (const [key, value] of Object.entries(this.campos_dinamicos)) {
            if (value.type === 'Valor financeiro') {
              this.campos_dinamicos[key].value = Number(value.value.replaceAll('.', '').replace(',', '.'))
            }
          }
          const data = {
            method: 'POST',
            cnpj: this.empresa.cnpj,
            modelo: this.veiculo.modelo,
            marca: this.veiculo.marca,
            ano_fabricacao: this.veiculo.ano_fabricacao,
            juros: this.juros,
            numero_parcelas: this.numeroParcelas,
            entrada: Number(this.entrada.replaceAll('.', '').replace(',', '.')),
            parcela: Number(this.parcela.replaceAll('.', '').replace(',', '.')),
            financiadora: this.financiadora,
            campos_dinamicos: this.campos_dinamicos
          }
          this.$store.dispatch('opcoesFinanciamento', data).then(() => {
            this.pegarOpfinanciamento()
          })
        }
      } else {
        this.showError('Para cadastrar uma opção de financimento todos os campos precisam está preenchidos.')
      }
    }, // adicionarOpcaoFinanciamento
    deletarOpcaoFinanciamento (id) {
      const data = {
        method: 'DELETE',
        id_financiamento: id,
        cnpj: this.empresa.cnpj
      }
      this.pegarOpfinanciamento()
      this.$store.dispatch('opcoesFinanciamento', data).then(() => {
        this.pegarOpfinanciamento()
      })
    }, // deletarOpcaoFinanciamento
    pegarOpfinanciamento () {
      const data = {
        method: 'GET',
        cnpj: this.empresa.cnpj,
        modelo: this.veiculo.modelo,
        marca: this.veiculo.marca,
        ano_fabricacao: this.veiculo.ano_fabricacao
      }
      this.$store.dispatch('opcoesFinanciamento', data).then(json => {
        this.opcoesFinanciamento = json
      })
    } // pegarOpfinanciamento
  },
  beforeMount () {
    if (this.veiculo) {
      this.pegarOpfinanciamento()
    }
  },
  computed: {
    empresa () {
      return this.$store.getters.empresa
    }
  }
}
</script>
