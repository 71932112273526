<template>
  <v-card>
    <v-card-title
      class="justify-center"
    >
      <h2>INSERIR MEIO DE RECEBIMENTO</h2>
    </v-card-title>
    <v-card-text>
      <v-stepper v-model="step" class="elevation-0">
        <v-stepper-items>
          <v-stepper-content step="1" class="pa-0">
            <v-container flex grid-list-lg>
              <v-form v-model="valid1" ref="firstForm">
                <v-layout justify-center row wrap>
                  <v-flex
                    xs12
                    class="text-center"
                  >Selecione uma das formas abaixo</v-flex>
                  <v-flex xs12 md8 class="pa-0 ma-0">
                    <v-select
                      v-model="pagamento"
                      outlined
                      placeholder="Clique para selecionar"
                      single-line
                      :items="formasPag"
                      item-text="descricao"
                      required
                      return-object
                      hide-details
                      :rules="[
                          v => !!v || ''
                      ]"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
          </v-stepper-content>
          <v-stepper-content step="2" class="pa-0">
            <v-container flex grid-list-lg>
              <v-form v-model="valid2" ref="secondForm">
                <v-layout justify-center row wrap v-if="!loading">
                  <v-flex
                    xs12
                    class="title primary--text text-xs-center"
                  >Insira os dados para os seguintes campos</v-flex>
                  <v-flex
                    xs11
                    class="secondary--text text-xs-center"
                    v-if="pagamento_secundario"
                  >ATENÇÃO! Esta forma de pagamento é secundária, usada para recebimento pix e carteira digital pela empresa.</v-flex>
                  <template v-for="(field, index) in fields">
                    <v-flex :key="index" xs12 md10 v-if="field === 'chave_pix'">
                    <v-select
                      label="Tipo de chave"
                      :items="[
                        'CNPJ',
                        'Número de Telefone',
                        'Chave Aleatória'
                      ]"
                      v-model="tipo_chave"
                    ></v-select>
                      <v-text-field
                        :label="field"
                        v-model="pagamento_model.formulario[field]"
                        hide-details
                        required
                        :mask="
                        tipo_chave === 'CNPJ' ? '##.###.###/####-##' : (tipo_chave === 'Número de Telefone' ? '(##) # ####-####' : '')
                        "
                        :rules="[
                          v => !!v || ''
                        ]"
                      ></v-text-field>
                    </v-flex>
                    <v-flex :key="index" xs12 md10 v-else>
                      <v-text-field
                        :label="field"
                        v-model="pagamento_model.formulario[field]"
                        hide-details
                        required
                        :rules="[
                          v => !!v || ''
                        ]"
                      ></v-text-field>
                    </v-flex>
                  </template>
                </v-layout>
                <v-layout justify-center class="ma-4" v-else>
                  <v-progress-circular
                    size="100"
                    color="info"
                    indeterminate
                  ></v-progress-circular>
                </v-layout>
              </v-form>
            </v-container>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-card-actions class="justify-space-around">
      <v-flex xs6>
        <v-btn
          block
          text
          color="grey darken-3"
          @click="cancelar()"
        >
          CANCELAR
        </v-btn>
      </v-flex>
      <v-divider vertical></v-divider>
      <v-flex xs6>
        <v-btn
          block
          text
          color="green"
          :disabled="disabledBtn"
          @click="continuar()"
          :loading="loading"
        >
          <template v-if="step === 1">CONTINUAR</template>
          <template v-else-if="step === 2">CONFIRMAR</template>
        </v-btn>
      </v-flex>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  data () {
    return {
      loading: false,
      tipo_chave: 'CNPJ',
      step: 1,
      valid1: false,
      valid2: false,
      fields: [],
      pagamentosExtra: [],
      pagamento: null,
      pagamento_secundario: null,
      formasPag: [],
      pagamento_model: {
        empresa: null,
        formulario: {},
        aceitar_credito: true,
        aceitar_debito: true,
        posto: null,
        is_admin: false
      }
    }
  },
  computed: {
    disabledBtn () {
      switch (this.step) {
        case 1:
          return !this.pagamento
      }
      return false
    },
    headers () {
      return this.$store.getters.headers
    },
    empresa () {
      return this.$store.getters.empresa
    }
  },
  watch: {
    headers (val) {
      if (val) {
        this.carregarFormasPagamento()
      }
    },
    pagamento (val) {
      if (val) {
        this.fields = val.nome_campos.split(',')
        this.pagamento_secundario = val.pagamento_secundario
      }
    }
  },
  beforeMount () {
    if (this.empresa) {
      this.carregarFormasPagamento()
    }
  },
  methods: {
    carregarFormasPagamento () {
      this.$store.dispatch('carregarFormasPagamento').then(json => {
        this.formasPag = json
      })
    },
    continuar () {
      switch (this.step) {
        case 1:
          if (this.$refs.firstForm.validate()) {
            this.step = 2
          }
          break
        case 2:
          if (this.$refs.secondForm.validate()) {
            this.vincularPagamento()
          }
          break
      }
    },
    vincularPagamento () {
      this.loading = true
      if (this.tipo_chave === 'Número de Telefone') this.pagamento_model.formulario.chave_pix = `+55${this.pagamento_model.formulario.chave_pix}`
      this.$store.dispatch(
        'vincularPagamentoExtra',
        {
          body: {
            ...this.pagamento_model,
            empresa: this.empresa.cnpj
          },
          pagamento: this.pagamento.id_forma_pagamento
        }
      ).then(() => {
        this.loading = false
        this.$emit(
          'showSnackbar',
          'Forma de pagamento foi vinculada com sucesso!',
          'primary'
        )
      }).catch(() => {
        this.$emit(
          'showSnackbar',
          'Infelizmente a forma de pagamento não pôde ser inserida!',
          'error'
        )
      })
      this.cancelar()
    },
    cancelar () {
      this.$refs.firstForm.reset()
      this.$refs.secondForm.reset()
      this.step = 1
      this.$emit('fechar')
    }
  }
}
</script>
