<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || empresa.tipo_empresa !== 2)"/>
  <v-layout align-space-around justify-start fill-height column class="pt-5" v-else>
    <v-toolbar
      color="transparent"
      class="elevation-0 secondary--text"
      style="background: transparent">
      <v-toolbar-title>Meus Leilões</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small fab color="info" @click="showFilter = !showFilter">
            <v-icon>{{ !showFilter ? 'filter_list' : 'filter_list_off' }}</v-icon>
          </v-btn>
        </template>
        <span>{{!showFilter ? 'Mostrar filtros' : 'Recolher filtros'}}</span>
      </v-tooltip>
    </v-toolbar>
    <v-slide-x-transition>
      <v-card v-show="showFilter" class="ml-4 mr-4">
        <v-card-text>
          <v-layout wrap justify-space-between>
            <v-flex xs10 md11>
              <v-layout wrap justify-space-around>
                <v-flex xs11 sm5 md3 lg2 xl2>
                  <v-text-field
                    label="Código do veículo"
                    v-model="id_veiculo"
                  ></v-text-field>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-select
                    label="Status"
                    :items="
                      [
                        { text: 'Aberto', value: 1 },
                        { text: 'Encerrado', value: 2 },
                        { text: 'Cancelado', value: 3 },
                        { text: 'Em andamento', value: 4 },
                        { text: 'Concluído', value: 5 }
                      ]
                    "
                    v-model="status"
                    item-text="text"
                    item-value="value"
                    clearable
                  ></v-select>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-select
                    label="Responsável"
                    :items="
                      [
                        { text: 'Você', value: 1 },
                        { text: 'Cliente', value: 2 },
                        { text: 'Empresa', value: 3 }
                      ]
                    "
                    v-model="responsavel"
                    item-text="text"
                    item-value="value"
                    clearable
                  ></v-select>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-select
                      :label="`${selectOpcoes} de início`"
                      :items="options"
                      item-text="title"
                      item-value="value"
                      v-model="selectOpcoes"
                    >
                    </v-select>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-menu
                    ref="menuDataInicial"
                    v-model="menuDataInicial"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-combobox
                        v-if="opcoes.dia"
                        v-model="opcoes.data_relatorio"
                        :label="'Data de Início'"
                        persistent-hint
                        chips
                        small-chips
                        readonly
                        flat
                        @blur="date = parseDate(opcoes.data_relatorio)"
                        prepend-icon="event"
                        required
                        v-on="on"
                        clearable
                      ></v-combobox>
                      <v-combobox
                        v-else
                        v-model="opcoes.mes_relatorio"
                        :label="'Mês de início'"
                        persistent-hint
                        chips
                        small-chips
                        readonly
                        flat
                        @blur="month = parseDate(opcoes.mes_relatorio)"
                        prepend-icon="event"
                        required
                        v-on="on"
                        clearable
                      ></v-combobox>
                    </template>
                    <v-date-picker v-show="opcoes.dia" v-model="date" type="date" no-title @input="dataSelecionada" locale="pt-br"></v-date-picker>
                    <v-date-picker v-show="opcoes.mes" v-model="month" type="month" no-title @input="dataSelecionada" locale="pt-br"></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2 md1 align-self-center>
              <v-btn fab small color="info" @click="pegarLeiloes(true)">
                <v-icon>search</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-slide-x-transition>
      <v-data-table
        hide-default-footer
        :headers="[
          {
            sortable: false
          },
          {
            text: 'Veículo',
            value: 'veiculo_leiloado',
            sortable: false
          },
          {
            text: 'Responsável',
            value: 'empresa',
            sortable: false
          },
          {
            text: 'Data de início',
            align: 'left',
            sortable: false,
            value: 'data_inicio'
          },
          {
            text: 'Data de encerramento',
            align: 'left',
            sortable: false,
            value: 'data_fim'
          },
          {
            text: 'Quantidade de lances',
            align: 'center',
            sortable: false,
            value: 'qtd_lances'
          },
          {
            text: 'Status',
            value: 'status',
            sortable: false,
            align: 'left'
          },
          {
            align: 'right',
            sortable: false
          }
        ]"
        :items="leiloes"
        :options.sync="pagination"
        no-data-text="Você ainda não possui leilões cadastrados"
        class="elevation-1"
      >
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-left">
              <v-icon>gavel</v-icon>
            </td>
            <td class="text-xs-left">
              <template v-if="props.item.veiculo_leiloado.modelo && props.item.veiculo_leiloado.marca">
                {{props.item.veiculo_leiloado.modelo.split(' ')[0] || ''}}/{{props.item.veiculo_leiloado.marca}}
              </template>
            </td>
            <td class="text-xs-left">
              <template v-if="props.item.empresa && empresa">
                {{ props.item.empresa.cnpj == empresa.cnpj ? 'Você' : substituirPorAsteriscos(props.item.empresa.nome_fantasia, props.item) }}
              </template>
              <template v-if="props.item.cliente">
                {{ substituirPorAsteriscos(props.item.cliente.pessoa.nome_completo, props.item) }}
              </template>
            </td>
            <td>
              {{ props.item.data_inicio | formatDate('DD/MM/YYYY')  }}
            </td>
            <td>{{ props.item.data_fim | formatDate('DD/MM/YYYY') }}</td>
            <td class="text-center">
              {{  props.item.qtd_lances }}
            </td>
            <td class="text-xs-left">
              <v-tooltip bottom v-if="props.item.status==3">
                <template v-slot:activator="{ on }">
                  <v-icon color="red" dark v-on="on">cancel</v-icon>
                </template>
                <span>Cancelado</span>
              </v-tooltip>
              <v-tooltip bottom v-else-if="props.item.status==5">
                <template v-slot:activator="{ on }">
                  <v-icon color="teal" dark v-on="on">check_circle</v-icon>
                </template>
                <span>Concluído</span>
              </v-tooltip>
              <v-tooltip bottom v-else-if="props.item.status==2 || verificarEncerramento(props.item.data_fim)">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">timer_off</v-icon>
                </template>
                <span>Enderrado</span>
              </v-tooltip>
              <v-tooltip bottom v-else-if="props.item.status==1">
                <template v-slot:activator="{ on }">
                  <v-icon color="primary" dark v-on="on">timer</v-icon>
                </template>
                <span>Em Aberto</span>
              </v-tooltip>
              <v-tooltip bottom v-else-if="props.item.status==4">
                <template v-slot:activator="{ on }">
                  <v-icon color="primary" dark v-on="on">change_circle</v-icon>
                </template>
                <span>Em andamento</span>
              </v-tooltip>
            </td>
            <td class="text-xs-right">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    icon
                    color="primary"
                    @click="
                      leilaoSelecionado = props.item,
                      dialogVisualizarLeilao = true
                    "
                  >
                    <v-icon>remove_red_eye</v-icon>
                  </v-btn>
                </template>
                <span>Detalhes da disputa de lances</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    icon
                    color="info"
                    :disabled="props.item.status === 5 || props.item.status === 3 || props.item.status === 2 || (props.item.empresa && empresa && props.item.empresa.cnpj !== empresa.cnpj)"
                    @click="
                      leilaoSelecionado = props.item,
                      dialogEditarLeilao = true
                    "
                  >
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>Editar disputa de lances</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    icon
                    color="red"
                    :disabled="props.item.status === 3 || props.item.status === 5 || (props.item.empresa && empresa && props.item.empresa.cnpj !== empresa.cnpj)"
                    @click="
                      leilaoSelecionado = props.item,
                      dialogExcluirLeilao = true
                    "
                  >
                    <v-icon>cancel</v-icon>
                  </v-btn>
                </template>
                <span>Excluir disputa de lances</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    <div class="text-xs-center">
      <v-pagination v-model="pagination.page" :length="pages" circle></v-pagination>
    </div>
    <v-dialog
      v-model="dialogVisualizarLeilao"
      v-if="dialogVisualizarLeilao"
      width="55em"
      scrollable
    >
      <visualizar-leilao
        @fechar="
          dialogVisualizarLeilao = false,
          leilaoSelecionado = undefined
        "
        @carregar="pegarLeiloes(false)"
        :leilaoSelecionado="leilaoSelecionado"
        />
    </v-dialog>
    <v-dialog v-model="dialogExcluirLeilao" width="500">
      <v-card>
        <v-card-title class="justify-center error--text" style="font-size: 1.3em;">
          Você deseja excluir essa disputa de lances?
        </v-card-title>
        <v-card-actions class="justify-space-around">
          <v-btn rounded class="mb-3" @click="dialogExcluirLeilao = false">Não</v-btn>
          <v-btn rounded class="mb-3" @click="excluirLeilao" color="error">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditarLeilao" v-if="dialogEditarLeilao" width="50em">
      <dialog-editar-leilao
        @fechar="dialogEditarLeilao = false, leilaoSelecionado = undefined"
        :leilaoSelecionado="leilaoSelecionado"
        @carregar="pegarLeiloes(false)"
      />
    </v-dialog>
  </v-layout>
</template>
<script>
import VisualizarLeilao from '@/components/dialogs/VisualizarLeilao'
import DialogEditarLeilao from '@/components/dialogs/EditarLeilao'
import moment from 'moment'
import AvisoBloqueio from '@/components/AvisoBloqueio'
export default {
  components: { VisualizarLeilao, AvisoBloqueio, DialogEditarLeilao },
  data () {
    return {
      date: moment().format('YYYY-MM-DD'),
      month: new Date().toISOString().substr(0, 10),
      dialogEditarLeilao: false,
      dialogVisualizarLeilao: false,
      dialogExcluirLeilao: false,
      leilaoSelecionado: undefined,
      menuDataInicial: false,
      showFilter: false,
      status: undefined,
      data_inicia: undefined,
      id_veiculo: undefined,
      data_encerramento: undefined,
      responsavel: undefined,
      totalItens: 0,
      dates: [],
      leiloes: [],
      pagination: {
        page: 1,
        rowsPerPage: 5
      },
      options: [
        {
          title: 'Dia',
          value: 'dia'
        },
        {
          title: 'Mês',
          value: 'mes'
        }
      ],
      selectOpcoes: 'dia',
      opcoes: {
        dia: true,
        mes: false,
        data_relatorio: moment().format('DD/MM/YYYY'),
        mes_relatorio: null,
        intervalo_relatorio: [],
        confirmadas: true
      }
    }
  },
  watch: {
    empresa (val, oldval) {
      if (val !== null && val !== undefined && val !== oldval) {
        this.pegarLeiloes(false)
      }
    },
    pagination: {
      handler (val, oldval) {
        if (this.empresa) this.pegarLeiloes(false)
      },
      deep: true
    },
    date (val) {
      if (val) {
        this.opcoes.data_relatorio = this.formatDate(this.date)
      }
    },
    month (val) {
      if (val) {
        this.opcoes.mes_relatorio = this.formatDate(this.month)
      }
    },
    selectOpcoes (val) {
      this.limparOpcoes()
      if (val === 'dia') {
        this.opcoes.dia = true
        this.opcoes.data_relatorio = this.formatDate(this.date)
      } else if (val === 'mes') {
        this.opcoes.mes = true
        this.opcoes.mes_relatorio = this.formatDate(this.month)
      }
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    empresa () {
      return this.$store.getters.empresa
    },
    pages () {
      const count = this.totalItens
      if (this.pagination.rowsPerPage == null || count == null) return 0

      return Math.ceil(count / this.pagination.rowsPerPage)
    }
  },
  mounted () {
    if (this.empresa) {
      this.pegarLeiloes(false)
    }
  },
  methods: {
    limparOpcoes () {
      this.opcoes.dia = false
      this.opcoes.mes = false
    },
    verificarEncerramento (data) {
      const agoraRecife = new Date().toLocaleString('pt-BR', { timeZone: 'America/Recife' })
      const dataSeparada = agoraRecife.split(', ')
      const dataFormatada = dataSeparada[0].split('/').reverse().join('-')
      const dataFormatoISO = `${dataFormatada}T${dataSeparada[1]}`
      return new Date(dataFormatoISO) >= new Date(data)
    },
    excluirLeilao () {
      if (!this.leilaoSelecionado) this.$store.dispatch('setError', 'disputa de lances não encontrada.')
      this.$store.dispatch('leilaoVeiculos', {
        method: 'DELETE',
        id: this.leilaoSelecionado.id,
        parametros: {
          empresa: this.empresa.cnpj
        }
      }).then(() => {
        this.pegarLeiloes(false)
        this.dialogExcluirLeilao = false
      })
    },
    dataSelecionada () {
      this.menuDataInicial = false
    },
    formatDate (date) {
      if (!date) return null

      if (this.opcoes.dia) {
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      } else {
        const [year, month] = date.split('-')
        return `${month}/${year}`
      }
    },
    parseDate (date) {
      if (!date) return null

      if (this.opcoes.dia) {
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      } else {
        const [month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}`
      }
    },
    orderDates () {
      this.dates.sort((a, b) => {
        a = new Date(a.split('-'))
        b = new Date(b.split('-'))
        return a < b ? -1 : a > b ? 1 : 0
      })
    },
    substituirPorAsteriscos (texto, leilao) {
      if (!texto) return ''
      if (leilao.status === 5) return texto
      // Calcula quantos caracteres devem ser substituídos
      const numCaracteresSubstituir = Math.floor(texto.length * 0.7)

      // Gera uma string com asteriscos
      const asteriscos = '*'.repeat(numCaracteresSubstituir)

      // Concatena os asteriscos com o restante da string original
      const resultado = asteriscos + texto.slice(numCaracteresSubstituir)

      return resultado
    },
    pegarLeiloes (isBtn) {
      const data = {
        method: 'GET',
        parametros: {
          page: isBtn ? 1 : this.pagination.page,
          page_size: this.pagination.rowsPerPage
        }
      }
      if (this.showFilter) {
        if (this.opcoes.dia && this.date) {
          data.parametros.data_inicio__gte = this.date + 'T00:00:00'
          data.parametros.data_inicio__lte = this.date + 'T23:59:59'
        } else if (this.opcoes.mes && this.month && this.month.length) {
          const initialDate = new Date(`${this.month.split('-')[1]}-01-${this.month.split('-')[0]}`.split('-').join('/'))
          const finalDate = new Date(initialDate.getFullYear(), initialDate.getMonth() + 1, 0)
          data.parametros.data_inicio__gte = initialDate.toISOString().substr(0, 10) + 'T00:00:00'
          data.parametros.data_inicio__lte = finalDate.toISOString().substr(0, 10) + 'T00:00:00'
        }
        if (this.status) data.parametros.status = this.status
        if (this.id_veiculo) data.parametros.veiculo_leiloado = this.id_veiculo
        if (this.responsavel === 2) {
          data.parametros.cliente__isnull = false
          data.parametros.responsavel_lance = this.empresa.cnpj
        } else if (this.responsavel === 3) {
          data.parametros.empresa_logada = false
          data.parametros.responsavel_lance = this.empresa.cnpj
        } else if (this.responsavel === 1) {
          data.parametros.empresa = this.empresa.cnpj
        } else {
          data.parametros.responsavel_lance = this.empresa.cnpj
          data.parametros.empresa = this.empresa.cnpj
          data.parametros.responsavel = this.empresa.cnpj
        }
      } else {
        data.parametros.responsavel_lance = this.empresa.cnpj
        data.parametros.empresa = this.empresa.cnpj
        data.parametros.responsavel = this.empresa.cnpj
      }
      this.$store.dispatch('leilaoVeiculos', data).then(json => {
        this.leiloes = json.results
        this.totalItens = json.count
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
