<template>
  <v-form>
    <v-card>
      <v-card-text
        class="pt-4"
        style="position: relative;"
      >
        <v-container>
          <v-layout v-if="loading" align-center justify-center column >
            <v-progress-circular
              size="24"
              color="info"
              indeterminate
            ></v-progress-circular>
          </v-layout>
          <template v-else-if="frentista_model.pessoa">
              <v-layout align-center justify-center column >
                <v-avatar
                  color="grey"
                  size="60">
                    <img v-if="frentista_model.pessoa.url_imagem_perfil" :src="frentista_model.pessoa.url_imagem_perfil" alt="trevor">
                    <v-icon dark v-else>face</v-icon>
                </v-avatar>
              </v-layout>
              <v-layout align-center justify-center column >
                <v-card-title  class="text-xs-center">
                  <v-layout column align-center>
                    <div class="headline">{{ frentista_model.pessoa.nome }}</div>
                    <div>{{ frentista_model.pessoa.email }}</div>
                    <div>Tel: {{ frentista_model.pessoa.telefone }}</div>
                  </v-layout>
                </v-card-title>
              </v-layout>
          </template>
          <template v-else>
            <v-layout align-center justify-center column >
              <v-fade-transition leave-absolute>
                <v-avatar
                  color="transparent"
                  size="120">
                  <img :src="require('@/assets/logo.png')" alt="trevor">
                </v-avatar>
              </v-fade-transition>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="frentista_model.email"
                  outline
                  clearable
                  label="Email do funcionário"
                  type="text"
                >
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="frentista_model.telefone"
                  outline
                  clearable
                  label="Telefone"
                  type="text"
                  mask="(##) # ####-####"
                >
                </v-text-field>
                <h4
                  v-if="verificacaoTelefone.cadastrado"
                  class="error--text text-xs-center"
                >
                  Esse Telefone já foi cadastrado
                </h4>
              </v-flex>
            </v-layout>
          </template>
        </v-container>
      </v-card-text>
      <v-card-actions color="red" class="accent">
          <v-spacer></v-spacer>
          <v-btn v-if="loading" disabled text dark>
          Aguarde o processamento ...
          </v-btn>
          <v-btn v-else-if="frentista_model.pessoa" text dark @click.prevent="() => conectarFrentista()">
              Conectar
          </v-btn>
          <v-btn
            v-else
              text
              dark
              @click.prevent="() => cadastrarAcessoFrentista()"
              :disabled="verificacaoTelefone.cadastrado"
            >
              Cadastrar
          </v-btn>
          <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>

export default {
  props: ['funcionario'],
  data () {
    return {
      testingThereIsPersonWithCPF: true,
      valid: true,
      frentista_model: {
        email: '',
        telefone: '',
        pessoa: null
      },
      verificacaoTelefone: {
        cadastrado: false
      }
    }
  },
  computed: {
    loading () {
      return this.testingThereIsPersonWithCPF
    },
    empresa () {
      return this.$store.getters.empresa
    }
  },
  methods: {
    cadastrarAcessoFrentista () {
      this.$store.dispatch('cadastrarUsuarioFrentista', { ...this.frentista_model, id_frentista: this.funcionario.id_frentista }).then((json) => {
        this.$emit('complete')
      }).catch((e) => {
        alert(e.message)
      })
    },
    conectarFrentista () {
      this.$store.dispatch('editarFrentista', {
        id_frentista: this.funcionario.id_frentista,
        empresa: this.empresa.cnpj,
        id_pessoa: this.frentista_model.pessoa.id_pessoa
      }).then(() => {
        this.$emit('complete')
      }).catch((e) => {
        alert(e.message)
      })
    },
    verificarTelefone () {
      this.$store.dispatch('verificarTelefone', {
        id_pessoa: this.frentista_model.pessoa ? this.frentista_model.pessoa.id_pessoa : null,
        email: this.frentista_model.email,
        telefone: this.frentista_model.telefone.slice(3, 11)
      }).then(json => {
        this.verificacaoTelefone = json
      })
    }
  },
  watch: {
    'frentista_model.telefone' (val) {
      if (val && val.length === 11) {
        this.verificarTelefone()
      } else {
        this.verificacaoTelefone.cadastrado = false
      }
    },
    'frentista_model.email' (val) {
      if (val && this.frentista_model.telefone && this.frentista_model.telefone.length === 11) {
        this.verificarTelefone()
      } else {
        this.verificacaoTelefone.cadastrado = false
      }
    }
  },
  mounted () {
    this.$store.dispatch('consultarFrentistaCPF', this.funcionario.cpf.replace(/\D/g, '')).then((json) => {
      this.frentista_model.pessoa = json
      this.testingThereIsPersonWithCPF = false
    }).catch(() => {
      this.testingThereIsPersonWithCPF = false
    })
  }
}
</script>
