<template>
  <v-dialog
    persistent
    v-model="visible"
    width="500"
    :fullscreen="$vuetify.breakpoint.xs"
  >
    <template v-if="visible">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1"
            >Forma de pagamento</v-stepper-step
          >

          <v-divider></v-divider>

          <v-stepper-step :complete="step > 2" step="2">Pagar</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">Confirmação</v-stepper-step>
        </v-stepper-header>
        <v-toolbar color="green" dark text>
          <v-toolbar-title>Pagamento</v-toolbar-title>
          <v-spacer></v-spacer>
          {{ negociacao.posto ? negociacao.posto.nome_fantasia : negociacao.rede.nome }}
        </v-toolbar>
        <v-stepper-items>
          <v-stepper-content step="1" class="pa-0">
            <v-card v-if="negociacao">
              <v-container fluid grid-list-md>
               <v-flex v-if="negociacao.status_pagamento"
                xs8
                class="text-xs-center subheading green--text font-weight-regular"
              >
                Pagamento da proposta já foi realizado!
              </v-flex>
                <v-slide-y-transition>
                  <v-form
                    ref="formTipoPagamento"
                    v-model="valid"
                    lazy-validation
                    v-show="!termosAceitos"
                  >
                    <v-layout row wrap>
                      <v-flex xs12>
                        <h3 class="grey--text">
                          Valor Negociado: {{ negociacao.valor }}
                        </h3>
                      </v-flex>
                      <v-select
                        v-model="select"
                        :items="items"
                        label="Forma de pagamento"
                        required
                      ></v-select>
                    </v-layout>
                  </v-form>
                </v-slide-y-transition>
                <v-slide-y-transition>
                  <div>
                    <v-card class="mx-auto" max-width="450" outlined>
                      <v-layout
                        row
                        wrap
                        v-show="termosAceitos"
                        align-center
                        justify-center
                      >
                        <v-list-item-title class="headline mb-1">
                        </v-list-item-title>
                        <v-flex
                          v-if="select == 'Pix'"
                          xs8
                          class="text-xs-center headline gray--text font-weight-medium"
                        >
                          {{ negociacao.chave_pix }}</v-flex
                        >
                        <v-flex
                          v-if="select == 'Boleto'"
                          xs8
                          class="text-xs-center headline gray--text font-weight-medium"
                        >
                          {{ negociacao.num_boleto }}</v-flex
                        >
                         <v-flex
                          v-if="select == 'Transferência bancária'"
                          xs8
                          class="text-xs-center headline gray--text font-weight-medium"
                        >
                          {{ negociacao.dados_bancarios }}</v-flex
                        >
                      </v-layout>
                    </v-card>
                  </div>
                </v-slide-y-transition>
              </v-container>
              <v-card-actions>
                <v-btn color="grey darken-1" text @click="fechar()"
                  >Voltar</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn v-if="!negociacao.status_pagamento" color="green darken-1" text @click="passarTela()">{{
                  btnNome
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="2" >
            <v-card class="mx-auto">
              <v-container fluid grid-list-md>
                <v-slide-y-transition>
                  <v-layout
                        row
                        wrap
                        align-center
                        justify-center
                  >
                   <v-flex
                     v-if="select == 'Cartão'"
                        class="text-xs-center headline gray--text font-weight-medium"
                        >

                          <v-form
                            ref="formCreditCard"
                            v-model="valid"
                            lazy-validation>
                            <v-layout row wrap>
                              <v-row>
                              <v-col cols="15" md="12">
                                <v-select
                                  :items="desserts.final"
                                  :rules="[rules.required]"
                                  item-text="nome"
                                  label="Final do cartão"
                                  item-value="id_cartao"
                                  v-model="selectCartao"
                                  required
                              ></v-select>
                              </v-col>
                              <v-col cols="15" md="12">
                              <v-text-field
                                v-model="cvv"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.min]"
                                :type="show1 ? 'text' : 'password'"
                                name="input-10-1"
                                label="CVV"
                                hint="Pelo menos 3 caracteres"
                                counter
                                @click:append="show1 = !show1"
                              ></v-text-field>
                              </v-col>
                              </v-row>
                            </v-layout>
                          </v-form>
                        </v-flex>
                    <v-flex
                        row
                        wrap
                        align-center
                        justify-center v-else>
                      <template
                        v-if="
                          !negociacao.comprovante_pagamento ||
                          imagemAnexada ||
                          arquivoAnexado
                        "
                      >
                        <div>
                          <img
                            v-if="imagemAnexada"
                            :src="imagemAnexada"
                            alt="imagem do comprovante"
                            width="300"
                            @click="$refs.imageInput.click()"
                          />
                          <object
                            v-if="arquivoAnexado"
                            width="300"
                            height="300"
                            type="application/pdf"
                            :data="arquivoAnexado"
                            @click="$refs.imageInput.click()"
                          ></object>
                          <v-card
                            v-if="!imagemAnexada && !arquivoAnexado"
                            color="grey"
                            width="300"
                            height="300"
                            @click="$refs.imageInput.click()"
                          >
                            <v-layout justify-center align-center fill-height>
                              <div
                                class="title white--text pa-4 text-xs-center"
                              >
                                Clique para adicionar Comprovante
                              </div>
                            </v-layout>
                          </v-card>
                        </div>
                      </template>
                      <template v-else>
                        <div v-if="!imagemAnexada && !arquivoAnexado">
                          <object
                            v-if="
                              this.negociacao.comprovante_pagamento.indexOf(
                                'png'
                              ) === -1
                            "
                            width="400"
                            height="400"
                            type="application/pdf"
                            :data="fatura.comprovante_pagamento"
                            @click="$refs.imageInput.click()"
                          ></object>
                          <img
                            v-else
                            alt="imagem do comprovante"
                            :src="this.fatura.comprovante_pagamento"
                            width="300"
                            @click="$refs.imageInput.click()"
                          />
                        </div>
                      </template>

                      <input
                        type="file"
                        style="display: none"
                        accept="image/pdf*"
                        ref="imageInput"
                        @input="anexar"
                      />
                    </v-flex>
                  </v-layout>
                </v-slide-y-transition>
              </v-container>
              <v-divider></v-divider>
               <v-card-actions v-if="select == 'Cartão'">
                <v-btn color="red darken-1" text @click="fechar()"
                  >Cancelar</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="passarTela()">Pagar</v-btn>
              </v-card-actions>
              <v-card-actions v-else>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="!imagemAnexada && !arquivoAnexado"
                  class="mb-3"
                  color="primary"
                  @click="$refs.imageInput.click()"
                  >Anexar</v-btn
                >
                <v-btn v-else class="mb-3" color="primary" @click="inserir()"
                  >Confirmar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="3" class="pa-0">
            <v-card>
              <v-container fluid grid-list-md>
                <v-slide-y-transition>
                  <v-layout column v-if="select == 'Cartão'">
                    <div v-if="proceed === true">
                      <v-flex
                      xs8
                      class="text-xs-center subheading red--text font-weight-regular"
                    >
                      Pagamento Realizado com sucesso
                    </v-flex>
                    </div>
                  </v-layout>
                  <v-layout column v-else>
                    <v-flex
                      xs8
                      class="text-xs-center subheading red--text font-weight-regular"
                    >
                      Aguarde o posto aprovar o pagamento
                    </v-flex>
                  </v-layout>
                </v-slide-y-transition>
              </v-container>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  color="red darken-1"
                  text
                  :loading="loading"
                  @click="fechar()"
                  >Fechar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </v-dialog>
</template>

<script>
import { pagamentoDao } from '@/api'

export default {
  props: ['visible', 'negociacao', 'propostasDao'],
  data () {
    return {
      seletedFormaPagamento: null,
      pagamentoDinheiro: false,
      arquivoAnexado: null,
      imagemAnexada: null,
      btnNome: 'Realizar Pagamento',
      termosAceitos: false,
      step: 1,
      loading: false,
      formPagamento: false,
      soma: 0,
      valid: false,
      teste: '',
      select: null,
      validade: null,
      items: [
        'Boleto',
        'Pix',
        'Transferência bancária',
        'Cartão'
      ],
      selectCartao: null,
      cvv: null,
      cartao: {
        titular: null,
        numero_cartao: null,
        validade_cartao: null,
        cvv_cartao: null,
        bandeira_card: null,
        credito: true
      },
      data: {},
      infoPgto: {
        url_pagamento: null,
        id_compra: null,
        user_agent: null,
        nome: null,
        email: null,
        cpf: null,
        data_nascimento: null
      },
      endereco_cobranca: {
        cep: null,
        rua: null,
        num: null,
        complemento: null,
        cidade: null,
        estado: null,
        pais: null,
        bairro: null
      },
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 3 || 'Min 3 caracteres'
      },
      show1: false,
      proceed: false,
      pagination: {
        page: 1,
        rowsPerPage: 5
      }
    }
  },
  created () {
    this.initialize()
  },
  mounted () {
    this.$store.dispatch('carregarTiposPagamento')
    if (this.empresa.administrador) {
      this.carregarDados()
    }
    // this.litros_total = this.negociacao.litros
  },
  computed: {
    cliente () {
      return this.$store.getters.cliente
    },
    tipos_pagamento () {
      return this.$store.getters.tipos_pagamento
    },
    empresa () {
      return this.$store.getters.empresa
    },
    headers () {
      return this.$store.getters.headers
    },
    hash () {
      return this.$store.getters.hash
    },
    cartaoId () {
      return this.$store.getters.cartao
    },
    infoCompra () {
      let cnpj
      let idRede
      if (this.negociacao.posto) {
        cnpj = this.negociacao.posto.cnpj
      } else {
        idRede = this.negociacao.rede.id_rede
      }
      return {
        empresa: this.empresa.cnpj,
        cnpj_posto: cnpj,
        id_rede: idRede,
        // MerchantId: this.negociacao.posto.MerchantId,
        // MerchantKey: this.negociacao.posto.MerchantKey,
        id: this.negociacao.id,
        valor_compra: this.negociacao.valor
      }
    },
    infoCompraFinalizar () {
      return {
        url: this.infoPgto.url_pagamento,
        id_compra: this.infoPgto.id_compra,
        id_cartao: this.cartaoId,
        cvv: this.cvv,
        cartao: this.hash,
        user_agent: this.infoPgto.user_agent
      }
    },
    card_hash () {
      return {
        titular: {
          nome: this.cartao.titular
        },
        endereco_cobranca: this.endereco_cobranca,
        numero_cartao: this.cartao.numero_cartao,
        cvv: this.cartao.cvv_cartao,
        mes_expiracao: this.validade[0],
        ano_expiracao: this.validade[1],
        debito: !this.cartao.credito,
        credito: this.cartao.credito
      }
    },
    cartoes () {
      return this.$store.getters.cartoes
    },
    pessoa () {
      return this.$store.getters.pessoa
    }
  },
  methods: {
    voltarTela () {
      switch (this.step) {
        case 2:
          this.step = 1
          this.tipo_cartao_selecionado = false
          this.pagamentoDinheiro = false
      }
    },
    clearFields () {
      this.tipo_cartao_selecionado = false
      this.datatemp = null
      this.pagamentoDinheiro = false
      this.termosAceitos = false
      this.$refs.formTipoPagamento.reset()
      this.soma = null
      this.arquivoAnexado = null
      this.imagemAnexada = null
    },
    passarTela () {
      switch (this.step) {
        case 1:
          if (this.$refs.formTipoPagamento.validate()) {
            this.btnNome = 'Continuar'
            if (this.termosAceitos) {
              this.step = 2
            } else {
              this.termosAceitos = true
            }
            if (this.select === 'Cartão') {
              this.step = 2
              this.carregarDados()
              this.realizarCompra()
            }
          }
          break
        case 2:
          this.btnNome = 'Concluir'
          if (this.termosAceitos) {
            // if (this.cartao.numero_cartao !== null) {
            this.finalizarCompra()
            // }
            this.step = 3
          } else {
            this.termosAceitos = true
          }
          break
      }
    },
    anexar (event) {
      this.arquivoAnexado = null
      this.imagemAnexada = null
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = event => {
        const arquivo = event.target.result
        if (file.type.indexOf('image/') !== -1) {
          this.imagemAnexada = arquivo
        } else {
          this.arquivoAnexado = arquivo
        }
      }
      if (file) {
        reader.readAsDataURL(file)
      }
    },
    inserir () {
      this.$store.dispatch('inserirArquivoFirebase', {
        prefixo: 'comprovante',
        id: this.negociacao.id,
        local: 'propostas',
        arquivo: this.imagemAnexada ? this.imagemAnexada : this.arquivoAnexado,
        tipo: this.imagemAnexada ? 'png' : 'pdf'
      }).then(downloadURL => {
        this.$store.dispatch('inserirComprovante', {
          comprovante_pagamento: downloadURL,
          id: this.negociacao.id
        })
      }).then(() => {
        this.$emit('success', 'Comprovante inserido com sucesso.')
        this.step = 3
      }).catch(() => {
        this.$emit('error', 'Não foi possível inserir seu comprovante')
      })
    },
    realizarCompra () {
      this.getFormaPagamento()
      this.$store
        .dispatch('realizarCompra', this.infoCompra, this.headers)
        .then(json => {
          this.infoPgto.id_compra = json.id_compra
        })
        .catch(error => {
          this.$emit('error', error)
          this.fechar()
        })
    },
    pagar () {
      this.finalizarCompra()
      // this.hashcartao(this.cartao)
    },
    getFormaPagamento () {
      const cnpj = this.negociacao.posto ? this.negociacao.posto.cnpj : this.negociacao.rede.matriz.cnpj
      pagamentoDao
        .formaDepagamento(cnpj)
        .then(response => {
          return response.json()
        })
        .then(result => {
          this.infoPgto.url_pagamento = result[0].forma_pagamento.url
        })
    },
    finalizarCompra () {
      this.$store
        .dispatch('pagarCartao', this.infoCompraFinalizar)
        .then(json => {
          this.step = 3
          this.proceed = true
          this.aprovarPagamento()
          this.$store.dispatch('buscarPropostas', { pagination: this.pagination })
        })
        .catch(error => {
          this.$emit('error', error)
          this.fechar()
        })
    },
    hashcartao () {
      this.checkCartao()
      this.validade = this.cartao.validade_cartao.split('/')
      pagamentoDao
        .hashCartao(this.card_hash, this.headers)
        .then(response => {
          return response.json()
        })
        .then(result => {
          this.infoCompraFinalizar.cartao = result.hash_code
          this.infoCompraFinalizar.id_cartao = result.id_cartao
        })
        .catch(error => {
          this.$emit('error', error)
          this.fechar()
        })
    },
    carregarDados () {
      this.infoPgto.user_agent = navigator.userAgent
      this.endereco_cobranca.cep = this.empresa.endereco.cep
      this.endereco_cobranca.rua = this.empresa.endereco.logradouro
      this.endereco_cobranca.num = this.empresa.endereco.numero
      this.endereco_cobranca.complemento = this.empresa.endereco.complemento
      this.endereco_cobranca.cidade = this.empresa.endereco.cidade
      this.endereco_cobranca.estado = this.empresa.endereco.estado
      this.endereco_cobranca.pais = this.empresa.endereco.pais
      this.endereco_cobranca.bairro = this.empresa.endereco.bairro
    },
    fechar () {
      this.step = 1
      this.clearFields()
      this.$emit('fechar')
      this.proceed = false
    },
    checkCartao () {
      if (this.cartao.credito === 'credito') {
        this.cartao.credito = true
      } else {
        this.cartao.credito = false
      }
    },
    getCartao () {
      this.$store.dispatch('getCartao', this.cartaoId)
        .then(json => {
          this.desserts = json
        })
        .catch(error => {
          this.$emit('error', error)
          this.close()
        })
    },
    listarcartoes () {
      if (!this.pessoa || !!this.pessoa.id_pessoa) return
      this.$store.dispatch('listarCartoes', this.pessoa.id_pessoa).then(cartoes => {
        this.desserts = cartoes
      })
        .catch(error => {
          this.$emit('error', error)
          this.close()
        })
    },
    initialize () {
      this.getCartao()
    },
    aprovarPagamento () {
      this.negociacao.status_pagamento = true
      this.$store.dispatch('confirmadoPagamentoCartao', this.negociacao.id).then(response => {
      })
        .catch(error => {
          this.$emit('error', error)
          this.close()
        })
    }
  }
}
</script>
