<template>
  <v-flex
    style="
      position: relative;
    "
  >
    <monaco-editor
        v-model="dados"
        language="json"
        height="300px"
        theme="vs-dark"
        :options="{
          readOnly: true,
          automaticLayout: true,
          formatOnType: true,
          formatOnPaste: true
        }"
      />
      <v-tooltip top
        :color="copiando ? 'green' : ''"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            @click="copiarRetorno"
            icon
            color="white"
            v-on="on"
            style="
            position: absolute;
            bottom: 1em;
            right: 1em;
          "
          >
            <v-icon>content_copy</v-icon>
          </v-btn>
        </template>
        <span>{{ copiando ? 'Copiado' : 'Copiar' }}</span>
      </v-tooltip>
  </v-flex>
</template>

<script>
import MonacoEditor from '@guolao/vue-monaco-editor'
export default {
  components: { MonacoEditor },
  props: ['codigo', 'endpoint'],
  data: () => ({
    copiando: false,
    dados: ''
  }),
  beforeMount () {
    this.dados = this.codigo ? this.codigo : this.prepararCorpo(this.endpoint.body)
  },
  watch: {
    codigo (val) {
      if (val) {
        this.dados = this.codigo
      }
    },
    'endpoint.body' (val) {
      if (val) {
        this.dados = this.prepararCorpo(val)
      }
    }
  },
  methods: {
    copiarRetorno () {
      navigator.clipboard.writeText(
        this.dados
      )
      this.copiando = true
      const interval = setInterval(() => {
        this.copiando = false
        clearInterval(interval)
      }, 3000)
    },
    prepararCorpo (body) {
      function isNumero (valor) {
        return !isNaN(parseFloat(valor)) && isFinite(valor)
      }
      function isLista (valor) {
        try {
          const resultado = JSON.parse(valor)
          return Array.isArray(resultado)
        } catch (e) {
          return false
        }
      }
      function isObjetoLiteral (valor) {
        try {
          const resultado = JSON.parse(valor)
          return resultado && typeof resultado === 'object' && Object.keys(resultado).length === 0
        } catch (e) {
          return false
        }
      }
      let jsonData = {}
      body.forEach(item => {
        const [chave, valor] = item.split(': ').map(part => part.trim())
        let novoValor = valor
        if (chave === 'posto' || chave === 'empresa') novoValor = `${valor}`
        else if (valor === 'true' || valor === 'false') novoValor = valor === 'true'
        else if (isNumero(valor)) novoValor = parseFloat(valor)
        else if (isLista(valor)) novoValor = JSON.parse(valor)
        else if (isObjetoLiteral(valor)) novoValor = JSON.parse(valor)
        jsonData[chave] = novoValor
      })
      if (this.endpoint.nome_lista) {
        const newData = { empresa: '87909075000178', veiculos: [] }
        newData[this.endpoint.nome_lista].push(jsonData)
        jsonData = newData
      }
      const jsonString = JSON.stringify(jsonData, null, 2)
      return jsonString
    }
  }
}
</script>
