<template>
  <v-card>
    <v-card-title class="text-h5 grey lighten-2">
        Ativação do SmartLock
    </v-card-title>
    <br>
    <v-card-text>
        Ao ativar a tela será bloqueada, sendo nescessário inserir ou cadastrar o seu SmartLock.
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions class="justify-space-around">
        <v-btn
        color="primary"
        text
        @click="fechar"
        >
        Voltar
        </v-btn>
        <v-btn
        color="primary"
        text
        @click="openSmartLock"
        >
        Continuar
        </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  methods: {
    openSmartLock () {
      this.$emit('openSmartLock')
      this.$emit('fechar')
    },
    fechar () {
      this.$emit('fechar')
    }
  }
}
</script>
