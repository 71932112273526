<template>
  <v-card style="border-radius: 1em; height: 36em;">
    <v-card-title v-if="step != 1" class="info--text">
      <v-btn @click="voltar" icon color="info" class="mr-3"><v-icon>arrow_back</v-icon></v-btn>Pré-Cadastro de Veículos
    </v-card-title>
    <v-card-text class="text-xs-center">
      <v-stepper v-model="step" style="box-shadow: none;">
        <v-stepper-items>
          <v-stepper-content step="1" v-if="step==1" style="justify-content: center; display: flex; height: 100%;">
            <v-layout justify-center>
              <v-icon class="mr-2" size="200" color="info">list_alt</v-icon>
            </v-layout>
              <h1 style="text-align: center;" class="info--text mb-4">Pré-Cadastro de Veículos</h1>
              <v-layout justify-space-around style="margin-top: 4em; margin-bottom: 2em;">
                <v-btn rounded color="info" @click="step++">Manual</v-btn>
                <v-btn rounded color="info" @click="step = 3">Automático</v-btn>
              </v-layout>
          </v-stepper-content>
          <v-stepper-content step="2" style="height: 100%;">
            <v-alert :value="true" type="error" v-show="this.existe">Placa já foi cadastra em sua empresa</v-alert>
            <v-layout column class="pa-2">
              <v-layout justify-space-around>
                <v-flex xs8>
                  <v-text-field
                    label="Placa do veículo"
                    v-model="input_placa"
                    outlined
                    v-mask="['AAA-#X##']"
                    masked="false"
                    v-on:keyup.enter="insertList"
                    :rules="[
                      v => !!v || 'Insira aqui a placa que você deseja adicionar',
                      v => v.length == 8 || 'Placa Inválida'
                    ]"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs2>
                  <v-btn small :disabled="this.existe" fab color="info" @click="insertList"><v-icon>add</v-icon></v-btn>
                </v-flex>
              </v-layout>
              <h2 v-if="files.length > 0">Veículos listados</h2>
              <v-flex xs12 class="mt-4">
              <v-list>
                <v-list-item v-for="(veiculo, index) in files" :key="index">
                  <v-list-item-avatar>
                    <v-icon>airport_shuttle</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{veiculo.placa}}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon><v-icon color="error" @click="removePlaca(index)">delete</v-icon></v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-flex>
            </v-layout>
          </v-stepper-content>
          <v-stepper-content step="3">
            <h4>Características da planilha</h4>
            <v-layout wrap justify-space-around class="pa-4" style="border: 2px solid gray; border-radius: 1em;">
              <v-flex xs4>
                <v-text-field type="number" color="info" label="Número da linha do cabeçalho" v-model="row"/>
              </v-flex>
              <v-flex xs4>
                <v-select
                  color="info"
                  label="Opção de seleção"
                  :items="[
                    'Nome do Cabeçalho',
                    'Nome da Coluna'
                  ]"
                  v-model="opcao"
                />
              </v-flex>
              <v-flex xs3>
                <v-text-field color="info" :label="opcao" v-model="col"/>
              </v-flex>
            </v-layout>
            <v-flex xs12 class="mt-4 mb-4 pa-4" v-show="col && row">
              <div class="excel-consolidar" style="height: 50px">
                <vue-dropzone
                  ref="myVueDropzone"
                  :useCustomSlot="true"
                  @vdropzone-file-added="fileadded"
                  @vdropzone-file-added-manually="fileadded"
                  id="drop"
                  :options="dropzoneOptions"
                  @vdropzone-success="handleDrop"
                  @vdropzone-drag-over="handleDragover"
                  @vdropzone-drag-enter="handleDragover"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">Arraste seus arquivos de consolidação para cá</h3>
                    <div class="subtitle">...ou clique para selecionar um arquivo do computador</div>
                  </div>
                </vue-dropzone>
              </div>
            </v-flex>
            <h2 v-if="files.length > 0">Veículos listados</h2>
            <v-flex xs12>
              <v-list>
                <v-list-item v-for="(veiculo, index) in files" :key="index">
                  <v-list-item-avatar>
                    <v-icon>airport_shuttle</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{veiculo.placa}}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon><v-icon color="error" @click="removePlaca(index)">delete</v-icon></v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-flex>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-card-actions class="ma-4" v-if="step != 1">
      <v-btn block color="success" @click="realizarPreCadastro"><v-icon class="mr-2">save</v-icon> Salvar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { mask } from 'vue-the-mask'
const ExcelJS = require('exceljs')

export default {
  directives: {
    mask
  },
  components: { vueDropzone: vue2Dropzone },
  data () {
    return {
      step: 1,
      col: undefined,
      row: undefined,
      existe: false,
      input_placa: '',
      opcao: 'Nome da Coluna',
      confirmado: false,
      report: null,
      files: [],
      events: [],
      input: null,
      nonce: 0,
      headers: [
        { text: '', sortable: false, value: 'STATUS', align: 'left', width: '0px', class: 'pl2 pr-2' },
        { text: 'Placa', sortable: false, align: 'left', value: 'identificacao', class: 'pl-3' }
      ],
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { 'My-Awesome-Header': 'header value' },
        dictDefaultMessage: 'Solte aqui os arquivos',
        previewTemplate: `
          <span tabindex="0" class="v-chip v-chip--removable theme--light teal white--text">
            <span class="v-chip__content">
              <div class="v-avatar" style="height: 48px; width: 48px;"><i aria-hidden="true" class="v-icon material-icons theme--light">check_circle</i></div>
              <div class="dz-filename"><span data-dz-name></span></div>
              <div class="v-chip__close dz-remove" data-dz-remove>
                <i aria-hidden="true" class="v-icon material-icons theme--light">cancel</i>
              </div>
            </span>
          </span>`,
        duplicateCheck: true
      }
    }
  },
  watch: {
    input_placa (val) {
      if (val && val.length === 8) {
        const placa = this.input_placa.replace(/[-]/g, '').toUpperCase()
        for (var i = 0; i < this.files.length; i++) {
          if (this.files[i].placa === placa) {
            this.existe = true
            this.$store.dispatch('setError', 'Placa já foi inserida.')
            return
          }
        }
        this.$store.dispatch('veiculoExiste', placa).then(value => {
          this.existe = value
          if (value) this.$store.dispatch('setError', 'Uma veículo já foi cadastrado com essa placa.')
        })
      } else {
        this.existe = false
      }
    }
  },
  methods: {
    realizarPreCadastro () {
      if (this.files.length <= 0) return
      this.$store.dispatch('preCadastro', {
        placas: this.files
      }).then(() => {
        this.files = []
        this.input_placa = ''
      })
    },
    insertList (placaPlanilha) {
      if (!this.input_placa && !placaPlanilha) {
        this.$store.dispatch('setError', 'Placa já foi inserida corretamente.')
        return
      }
      let exite = false
      const placa = this.input_placa ? this.input_placa.replace(/[-]/g, '').toUpperCase().trim() : placaPlanilha.toUpperCase().trim()
      for (var i = 0; i < this.files.length; i++) {
        if (this.files[i].placa === placa) {
          exite = true
        }
      }
      if (!exite) this.files.push({ placa: placa })
      else this.$store.dispatch('setError', 'Placa já foi inserida.')
    },
    removePlaca (index) {
      this.files.splice(index, 1)
    },
    updateElement: function (item) {
      Object.assign(this.report[item.index], item.element)
    },
    fileadded: function (file) {
      return false
    },
    comment () {
      this.input = null
    },
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    loadWorksheet (workbook) {
      // definir o worksheet
      const worksheet = workbook.worksheets[0]
      const headerPosition = this.row
      const headerRow = worksheet.getRow(headerPosition)
      if (!headerRow.hasValues) { return false }
      var col = this.opcao === 'Nome do Cabeçalho' ? undefined : this.col
      if (!col) {
        try {
          headerRow.eachCell((cel, i) => {
            if (cel.value === this.col) {
              // retorna o valor correto via exceção
              throw i
            }
          })
        } catch (e) {
          // definindo a coluna via try-catch
          col = e
        }
      }
      if (!col) { return false }

      // var docFile = []
      try {
        worksheet.eachRow((row, i) => {
          if (i <= headerPosition) {
            return
          }
          if (!row.hasValues || !row.getCell(col).value) {
            throw i
          }
          this.insertList((row.getCell(col).value + '').toUpperCase())
        })
      } catch (e) {
        console.log('resultado', e)
      }
      // this.files = docFile
    },
    voltar () {
      this.step = 1
      this.input_placa = ''
      this.files = []
    },
    handleDrop (f) {
      const workbook = new ExcelJS.Workbook()
      var extension = f.name.split('.')[1]
      if (extension === 'xlsx') {
        f.arrayBuffer().then(async (excelData) => {
          await workbook.xlsx.load(excelData)
          this.loadWorksheet(workbook)
        })
      } else if (extension === 'csv') {
        f.stream().then(async (excelData) => {
          await workbook.csv.read(excelData)
          this.loadWorksheet(workbook)
        })
      }
    }
  }
}
</script>

<style>
  .excel-consolidar .vue-dropzone {
    padding: 0
  }

  .excel-consolidar .vue-dropzone .dz-message {
    margin-top: 15px;
    margin-bottom: 10px
  }

  .vue-dropzone {
    border: 0px solid #e5e5e5;
  }

  .no-overflow .v-table__overflow {
    overflow: hidden;
  }

  .dz-filename {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
