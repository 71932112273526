<template>
  <v-card>
    <v-toolbar color="primary">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="fechar">
            <v-icon color="white">close</v-icon>
          </v-btn>
        </template>
        <span>Fechar</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-toolbar-title style="color: white;">
        Retirar Permissão do funcionário
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-alert v-if="error" type="error" class="mt-4 ml-1 mr-1 mb-1 pa-1">
      <h4>{{error}}</h4>
    </v-alert>
    <v-card-text>
      <v-row v-if="this.credito">
        <v-col cols="12" sm="6" md="12">
          <div class="my-1 subtitle-1 black--text"></div>
          <div class="my-1 subtitle-1 black--text">
            {{ credito.funcionario ? `Nome: ${credito.funcionario.nome_email}` : `Placa: ${credito.veiculo}` }}
          </div>
          <div v-if="credito.aberto==false" class="my-1 subtitle-1 black--text">Credito: {{credito.valor}} </div>
          <div v-else class="my-1 subtitle-1 black--text">Credito: Funcionario utiliza saldo da empresa </div>
         </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions align-content-space-between>
      <v-btn text color="red" dark @click="retirarPermissao()">Remover Permissão</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['credito'],
  data () {
    return {
      mostra: true,
      error: null,
      cor: ''
    }
  },
  methods: {
    fechar () {
      this.error = null
      this.$emit('carregar')
      this.$emit('fechar')
    },
    retirarPermissao () {
      this.credito.ativo = false
      this.$store.dispatch('removerPermissaoFuncionario', this.credito).then(() => {
        this.fechar()
      }).catch(() => {
        this.error = 'Não foi possível retirar a permissão'
      })
    }
  }
}
</script>
