<template>
  <v-card>
    <v-img v-if="posto.url_imagem_posto" width="700" :src="posto.url_imagem_posto" class="mb-3"></v-img>
    <v-card v-else color="grey" height="300" class="rounded-0 mb-3">
        <v-layout justify-center align-center fill-height>
          <div class="title white--text pa-4 text-xs-center">Sem Imagem</div>
        </v-layout>
    </v-card>
    <v-card-title class="pb-0">
      <h3 v-if="posto" style="width: 100%; text-align: center;">{{ posto.nome_fantasia }}</h3>
    </v-card-title>
    <v-card-text class="mb-4">
        <v-layout justify-space-around wrap class="mb-4">
          <v-flex xs5 class="mb-2">CNPJ: <b>{{posto.cnpj}}</b></v-flex>
          <template v-if="posto.endereco">
            <v-flex xs5 class="mb-2">Logradouro: <b>{{posto.endereco.logradouro}}</b></v-flex>
            <v-flex xs5 class="mb-2">Número: <b>{{posto.endereco.numero}}</b></v-flex>
            <v-flex xs5 class="mb-2">Complemento: <b>{{posto.endereco.complemento}}</b></v-flex>
            <v-flex xs5 class="mb-2">Bairro: <b>{{posto.endereco.bairro}}</b></v-flex>
            <v-flex xs5 class="mb-2">Cidade: <b>{{posto.endereco.cidade}}</b></v-flex>
            <v-flex xs5 class="mb-2">Estado: <b>{{posto.endereco.estado}}</b></v-flex>
            <v-flex xs5 class="mb-2">CEP: <b>{{posto.endereco.cep}}</b></v-flex>
          </template>
          <v-flex xs12 class="mb-2" v-if="combustiveis.length > 0">
            <v-divider></v-divider>
            <h3 style="text-align: center; font-weight: 500;" class="mt-3">Combustíveis do posto {{ posto.nome_fantasia }}</h3>
          </v-flex>
          <v-flex xs12 class="mb-2" v-if="combustiveis.length > 0">
            <v-list>
              <v-list-item two-line v-for="(item, index) in combustiveis" :key="index">
                <v-list-item-action>
                  <v-tooltip :color="item.combustivel.color" top>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <svg-filler
                          :path="item.combustivel.icone ? require(`@/assets/combs/${item.combustivel.icone}.svg`) : require(`@/assets/combs/gas.svg`)"
                          width="40px"
                          height="40px"
                          :fill="item.combustivel.color"
                        />
                      </span>
                    </template>
                    <span>{{item.combustivel.nome}}</span>
                  </v-tooltip>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 0.9em;">{{item.combustivel.nome}}</v-list-item-title>
                  <v-list-item-subtitle>R$ {{corrigirPreco(item.preco).toString().replace('.', ',')}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
        <v-layout justify-center>
          <v-card dark color="primary lighten-2" style="width: 90%">
            <l-map
              ref="myMap"
              v-if="mapa.center"
              style="height: 40vh; width: 100%; z-index: 0;"
              :options="{ zoomControl: zoomControl }"
              :zoom="mapa.zoom"
              :center="mapa.center"
              @update:zoom="zoomUpdated"
              @update:center="centerUpdated"
              @update:bounds="boundsUpdated"
            >
              <l-tile-layer
                :name="mapa.name"
                :url="mapa.url"
                :attribution="mapa.attribution"></l-tile-layer>
                <l-layer-group>
                  <app-marker @postoSelecionado="posto"  :key="index" :posto="posto"></app-marker>
                </l-layer-group>
            </l-map>
          </v-card>
        </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-btn block @click="$emit('fechar')">Fechar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import { LMap, LTileLayer, LLayerGroup } from 'vue2-leaflet'
import AppMarker from '@/components/util/Marker'

export default {
  props: ['posto'],
  components: {
    LMap,
    LTileLayer,
    LLayerGroup,
    AppMarker
  },
  data: () => ({
    combustiveis: [],
    mapa: {
      name: 'Mapa',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: 'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
      maxZoom: 18,
      zoom: 14,
      center: null,
      bounds: null,
      zoomControl: false
    }
  }),
  computed: {
    zoomControl () {
      return !this.$vuetify.breakpoint.xs
    }
  },
  beforeMount () {
    this.pegarCombustiveis()
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.carregarMapa(position.coords.latitude, position.coords.longitude)
      })
      /* geolocation is available */
    } else {
      this.$root.router.push(-1)
      /* geolocation IS NOT available */
    }
  },
  methods: {
    zoomUpdated (zoom) {
      this.mapa.zoom = zoom
    },
    centerUpdated (center) {
      this.mapa.center = center
    },
    boundsUpdated (bounds) {
      this.mapa.bounds = bounds
    },
    carregarMapa (latitude, longitude) {
      this.mapa.center = [this.posto.latitude, this.posto.longitude]
    },
    pegarCombustiveis () {
      this.$store.dispatch('precoCombustivelPorPosto', this.posto.cnpj
      ).then(value => {
        this.combustiveis = value
      })
    },
    corrigirPreco (valor) {
      if (!valor) return
      var temCasaDecimal = valor.toString().replaceAll('.', ',').split(',')[1] != null
      if (temCasaDecimal && valor.toString().replaceAll('.', ',').split(',')[1].length > 2) {
        var casasDecimais = valor.toString().replaceAll('.', ',').split(',')[1]
        var filtrando = casasDecimais.substring(0, 2)
        return parseFloat([valor.toString().split('.')[0], filtrando].join('.')).toFixed(2)
      }
      return valor.toFixed(2)
    }
  }
}
</script>
