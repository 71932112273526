<template>
    <v-card>
        <v-card-title class="justify-center" style="font-size: 1.5em;">
            Editar Informações do Funcionário
        </v-card-title>
        <v-card-text>
            <v-layout
                wrap
                justify-space-around
            >
                <v-flex xs5>
                    <v-text-field
                        label="Nome"
                        v-model="nome"
                        :rules="[
                            v => !!v || 'Campo Obrigatório'
                        ]"
                    />
                </v-flex>
                <v-flex xs5>
                    <v-text-field
                        label="Sobrenome"
                        v-model="sobrenome"
                    />
                </v-flex>
                <v-flex xs5>
                    <v-text-field
                        label="CPF"
                        v-model="cpf"
                        v-mask="'###.###.###-##'"
                        :rules="[
                            v => !!v || 'Campo Obrigatório'
                        ]"
                    />
                    <h5
                        class="error--text text-xs-center"
                        v-show="cpfExiste"
                    >
                        CPF já existe
                    </h5>
                </v-flex>
                <v-flex xs5>
                    <v-text-field
                        label="Matrícula"
                        v-model="matricula"
                        v-mask="'####'"
                        :rules="[
                            v => !!v || 'Campo Obrigatório'
                        ]"
                    />
                    <h5
                        class="error--text text-xs-center"
                        v-show="matriculaExiste"
                    >
                        Matrícula já existe
                    </h5>
                </v-flex>
                <v-flex xs5>
                    <v-text-field
                        label="Telefone"
                        v-model="telefone"
                        v-mask="'(##) # ####-####'"
                    />
                </v-flex>
            </v-layout>
        </v-card-text>
        <v-card-actions>
            <v-btn
                color="success"
                block
                :disabled="matriculaExiste || cpfExiste"
                @click="editarFrentista"
            >
                <v-icon class="mr-1">edit</v-icon>
                Editar
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
  props: ['funcionario'],
  data: () => ({
    nome: '',
    sobrenome: '',
    telefone: '',
    matricula: '',
    cpf: '',
    matriculaExiste: false,
    cpfExiste: false
  }),
  computed: {
    empresa () {
      return this.$store.getters.empresa
    }
  },
  beforeMount () {
    if (this.funcionario) {
      this.nome = this.funcionario.nome
      this.sobrenome = this.funcionario.sobrenome
      this.telefone = this.funcionario.telefone
      this.matricula = this.funcionario.matricula
      this.cpf = this.funcionario.cpf
    }
  },
  methods: {
    editarFrentista () {
      this.$store.dispatch('editarFrentista', {
        id_frentista: this.funcionario.id_frentista,
        nome: this.nome,
        sobrenome: this.sobrenome ? this.sobrenome : null,
        telefone: this.telefone ? this.telefone : null,
        matricula: this.matricula,
        cpf: this.cpf,
        empresa: this.empresa.cnpj
      }).then(() => {
        this.$emit('carregar')
        this.$emit('fechar')
      })
    },
    async verificarDados (data) {
      data.empresa = this.empresa.cnpj
      let existe = false
      await this.$store.dispatch('frentistas', data).then(json => {
        if (json.length > 0) {
          for (const f in json) {
            if (json[f].id_frentista !== this.funcionario.id_frentista) {
              existe = true
            }
          }
        }
      })
      return existe
    }
  },
  watch: {
    matricula (val) {
      if (val) {
        this.verificarDados({ matricula: val.replace(/\D/g, '') }).then(value => {
          this.matriculaExiste = value
        })
      }
    },
    cpf (val) {
      if (val) {
        this.verificarDados({ cpf: val.replace(/\D/g, '') }).then(value => {
          this.cpfExiste = value
        })
      }
    }
  }
}
</script>
