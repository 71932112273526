import settings from '@/settings'

const adicaoDeCredito = (headers, method, cnpj, data) => {
  // method GET pegar solicitações ainda não aprovadas de adição de crédito
  // method POST solicitação de adição de crédito
  const request = {
    mode: 'cors',
    headers: headers,
    method: method
  }
  if (method === 'GET') request.cache = 'default'
  else request.body = JSON.stringify(data)
  return fetch(`${settings.apetrusApi}/carteiravirtual/adicaodecredito/?empresa=${cnpj}`, request)
}

const comprarCashBack = (headers, data) => {
  return fetch(`${settings.apetrusApi}/carteiravirtual/compra-cashback/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

export default {
  adicaoDeCredito,
  comprarCashBack
}
