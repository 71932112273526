/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register('/service-worker.js', {
    registerOptions: { scope: '/' },
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered (registration) {
      console.log('Service worker has been registered.')
      registration.addEventListener('updatefound', () => {
        // Instalação do novo Service Worker
        const newWorker = registration.installing
        newWorker.addEventListener('statechange', () => {
          // Se o novo Service Worker for instalado
          if (newWorker.state === 'installed') {
            // Verifica se existe um Service Worker atualmente em controle
            if (navigator.serviceWorker.controller) {
              // Existe uma nova versão disponível
              console.log('New content is available please refresh.')
              // Dispara uma mensagem para o Service Worker atual
              newWorker.postMessage({ action: 'skipWaiting' })
              // Atualiza a página automaticamente
              window.location.reload()
            } else {
              // Não existe um Service Worker atualmente em controle
              console.log('Content is cached for offline use.')
            }
          }
        })
      })
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
