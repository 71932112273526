<template>
  <v-stepper>
    <v-stepper-header v-model="step">
      <v-stepper-step :complete="step > 1" step="1">Cadastro Empresa</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="step > 2" step="2">Termos</v-stepper-step>

      <v-divider></v-divider>

      <!-- <v-stepper-step step="3">Geolocalização</v-stepper-step> -->
    </v-stepper-header>
    <v-stepper-items>
      <!-----------------tela 1------------------------->
      <v-stepper-content step="1" class="pa-0">
        <v-card>
          <cad-empresa v-if="step===1"></cad-empresa>
          <v-spacer></v-spacer>
        </v-card>
      </v-stepper-content>
      <!----------------- fim tela 1------------------------->
      <!-----------------tela 3------------------------->
      <!-- <v-stepper-content step="3">
        <div v-on="printstep()"></div>
        <h1>teste</h1>
        <v-card v-if="step===3">
          <v-card-actions>
            <v-btn text color="primary" @click="step_next()">Finalizar</v-btn>
          </v-card-actions>
          <geo-position></geo-position>
          <geo-position v-if="step===3"></geo-position>
          <v-spacer></v-spacer>
        </v-card>
      </v-stepper-content>-->

      <!-----------------fim tela 2------------------------->
      <!-----------------tela 3------------------------->
      <v-stepper-content step="2" class="pa-0"></v-stepper-content>
      <div v-if="step===2">
        <v-card>
          <v-card-text class="pt-4" style="position: relative;">
            <h4
              class="display-1 font-weight-light orange--text mb-4"
            >Concorde com os termos para prosseguir.</h4>
            <v-layout row wrap justify-space-around fill-height>
              <v-flex xs12 class="mb-4 mt-4">
                <v-btn color="primary" block outlined @click="dialogTermosUsoUsuario = true">
                  <v-icon :color="termosUsuarioLidos? 'green' : 'grey'">check_circle</v-icon>
                  <v-spacer></v-spacer>Termos de Uso do Usuário
                  <v-spacer></v-spacer>
                </v-btn>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs12 class="mb-4">
                <v-btn color="primary" block outlined @click="dialogTermosUso = true">
                  <v-icon :color="termosLidos? 'green' : 'grey'">check_circle</v-icon>
                  <v-spacer></v-spacer>Termos de Uso da Empresa
                  <v-spacer></v-spacer>
                </v-btn>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs12 class="mb-4">
                <v-btn color="primary" block outlined @click="dialogPoliticasPrivacidade = true">
                  <v-icon :color="politicasLidas? 'green' : 'grey'">check_circle</v-icon>
                  <v-spacer></v-spacer>Política de privacidade
                  <v-spacer></v-spacer>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              :disabled="!(termosLidos && termosUsuarioLidos && politicasLidas)"
              color="primary"
              @click="aceitarTermos"
            >Concordar</v-btn>
          </v-card-actions>
        </v-card>
        <v-dialog v-model="dialogTermosUso" width="600">
          <termos-de-uso @confirmarLeitura="dialogTermosUso = false"></termos-de-uso>
        </v-dialog>
        <v-dialog v-model="dialogTermosUsoUsuario" width="600">
          <termos-de-uso-usuario @confirmarLeitura="dialogTermosUsoUsuario = false"></termos-de-uso-usuario>
        </v-dialog>
        <v-dialog v-model="dialogPoliticasPrivacidade" width="600">
          <politica-de-privacidade
            @confirmarLeitura="dialogPoliticasPrivacidade = false"
            @fechar="dialogPoliticasPrivacidade = false"
          ></politica-de-privacidade>
        </v-dialog>
        <!-- <div v-once>{{ step_next() }}</div> -->
      </div>
      <!-----------------fim tela 3------------------------->
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import CadEmpresa from '@/components/cadastros/CadEmpresa'
import TermosDeUso from '@/components/dialogs/DialogTermosUso'
import TermosDeUsoUsuario from '@/components/dialogs/DialogTermosUsoUsuario'
import PoliticaDePrivacidade from '@/components/dialogs/DialogPoliticaPrivacidade'
// import GeoPosition from '@/components/cadastros/GeoPosition'
import { empresasDao } from '@/api'

export default {
  components: { CadEmpresa, TermosDeUso, PoliticaDePrivacidade, TermosDeUsoUsuario },
  // components: { CadEmpresa, TermosDeUso, PoliticaDePrivacidade, GeoPosition },
  props: ['visible'],
  data () {
    return {
      step: 1,
      confirmGeoposition: false,
      termosLidos: false,
      termosUsuarioLidos: false,
      politicasLidas: false,
      dialogTermosUso: false,
      dialogTermosUsoUsuario: false,
      dialogPoliticasPrivacidade: false
    }
  },
  beforeMount () {
    if (this.empresa && !this.empresa.termos_aceitos) {
      this.step = 2
    }
  },
  watch: {
    empresa (val) {
      if (val !== null && val !== undefined) {
        if (val.termos_aceitos && this.step === 2) {
          this.$emit('finalizado')
        }
      }
    },
    dialogTermosUso (val, oldval) {
      if (val === false) {
        this.termosLidos = true
      }
    },
    dialogTermosUsoUsuario (val, oldval) {
      if (val === false) {
        this.termosUsuarioLidos = true
      }
    },
    dialogPoliticasPrivacidade (val, oldval) {
      if (val === false) {
        this.politicasLidas = true
      }
    },
    empresas (val) {
      if (val !== null && val !== undefined && val.length > 0) {
        if (this.step === 1) {
          this.step++
        }
      }
    },
    step (val) {
      if (val === 1 && this.empresas.length) {
        this.step++
      }
      if (val === 3 && this.empresa.geoposition) {
        this.$emit('finalizado')
      }
      if (val === 2 && this.empresa.termos_aceitos) {
        this.$emit('finalizado')
        // this.step++
      }
    }
  },
  computed: {
    empresas () {
      return this.$store.getters.empresas
    },
    empresa () {
      return this.$store.getters.empresa
    },
    headers () {
      return this.$store.getters.headers
    }
  },
  methods: {
    step_next () {
      this.step++
    },
    aceitarTermos () {
      empresasDao.aceitarTermos(this.empresa.cnpj, this.headers).then(() => {
        this.$store.dispatch('aceitarTermos')
        this.step = 3
      })
    }
  }
}
</script>
