<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || empresa.tipo_empresa !== 1)"/>
  <v-layout align-space-around justify-start fill-height column class="pt-5" v-else>
    <v-toolbar
      color="transparent"
      class="elevation-0 secondary--text"
      style="background: transparent"
    >
      <v-toolbar-title>Recargas Solicitados</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar color="transparent" class="elevation-0 secondary--text">
        <v-spacer></v-spacer>
        <v-btn class="mr-4" small fab color="info" @click="showFilter = !showFilter">
          <v-icon>{{ !showFilter ? 'filter_list' : 'filter_list_off' }}</v-icon>
        </v-btn>
      </v-toolbar>
    </v-toolbar>
    <v-slide-x-transition>
      <v-card v-show="showFilter" class="ml-4 mr-4 mb-4">
        <v-card-text>
          <v-layout wrap justify-space-between>
            <v-flex xs10 md11>
              <v-layout wrap justify-space-around>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-select
                    v-model="filtros.proposta__modelo_faturamento"
                    :items="[{value:'prepago', text:'Pré-pago'}, {value:'pospago', text:'Pós-pago'}, {value:'presencial', text:'Presencial'}]"
                    item-text="text"
                    item-value="value"
                    label="Modelo de faturamento"
                  ></v-select>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-text-field label="Data de solicitação" v-model="filtros.data_abertura" type="date"></v-text-field>
                </v-flex>
                <v-flex xs11 sm5 md4 lg3 xl2>
                  <v-select
                    v-model="filtros.status"
                    :items="[{value:'true', text:'Aceita'},{value:'false', text:'Negada'},{value:'null', text:'Aguardando resposta'}]"
                    label="Estado da proposta"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2 md1 align-self-center>
              <v-btn small fab color="info" @click="carregarCreditos($event)">
                <v-icon>search</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-slide-x-transition>
    <v-data-table
      hide-default-footer
      :headers="creditosSolicitadosHeaders"
      :items="recargassolicitadas"
      :options.sync="pagination"
      no-data-text="Você ainda não possui créditos"
      class="elevation-1"
    >
      <template v-slot:item="props">
        <tr>
          <td v-if="props.item.proposta.posto != null">{{ props.item.proposta.posto.nome_fantasia }}</td>
          <td v-else>{{ props.item.proposta.rede.nome }}</td>
          <td>{{ props.item.valor_recarga }}</td>
          <td>{{ props.item.data_abertura | formatDate('DD/MM/YYYY') }}</td>
          <td class="text-xs-left">
                <v-tooltip bottom v-if="props.item.status==true">
                  <template v-slot:activator="{ on }">
                    <v-icon color="teal" dark v-on="on">check_circle_outline</v-icon>
                  </template>
                  <span>Recarga Aceita</span>
                </v-tooltip>
                <v-tooltip bottom v-if="props.item.status==null && props.item.data_resposta == null">
                  <template v-slot:activator="{ on }">
                    <v-icon color="primary" dark v-on="on">query_builder</v-icon>
                  </template>
                  <span>Aguardando Resposta</span>
                </v-tooltip>
                <v-tooltip bottom v-if="props.item.status==false">
                  <template v-slot:activator="{ on }">
                    <v-icon color="red" dark v-on="on">highlight_off</v-icon>
                  </template>
                  <span>Recarga Recusada</span>
                </v-tooltip>
            </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-xs-center">
      <v-pagination
        v-model="pagination.page"
        :length="pages"
        circle
      ></v-pagination>
    </div>
    <v-snackbar
      v-model="snackbar.active"
      :color="snackbar.color"
      :multi-line="snackbar.multiLine"
      :timeout="snackbar.timeout"
      :vertical="snackbar.vertical"
    >
      {{ snackbar.text }}
      <v-btn text @click="snackbar.active = false"> Fechar </v-btn>
    </v-snackbar>
  </v-layout>
</template>

<script>
import AvisoBloqueio from '@/components/AvisoBloqueio'
export default {
  components: { AvisoBloqueio },
  data () {
    return {
      showFilter: false,
      filtros: {
        status: '',
        proposta__modelo_faturamento: '',
        data_abertura: ''
      },
      creditoSelecionado: null,
      snackbar: {
        active: false,
        color: '',
        multiLine: true,
        timeout: 4000,
        vertical: true,
        text: ''
      },
      pagination: {
        page: 1,
        rowsPerPage: 5
      },
      creditosSolicitadosHeaders: [
        {
          text: 'Posto',
          align: 'left',
          sortable: false,
          value: 'credito.propostas'
        },
        {
          text: 'Valor da recarga',
          align: 'left',
          sortable: false,
          value: 'valor_recarga'
        },
        {
          text: 'Data da solicitação',
          align: 'left',
          sortable: false,
          value: 'data_abertura'
        },
        {
          text: 'Status',
          align: 'left',
          sortable: false,
          value: 'status'
        }
      ]
    }
  },
  watch: {
    pagination: {
      handler (val, oldval) {
        if (this.empresa) this.carregarCreditos()
      },
      deep: true
    },
    empresa (val, oldval) {
      if (val !== null && val !== undefined && val !== oldval) {
        // this.carregarFuncionarios()
        this.carregarCreditos()
      }
    }
  },
  mounted () {
    if (this.empresa) this.carregarCreditos()
  },
  computed: {
    pages () {
      if (this.pagination.rowsPerPage == null || this.totalItems == null) {
        return 0
      }
      return Math.ceil(this.totalItems / this.pagination.rowsPerPage)
    },
    funcionarios () {
      return this.$store.getters.funcionarios
    },
    headers () {
      return this.$store.getters.headers
    },
    totalItems () {
      return this.$store.getters.totalItemsPage
    },
    empresa () {
      return this.$store.getters.empresa
    },
    recargassolicitadas () {
      return this.$store.getters.recargassolicitadas
    }
  },
  methods: {
    carregarCreditos (event) {
      if (event) {
        this.pagination.page = 1
      }

      var data = {
        page: this.pagination.page,
        empresa: this.empresa.cnpj
      }

      if ((this.filtros.status) && (this.filtros.status !== 'null')) data.status = this.filtros.status
      if (this.filtros.status === 'null') data.status__isnull = true
      if (this.filtros.proposta__modelo_faturamento) data.proposta__modelo_faturamento = this.filtros.proposta__modelo_faturamento
      if (this.filtros.data_abertura) data.data_abertura = this.filtros.data_abertura

      this.$store.dispatch('carregarCreditosSolicitados', data)
    },
    recarregarPagina () {
      document.location.reload(true)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
