<template>
  <v-dialog persistent v-model="visible" width="500" :fullscreen="$vuetify.breakpoint.xs">
    <template v-if="visible">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">Consumir</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="step > 2" step="2">Associar Crédito</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="step > 3" step="3">Confirmação</v-stepper-step>

        </v-stepper-header>
        <v-toolbar color="green" green text>
          <v-toolbar-title>
            Saldo disponível: {{
              step == 3 && negociacao.modelo_faturamento == 'credito' ?
              (
                select == 'Gerar Voucher' ? (saldo_disponivel - (formatarValor(valor_tela) - (formatarValor(valor_tela) * (negociacao.desconto / 100))))
                : (saldo_disponivel - (formatarValor(valor_limite)))
              ) :
              saldo_disponivel | currency({fractionCount: 2})
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          {{ getDestinatario(negociacao.posto || negociacao.rede) }}
        </v-toolbar>
        <v-stepper-items>
          <v-stepper-content step="1" class="pa-0">
            <v-card :height="select == 'Gerar Voucher' ? '42em' : '13em'">
              <v-container fluid grid-list-md>
                <v-slide-y-transition>
                  <v-form ref="formTipoPagamento" v-model="valid" lazy-validation v-show="!termosAceitos">
                    <v-layout row wrap>
                      <v-flex xs12>
                        <h3 v-if="negociacao.status_pagamento && negociacao.valor"
                          class="grey--text"
                        >
                          Valor Negociado: {{(negociacao.valor) | currency({fractionCount: 2}) }}
                        </h3>
                        <h3 v-else-if="!negociacao.is_apetrus" class="red--text"> {{ negociacao.posto ? 'Posto' : 'Rede' }} não aprovou seu pagamento!</h3>
                      </v-flex>
                       <v-select class="mt-2" v-if="negociacao.status_pagamento"
                          v-model="select"
                          :items="items"
                          label="Selecione a opção de uso"
                          required
                          solo
                      ></v-select>
                       <v-text-field v-if="select == 'Gerar Voucher'"
                          class="mt-3 mb-3"
                          v-model.lazy="valor_tela"
                          outline
                          label="Valor da compra"
                          prefix="R$"
                          hide-details
                          v-money="money"
                          required
                          solo
                          :rules="[
                            v => !!v || 'Valor Inválido',
                            v => formatarValor(v) > 0 || 'Valor Inválido'
                          ]"
                        >
                        </v-text-field>
                        <v-flex xs12 v-if="select == 'Gerar Voucher' && negociacao.is_apetrus">
                          <v-flex xs12>
                            <v-text-field
                              prepend-inner-icon="search"
                              rounded
                              filled
                              hide-details
                              label="Digite o nome fantasia ou CNPJ do posto"
                              class="pa-0 ma-0"
                              v-model="itemPesquisa"
                              solo
                            >
                            </v-text-field>
                          </v-flex>
                          <v-layout justify-center v-if="itemPesquisa" :class="postoSelecionado ? 'mt-3' : ''">
                            <v-card>
                              <v-list class="pa-0 ma-0">
                                <v-list-item v-for="(obj, index) in postos" :key="index">
                                  <v-list-item-icon>
                                    <v-avatar
                                        color="grey"
                                        size="30">
                                        <img v-if="obj.url_logo" :src="obj.url_logo" alt="Imagem do posto">
                                        <v-icon dark v-else>local_gas_station</v-icon>
                                      </v-avatar>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>{{obj.nome_fantasia}}</v-list-item-title>
                                    <v-list-item-subtitle>{{obj.cnpj}}</v-list-item-subtitle>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-btn icon @click="postoSelecionado = obj, postos = [obj]" :color="postoSelecionado && postoSelecionado.cnpj === obj.cnpj ? 'success' : ''">
                                      <v-icon>check</v-icon>
                                    </v-btn>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list>
                            </v-card>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 v-if="select == 'Gerar Voucher' &&  ((negociacao.is_apetrus == true && postoSelecionado) || (negociacao.posto || negociacao.rede))">
                          <v-select
                            class="mt-4"
                            label="Selecione o tipo de combustível"
                            clearable
                            :items="itens"
                            item-text="nome"
                            item-color="combustivel.color"
                            v-model="itemSelecionado"
                            :disabled="negociacao.combustivel || negociacao.item"
                            solo
                            return-object
                          >
                            <template v-slot:item="data">
                                <svg-filler
                                  :path="data.item.combustivel.icone ? require(`@/assets/combs/${data.item.combustivel.icone}.svg`) : require(`@/assets/combs/gas.svg`)"
                                  width="40px"
                                  height="40px"
                                  :fill="data.item.combustivel.color"
                                />
                                <v-spacer></v-spacer>
                                <span>{{data.item.combustivel.nome}}</span>
                                <v-spacer></v-spacer>
                                <div style="font-size: 0.8em" v-if="negociacao.desconto && negociacao.desconto > 0">
                                  <span>{{data.item.preco | currency({fractionCount: 2})}}</span>
                                </div>
                                <div style="font-size: 0.8em" v-else>
                                  <span>{{(data.item.preco - (data.item.preco * (negociacao.desconto / 100))) | currency({fractionCount: 2}) }}</span>
                                </div>
                            </template>
                          </v-select>
                          <v-autocomplete
                            :items="funcionarios"
                            v-model="funcionarioSelected"
                            color="white"
                            item-text="nome_email"
                            label="Colaboradores"
                            clearable
                            return-object
                            :disabled="!!veiculoSelecionado"
                            solo
                          >
                          <template v-slot:item="props">
                            <v-icon class="mr-4">person</v-icon>{{ props.item.nome_email }}
                          </template>
                          </v-autocomplete>
                          <v-autocomplete
                            :items="veiculos"
                            v-model="veiculoSelecionado"
                            color="white"
                            item-text="identificacao"
                            label="Veículos"
                            return-object
                            clearable
                            :disabled="!funcionarioSelected"
                            solo
                          >
                            <template v-slot:item="props">
                              <v-icon class="mr-4">directions_car</v-icon>{{ props.item.identificacao}} - {{ props.item.modelo_marca || `${props.item.marca} ${props.item.modelo}` }}
                            </template>
                          </v-autocomplete>
                        </v-flex>
                        <v-flex xs12 v-if="negociacao.modelo_faturamento == 'credito' && select == 'Gerar Voucher'">
                          <h4>Será abastecido: R$ {{valor_tela}}</h4>
                          <h4>Desconto: {{negociacao.desconto}}%</h4>
                          <h4 v-if="empresa.taxa_por_abastecimento_carteira">Taxa de utilização: {{empresa.taxa_por_abastecimento_carteira}}%</h4>
                          <h4>Você pagará:
                            {{
                              (checkTipoVoucher() - (checkTipoVoucher() * (negociacao.desconto / 100)) + (checkTipoVoucher() * (empresa.taxa_por_abastecimento_carteira / 100))) | currency({fractionCount: 2})
                            }}
                          </h4>
                        </v-flex>
                    </v-layout>
                  </v-form>
                </v-slide-y-transition>
              </v-container>
              <v-footer absolute color="transparent">
              <v-card-actions style="width: 100%;">
                <v-btn color="grey darken-1" text @click="fechar()">Voltar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="passarTela()" :disabled="desabilitar">{{select == 'Gerar Voucher' ? 'Gerar' : 'Continuar'}}</v-btn>
              </v-card-actions>
            </v-footer>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="2" class="pa-0">
            <v-card>
              <v-container fluid grid-list-md>
                <v-slide-y-transition>
                <v-form ref="formFuncionario" v-model="valid" lazy-validation v-show="!termosAceitos">
                  <v-layout column>
                   <v-flex
                    xs12
                    class="
                      text-center
                      subheading
                      gray--text
                      font-weight-regular
                      mb-3
                    "
                  >
                    SELECIONE O FUNCIONÁRIO BENEFICIADO OU VEÍCULO
                  </v-flex>
                  <v-autocomplete
                    :items="funcionarios"
                    v-model="funcionarioSelected"
                    color="white"
                    item-text="nome_email"
                    label="Colaboradores"
                    clearable
                    return-object
                    :disabled="!!veiculoSelecionado"
                    solo
                  >
                  <template v-slot:item="props">
                    <v-icon class="mr-4">person</v-icon>{{ props.item.nome_email }}
                  </template>
                  </v-autocomplete>
                  <v-autocomplete
                    :items="veiculos"
                    v-model="veiculoSelecionado"
                    color="white"
                    item-text="identificacao"
                    label="Veículos"
                    return-object
                    clearable
                    :disabled="!!funcionarioSelected"
                    solo
                  >
                    <template v-slot:item="props">
                      <v-icon class="mr-4">directions_car</v-icon>{{ props.item.identificacao}} - {{ props.item.modelo_marca || `${props.item.marca} ${props.item.modelo}` }}
                    </template>
                  </v-autocomplete>
                  <v-select v-if="negociacao.status_pagamento"
                      v-model="select_limite"
                      :items="items_limite"
                      label="Limite de credito"
                      required
                      solo
                  ></v-select>
                  <v-text-field v-if="select_limite == 'Com limite'"
                      outline
                      v-model.lazy="valor_limite"
                      :rules="[
                      v => !!v || 'Esse campo é obrigatório'
                      ]"
                      label="Informe o limite (Funcionário tera esse saldo para usar)"
                      hide-details
                      maxlength="10"
                      prefix="R$"
                      v-money="money"
                      solo
                  ></v-text-field>
                    <v-flex
                      v-if="select_limite == 'Sem limite'"
                      xs8
                      class="
                        text-xs-center
                        red--text
                        subheading
                        font-weight-regular
                      "
                    >
                     AVISO: O {{ funcionarioSelected ? 'funcionário' : 'veículo' }} selecionado usará o saldo da empresa
                    </v-flex>
                  </v-layout>
                </v-form>
                </v-slide-y-transition>
              </v-container>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn color="red darken-1" text :loading="loading" @click="fechar()">Fechar</v-btn>
                  <v-spacer></v-spacer>
                <v-btn color="green darken-1" text :disabled="desabilitar" @click="passarTela()">{{btnNome}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="3" class="pa-0">
            <v-card>
              <v-container fluid grid-list-md>
                <v-slide-y-transition>
                  <v-layout column>
                   <v-flex xs8 class="text-xs-center subheading green--text font-weight-regular">
                     {{msgFinal}}
                    </v-flex>
                  </v-layout>
                </v-slide-y-transition>
              </v-container>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn color="red darken-1" text :loading="loading" @click="fechar()">Fechar</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </v-dialog>
</template>

<script>

import { mask } from 'vue-the-mask'
import { VMoney } from 'v-money'
import { funcionariosDao } from '@/api'

export default {
  props: ['visible', 'negociacao'],
  directives: {
    mask,
    money: VMoney
  },
  data () {
    return {
      lista: false,
      seletedFormaPagamento: null,
      preco_combustiveis: [],
      postoSelecionado: undefined,
      postos: [],
      pagamentoDinheiro: false,
      arquivoAnexado: null,
      funcionarioSelected: undefined,
      veiculoSelecionado: undefined,
      imagemAnexada: null,
      msgFinal: 'Voucher gerado com sucesso, Agora realize o compartilhamento com funcionario',
      btnNome: 'Passar',
      voucher: {
        litros: null,
        id: null,
        valor: null,
        compra: null
      },
      item_text: 'nome_fantasia',
      funcionarios: [],
      veiculos: [],
      itens: [],
      termosAceitos: false,
      step: 1,
      loading: false,
      valor_tela: '0,00',
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false
      },
      itemPesquisa: undefined,
      id_combustivel: undefined,
      itemSelecionado: undefined,
      valor_limite: 0.00,
      formPagamento: false,
      formFuncionario: false,
      soma: 0,
      valid: false,
      aberto: false,
      teste: '',
      select: null,
      pagination: {
        page: 1,
        rowsPerPage: 1000
      },
      select_limite: null,
      items: [
        'Conceder Crédito',
        'Gerar Voucher'
      ],
      items_limite: [
        'Com limite',
        'Sem limite'
      ]
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    cliente () {
      return this.$store.getters.cliente
    },
    tipos_pagamento () {
      return this.$store.getters.tipos_pagamento
    },
    empresa () {
      return this.$store.getters.empresa
    },
    funcionario () {
      return this.$store.getters.funcionario
    },
    data () {
      const filtro = {
        page_size: 3
      }
      if (this.negociacao.modelo_faturamento === 'credito') {
        filtro.taxa_recebimento_empresa__gte = 0
        filtro.tem_recebimento_carteira_empresa = true
      } else filtro.taxa_empresa__gte = 0
      const isCNPJ = this.is_number(this.itemPesquisa)
      if (isCNPJ) filtro.cnpj__icontains = this.itemPesquisa
      else filtro.nome_fantasia__icontains = this.itemPesquisa
      return filtro
    },
    saldo_disponivel () {
      if (!this.negociacao) return 0
      else if (this.negociacao.modelo_faturamento === 'prepago' || this.negociacao.modelo_faturamento === 'pospago') return (this.negociacao.valor || 0) - (this.negociacao.valor_usado || 0)
      else if (this.negociacao.modelo_faturamento === 'credito') return this.negociacao.credito_apetrus || 0
      return 0
    },
    desabilitar () {
      if (!this.select) return true
      switch (this.step) {
        case 1:
          if (this.select === 'Gerar Voucher') {
            const obj = this.negociacao.posto || this.postoSelecionado || this.negociacao.rede
            if (this.checkTipoVoucher() <= 0 || !obj) return true
          }
          return false
        case 2:
          if ((!this.funcionarioSelected && !this.veiculoSelecionado) || !this.select_limite || (this.select_limite === 'Com limite' && this.formatarValor(this.valor_limite) <= 0)) return true
          return false
      }
      return true
    },
    infoCompra () {
      let cnpj
      let idRede
      if (this.negociacao.posto || this.postoSelecionado) {
        cnpj = this.negociacao.posto ? this.negociacao.posto.cnpj : null || (this.postoSelecionado ? this.postoSelecionado.cnpj : null)
      } else {
        idRede = this.negociacao.rede.id_rede
      }
      const idCombustivel = this.id_combustivel ? this.id_combustivel : null
      return {
        empresa: this.empresa.cnpj,
        cnpj_posto: cnpj,
        id_rede: idRede,
        id: this.negociacao.id,
        valor_compra: this.checkTipoVoucher(),
        id_combustivel: idCombustivel,
        id_item: this.itemSelecionado ? this.itemSelecionado.id : null,
        id_veiculo: this.veiculoSelecionado ? this.veiculoSelecionado.id_veiculo : null,
        id_funcionario: this.funcionarioSelected ? this.funcionarioSelected.id_funcionario : null
      }
    }
  },
  watch: {
    negociacao (val) {
      if (val) {
        this.pegarCombustiveis()
      }
    },
    data () {
      this.pegarPostos()
    },
    postoSelecionado () {
      this.pegarItens()
    },
    veiculoSelecionado (val) {
      if (val && this.select !== 'Gerar Voucher') {
        this.funcionarioSelected = undefined
      }
    },
    funcionarioSelected (val) {
      if (this.select === 'Gerar Voucher') {
        this.carregarVeiculos()
      } else {
        if (val) {
          this.veiculoSelecionado = undefined
        }
      }
    }
  },
  beforeMount () {
    if (this.negociacao) {
      this.pegarItens()
      this.carregarFuncionarios()
      this.carregarVeiculos()
      if (this.negociacao.is_apetrus) this.pegarPostos()

      if (this.negociacao.combustivel) {
        this.id_combustivel = this.negociacao.combustivel.id_combustivel
        const itemFilter = this.itens.filter((i) => i.combustivel.id_combustivel === this.negociacao.combustivel.id_combustivel)
        this.itemSelecionado = itemFilter[0]
      } else if (this.negociacao.item) {
        this.itemSelecionado = this.negociacao.item
        this.id_combustivel = this.negociacao.item.combustivel.id_combustivel
      }
    }
  },
  methods: {
    is_number (value) {
      try {
        const teste = Number(value)
        if (!teste) return false
        return true
      } catch (e) {
        return false
      }
    },
    formatarValor (valor) {
      if (!valor) return 0
      return Number(valor.replaceAll('.', '').replace(',', '.'))
    },
    getDestinatario  (destinatario) {
      if (!destinatario) return 'Apetrus'
      else if (destinatario.nome) return `Rede ${destinatario.nome}`
      else if (destinatario.nome_fantasia) return `Posto ${destinatario.nome_fantasia}`
      return undefined
    },
    voltarTela () {
      switch (this.step) {
        case 2:
          this.step = 1
      }
    },
    carregarVeiculos () {
      let funcao = function () {}
      if (this.select === 'Gerar Voucher' || this.funcionarioSelected) {
        const cpf = this.funcionarioSelected.pessoa ? this.funcionarioSelected.pessoa.cpf : this.funcionarioSelected.cpf
        funcao = this.$store.dispatch('veiculoPorFuncionario', {
          cnpj: this.empresa.cnpj,
          cpf: cpf
        })
      } else {
        funcao = this.$store.dispatch('carregarVoucherVeiculos', this.empresa.cnpj)
      }
      funcao.then(json => {
        this.veiculos = json
      })
    },
    clearFields () {
      this.tipo_cartao_selecionado = false
      this.datatemp = undefined
      this.pagamentoDinheiro = false
      this.termosAceitos = false
      this.$refs.formTipoPagamento.reset()
      this.soma = undefined
      this.valor_compra = undefined
      this.funcionarioSelected = undefined
      this.veiculoSelecionado = undefined
      this.select_limite = undefined
      this.valor_limite = undefined
    },
    passarTela () {
      switch (this.step) {
        case 1:
          if (this.$refs.formTipoPagamento.validate()) {
            this.btnNome = 'Continuar'
            if (this.select === 'Conceder Crédito') {
              this.step = 2
              this.msgFinal = 'O funcionário está com permissão para gerar vales de abastecimentos!'
            } else if (this.select === 'Gerar Voucher') {
              this.realizarCompra()
              this.step = 3
            } else {
              this.termosAceitos = true
            }
          }
          break
        case 2:
          this.btnNome = 'Continuar'
          if (this.$refs.formFuncionario.validate()) {
            this.selecionarFuncionarioPermissao()
          }
          break
      }
    },
    realizarCompra () {
      this.$store
        .dispatch('realizarCompra', this.infoCompra)
        .then(json => {
          this.$emit('carregar')
          this.step = 3
        })
        .catch(error => {
          this.$emit('error', error)
          this.fechar()
        })
    },
    pegarItens () {
      if (!this.empresa) return
      const data = {
        empresa: this.empresa.cnpj,
        categoria__referencia: 'combustivel'
      }

      if (this.negociacao.posto) {
        data.posto = this.negociacao.posto.cnpj
      } else if (this.negociacao.rede) {
        data.posto = this.negociacao.rede.matriz.cnpj
      } else if (this.postoSelecionado) {
        data.posto = this.postoSelecionado.cnpj
      }

      if (this.negociacao.combustivel) {
        data.combustivel__ref__icontains = this.negociacao.combustivel.ref
      } else if (this.negociacao.item) {
        data.item = this.negociacao.item.id
      }

      this.$store.dispatch('pegarItens', data).then(json => {
        this.itens = json
      })
    },
    carregarFuncionarios () {
      var data = {
        pagination: this.pagination,
        empresa: this.empresa.cnpj
      }
      funcionariosDao.getFuncionariosEmpresaConfirmados(data, this.headers)
        .then(res => res.json())
        .then(json => {
          this.funcionarios = json.results
        })
    },
    selecionarFuncionarioPermissao () {
      if (this.valor_limite == null) {
        this.valor_limite = 0
      }
      if (this.select_limite === 'Sem limite') {
        this.aberto = true
      }
      if (this.valor_limite) {
        this.valor_limite = this.valor_limite.split('.').join('')
        this.valor_limite = this.valor_limite.split(',').join('.')
      }
      const data = {
        valor: this.valor_limite,
        aberto: this.aberto,
        funcionario: this.funcionarioSelected ? this.funcionarioSelected.id_funcionario : null,
        veiculo: this.veiculoSelecionado ? this.veiculoSelecionado.id_veiculo : null,
        proposta: this.negociacao.id,
        ativo: true
      }

      this.$store.dispatch('criarCredito', data).then(() => {
        this.step = 3
        this.$emit('carregar')
      })
    },
    fechar () {
      this.step = 1
      this.clearFields()
      this.$emit('fechar')
    },
    checkTipoVoucher () {
      if (this.select === 'Gerar Voucher') {
        const newValue = this.valor_tela.replaceAll('.', '').replace(',', '.')
        return parseFloat(newValue)
      } else {
        return parseFloat(this.negociacao.valor - this.negociacao.valor_usado)
      }
    },
    checkCompraAberta () {
      if (this.select_limite === 'Sem Limite') {
        this.aberto = true
      } else {
        this.aberto = false
      }
    },
    pegarPostos () {
      this.$store.dispatch('pegarPostos', this.data).then(json => {
        this.postos = json.results
      })
    }
  }
}
</script>
